<div class="max-w-6xl mx-auto mt-4">
  <div class="mx-2 sm:mx-0">
    <h1 class="text-3xl font-bold">{{ movie.title }}</h1>
    <p class="text-gray-600">{{ movie.text }}</p>
  </div>
  <div
    class="mt-2 sm:rounded-larger sm:shadow-lg overflow-hidden aspect-w-16 aspect-h-9"
  >
    <iframe
      class="w-full"
      [src]="
        'https://www.youtube-nocookie.com/embed/' + movie.youtube_video_id
          | safe
      "
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</div>
