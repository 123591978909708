import { isPlatformBrowser, NgIf } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatIcon } from "@angular/material/icon";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabLink, MatTabNav, MatTabsModule } from "@angular/material/tabs";
import { ActivatedRoute, RouterLink, RouterOutlet, UrlSegment } from "@angular/router";
import { School } from "../../models/school";
import { CustomerAuthService } from "../../services/customer-auth.service";
import { SchoolService } from "../../services/school.service";

@Component({
    selector: "ts-routing-customer",
    templateUrl: "./routing-customer.component.html",
    styleUrls: ["./routing-customer.component.scss"],
    standalone: true,
    imports: [
        NgIf,
        MatTabNav,
        MatTabLink,
        MatTabsModule,
        RouterLink,
        MatIcon,
        RouterOutlet,
        FormsModule,
    ],
})
export class RoutingCustomerComponent implements OnInit {
  public url: UrlSegment[];
  school: School = new School();
  public isAuthed = false;
  public storedPw = "";
  public tmpPw = "";

  constructor(
    private schoolSrv: SchoolService,
    private route: ActivatedRoute,
    private authSrv: CustomerAuthService,
    private snackbar: MatSnackBar,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  onKey(event: any, pw: string) {
    if (event.code === "Enter") {
      this.auth(pw);
    }
  }

  public auth(pw: string) {
    this.authSrv.check(this.school.id, pw).subscribe((isAuthed) => {
      this.isAuthed = isAuthed;
      if (isAuthed) {
        this.storedPw = pw;
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem("customer_pw", this.storedPw);
        }
      } else {
        this.snackbar.open(
          "Das angegebene Passwort ist falsch. Vielleicht ist es abgelaufen?",
          "",
          {
            duration: 5000,
          }
        );
      }
    });
  }

  public logout() {
    this.isAuthed = false;
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem("customer_pw");
      this.snackbar.open("Sie wurden erfolgreich abgemeldet!", null, {
        duration: 5000,
      });
    }
  }

  ngOnInit() {
    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
      if (isPlatformBrowser(this.platformId)) {
        const pw = localStorage.getItem("customer_pw");
        if (pw) {
          this.auth(pw);
        }
      }
    });

    this.route.url.subscribe((url) => {
      this.url = url;
    });
  }
}
