import { DatePipe, NgFor, NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { Video } from "../../../models/video";
import { VideoCategory } from "../../../models/videoCategory";
import { VideoCategorieService } from "../../../services/video-categorie.service";
import { VideoService } from "../../../services/video.service";

@Component({
    selector: "ts-portal-video-cat",
    templateUrl: "./portal-video-cat.component.html",
    styleUrls: ["./portal-video-cat.component.css"],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        RouterLink,
        DatePipe,
    ],
})
export class PortalVideoCatComponent implements OnInit {
  videoCat: VideoCategory = new VideoCategory();
  videos: Video[] = [];
  loading = false;

  constructor(
    private router: ActivatedRoute,
    private videoCatSrv: VideoCategorieService,
    private videoSrv: VideoService
  ) { }

  update() {
    this.router.params.subscribe((params) => {
      this.loading = true;
      this.videoCatSrv.findById(params.videoCatId).subscribe((videoCat) => {
        this.videoCat = videoCat;
      });
      this.videoSrv.findByCategory(params.videoCatId).subscribe(
        (videos) => {
          this.videos = videos;
          this.loading = false;
        },
        (err) => {
          this.loading = false;
        }
      );
    });
  }
  ngOnInit() {
    this.update();
  }
}
