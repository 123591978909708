<div class="max-w-6xl mx-auto">
  <div class="mt-4 mx-2">
    <nav mat-tab-nav-bar mat-align-tabs="center">
      <a mat-tab-link [active]="ageClass.nameUrl == params['ageClass']" *ngFor="let ageClass of ageClasses"
        [routerLink]="['/events', ageClass.nameUrl]">
        {{ ageClass.name }}
      </a>
    </nav>
    <ts-event-list [ageClassId]="ageClass.id"></ts-event-list>
  </div>
</div>
