import { Component, OnInit } from '@angular/core';
import { SchoolService } from '../../services/school.service';
import { School } from '../../models/school';
import { routerTransition } from '../../router.animations';

@Component({
    selector: 'ts-routing-private',
    templateUrl: './routing-private.component.html',
    styleUrls: ['./routing-private.component.css'],
    animations: [routerTransition()],
    standalone: true,
})
export class RoutingPrivateComponent implements OnInit {

  constructor(private schoolSrv: SchoolService) { }

  public school: School = new School();

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
    });
  }

}
