import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class AssetsService {

  static prefix = 'assets';

  constructor(private httpSrv: TsHttpService) { }

  upload(file: any): Observable<any> {
    return this.httpSrv.post(`${AssetsService.prefix}`, file, { reportProgress: true, observe: "events" });
  }
}
