import { NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { School } from "../../models/school";
import { SchoolService } from "../../services/school.service";
import { SeoService } from "../../services/seo.service";
import { PictureComponent } from "../../shared/picture/picture.component";

@Component({
    selector: "ts-routing-about-us",
    templateUrl: "./routing-about-us.component.html",
    styleUrls: ["./routing-about-us.component.scss"],
    standalone: true,
    imports: [NgIf, PictureComponent],
    host: { ngSkipHydration: "true" },
})
export class RoutingAboutUsComponent implements OnInit {
  public school: School = new School();

  constructor(private schoolSrv: SchoolService, private seoSrv: SeoService) {}

  ngOnInit() {
    this.schoolSrv.current().subscribe((school) => {
      this.school = school;

      this.seoSrv.updateDescription(this.school.aboutUsText.slice(0, 159));
    });
  }
}
