import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Video } from "../../../models/video";
import { VideoService } from "../../../services/video.service";
import { SafePipe } from "../../../safe.pipe";
import { NgIf, DatePipe } from "@angular/common";

@Component({
    selector: "ts-portal-video",
    templateUrl: "./portal-video.component.html",
    styleUrls: ["./portal-video.component.css"],
    standalone: true,
    imports: [
        NgIf,
        DatePipe,
        SafePipe,
    ],
})
export class PortalVideoComponent implements OnInit {
  video: Video = new Video();

  constructor(private videoSrv: VideoService, private router: ActivatedRoute) {}

  playVideo($event) {
    $event.playVideo();
  }

  ngOnInit(): void {
    this.router.params.subscribe((params) => {
      this.videoSrv.findById(params.videoId).subscribe((video) => {
        this.video = video;
      });
    });
  }
}
