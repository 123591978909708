<div class="mt-2">
  <label [for]="column.dbName" class="block text-sm font-medium text-gray-700">{{column?.prettyName}}<span
      *ngIf="column.required">*</span></label>
  <div class="mt-1">
    <input [placeholder]="column?.prettyName" [id]="column.dbName" [id]="column.dbName" type="datetime-local"
      [required]="column.required" [ngModel]="column.data | date: 'yyyy-MM-ddTHH:mm'"
      (ngModelChange)="setDateTime($event)"
      class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
  </div>
</div>
