import { Component, OnInit } from "@angular/core";
import { School } from "../../models/school";
import { SchoolService } from "../../services/school.service";

@Component({
    selector: "ts-routing-agb-higle-app",
    templateUrl: "./routing-agb-higle-app.component.html",
    styleUrls: ["./routing-agb-higle-app.component.scss"],
    standalone: true,
})
export class RoutingAgbHigleAppComponent implements OnInit {
  public school: School = new School();

  constructor(private schoolSrv: SchoolService) {}

  ngOnInit() {
    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
    });
  }
}
