import * as dayjs from "dayjs";
import { Dance } from "./dance";
import { DbObj } from "./dbObj";
import { VideoCategory } from "./videoCategory";

export class Video implements DbObj {
  public id: number;
  public title: string;
  public sequence_name: string;
  public song_title: string;
  public artist: string;
  public song_reference: string;
  public date: Date;
  public youtube_video_id: string;
  public vimeo_video_id: string;
  public vimeo_thumbnail_url: string;
  public clicked: boolean;
  public added: Date;
  public dance: Dance;
  public videoCategory: VideoCategory;
  spotify_album_cover_url: string;

  private fk_category: number;
  public fk_dance: number | null;

  get prettyName() {
    return this.sequence_name && this.sequence_name.length > 0
      ? this.sequence_name
      : this.title
        ? this.title
        : this.song_title;
  }

  get subtitle() {
    return dayjs(this.added).format("DD.MM.YYYY");
  }

  public getTitle(): string {
    let title = "";
    if (this.dance) {
      title = this.dance.name;
    } else {
      title = this.title;
    }
    return title;
  }

  public getSubTitle(): string {
    let subTitle = "";

    if (!this.song_title && !this.artist) {
      subTitle = this.sequence_name;
    } else {
      if (this.song_reference) {
        return `'<a class="subLink" target="_blank" href=${this.song_reference}">${this.song_title} - ${this.artist}</a>`;
      }
      subTitle =
        '<a class="subLink" target="_blank" href="https://open.spotify.com/search/' +
        this.song_title +
        " - " +
        this.artist +
        '">' +
        this.song_title +
        " - " +
        this.artist +
        "</a>";
    }
    return subTitle;
  }

  constructor() { }
}
