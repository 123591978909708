import {
  Component,
  EventEmitter,
  Input,
  KeyValueDiffers,
  Output,
} from "@angular/core";
import * as dayjs from "dayjs";
import { Column } from "../../../models/column";
import { FormsModule } from "@angular/forms";
import { NgIf, DatePipe } from "@angular/common";

@Component({
    selector: "ts-datetime-field",
    templateUrl: "./datetime-field.component.html",
    styleUrls: ["./datetime-field.component.css"],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        DatePipe,
    ],
})
export class DatetimeFieldComponent {
  differ: any;

  constructor(private differs: KeyValueDiffers) {
    this.differ = differs.find({}).create();
  }

  @Input() column: Column;
  @Output() columnChange = new EventEmitter();
  changeDetected = false;
  date: string;

  setDateTime($event: any) {
    this.column.data = dayjs($event, "YYYY-MM-DDTHH:mm");
  }

  onChange() {
    this.column.data = new Date(this.date).toISOString();
    this.columnChange.emit(this.column);
  }
}
