import { Injectable } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { CreateEventRegistrationDto } from "../../../../shared/dtos";
import { EventRegistration } from "../models/eventRegistration";
import { ConvService } from "./conv.service";
import { CrudService } from "./crud.service";
import { TsHttpService } from "./ts-http.service";

@Injectable({
  providedIn: 'root'
})
export class EventRegistrationService extends CrudService<EventRegistration> {

  static prefix = "event-registrations";
  dbTable = "event_registration";

  constructor(public httpSrv: TsHttpService, public convServ: ConvService) {
    super(httpSrv, EventRegistrationService.prefix, EventRegistration, convServ);
  }

  async createWithDto(dto: CreateEventRegistrationDto): Promise<void> {
    await firstValueFrom(this.httpSrv.post<EventRegistration>(`${EventRegistrationService.prefix}`, dto));
  }
}