import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Backend } from '../models/backend';
import { Column } from '../models/column';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class BackendService extends CrudService<Backend> {

  static prefix = 'backend';

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService,
  ) {
    super(httpSrv, BackendService.prefix, Backend, convSrv);
  }

  findByName(tableName: string): Observable<Backend> {
    return this.httpSrv.get<Backend>(`${BackendService.prefix}/names/${tableName}`);
  }

  findColumnNames(tableName: string): Observable<Column[]> {
    return this.httpSrv.get(`${BackendService.prefix}/names/${tableName}/columns`);
  }
}
