import { Component, OnInit } from '@angular/core';
import { BusinessPartner } from '../../models/businessPartner';
import { School } from '../../models/school';
import { PartnerService } from '../../services/partner.service';
import { SchoolService } from '../../services/school.service';
import { PictureComponent } from '../../shared/picture/picture.component';
import { NgFor } from '@angular/common';

@Component({
    selector: 'ts-partner-list',
    templateUrl: './partner-list.component.html',
    styleUrls: ['./partner-list.component.css'],
    standalone: true,
    imports: [NgFor, PictureComponent]
})
export class PartnerListComponent implements OnInit {

  public school: School = new School();
  public partners: BusinessPartner[] = new Array();

  constructor(
    private schoolSrv: SchoolService,
    private partnerSrv: PartnerService
  ) { }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
      this.partnerSrv.bySchoolId(this.school.id).subscribe(partners => {
        this.partners = partners;
      });
    });
  }

}
