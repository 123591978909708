import { Component, OnInit } from '@angular/core';
import { School } from '../../models/school';
import { Teammember } from '../../models/teammember';
import { SchoolService } from '../../services/school.service';
import { TeamService } from '../../services/team.service';
import { PictureComponent } from '../../shared/picture/picture.component';
import { NgClass, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'ts-team-grid',
    templateUrl: './team-grid.component.html',
    styleUrls: ['./team-grid.component.css'],
    standalone: true,
    imports: [NgClass, NgFor, PictureComponent, NgIf]
})
export class TeamGridComponent implements OnInit {

  public school: School = new School();
  public members: Teammember[] = new Array();
  public hasText: boolean = false;

  public padding = '168%';

  constructor(private schoolSrv: SchoolService, private teamSrv: TeamService) { }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
      this.teamSrv.bySchoolId(this.school.id).subscribe(members => {
        this.members = members;
        this.hasText = members.reduce((initial, member) => initial || member.text?.length > 0, false)
      });
    });
  }

}
