<div class="max-w-4xl mx-auto">
  <div *ngIf="event"
    class="waves relative shadow-xl sm:rounded-2xl sm:overflow-hidden h-48 sm:h-72 sm:mt-8 p-8 mx-0 sm:mx-2">
    <div class="absolute inset-0">
      <ts-picture class="object-cover w-full h-full" [img]="event.img" width="100%" height="100%">
      </ts-picture>

      <div class="absolute inset-0 bg-gray-600 mix-blend-multiply"></div>
    </div>

    <div class="relative h-full flex flex-col gap-2.5 justify-center items-center">
      <span class="text-white">
        {{event.ageClass.name}}
      </span>
      <h1 class="text-center text-2xl font-extrabold tracking-tight sm:text-3xl lg:text-5xl text-white">
        {{event.name}}
      </h1>
      <span class="text-white">
        {{event.description}}
      </span>
    </div>
  </div>

  <div *ngIf="event" class="flex flex-col md:flex-row gap-2.5 justify-between mt-6 sm:mx-2">

    <div class="flex flex-col gap-2.5 w-full">
      <div class="flex flex-col gap-2.5">
        <div class="flex flex-row items-center gap-2.5">
          <div class="text-gray-600">Datum:</div>
          <div class="text-gray-800">{{event.getDateText()}}</div>
        </div>
        <div class="flex flex-row items-center gap-2.5">
          <div class="text-gray-600">Uhrzeit:</div>
          <div class="text-gray-800">{{event.getTimeText()}}</div>
        </div>
      </div>
      <div class="max-w-full prose text-gray-700 text-sm" [innerHTML]="event.text"></div>

      <form class="flex flex-col gap-4" ngNativeValidate>
        <div class="grid mt-6 grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <div class="sm:col-span-2 text-xs font-bold text-gray-500">
            Deine Daten
          </div>
          <div class="-mt-4">
            <label for="first-name" class="block text-sm font-medium text-gray-700">Vorname</label>
            <div class="mt-1">
              <input type="text" name="first-name" id="first-name" autocomplete="given-name" required
                [(ngModel)]="dto.firstName"
                class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:-mt-4">
            <label for="last-name" class="block text-sm font-medium text-gray-700">Nachname</label>
            <div class="mt-1">
              <input type="text" name="last-name" id="last-name" autocomplete="family-name" required="required"
                [(ngModel)]="dto.lastName"
                class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="-mt-4">
            <label for="email" class="block text-sm font-medium text-gray-700">E-Mail</label>
            <div class="mt-1">
              <input type="email" name="email" id="email" autocomplete="email" required
                [(ngModel)]="dto.email"
                class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:-mt-4">
            <label for="phone" class="block text-sm font-medium text-gray-700">Telefon</label>
            <div class="mt-1">
              <input type="tel" name="phone" id="phone" autocomplete="tel" required="required"
                [(ngModel)]="dto.phone"
                class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:-mt-4">
            <label for="street" class="block text-sm font-medium text-gray-700">Straße + Hausnummer</label>
            <div class="mt-1">
              <input type="text" name="street" id="street" autocomplete="street-address"
                [(ngModel)]="dto.street"
                class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:-mt-4">
            <label for="zip" class="block text-sm font-medium text-gray-700">PLZ</label>
            <div class="mt-1">
              <input type="string" name="zip" id="zip" autocomplete="zip"
                [(ngModel)]="dto.zip"
                class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
            </div>
          </div>

          <div class="sm:-mt-4">
            <label for="city" class="block text-sm font-medium text-gray-700">Stadt</label>
            <div class="mt-1">
              <input type="text" name="zip" id="city" autocomplete="city"
                [(ngModel)]="dto.city"
                class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
            </div>
          </div>
        </div>

        <div class="grid mt-6 grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
          <div class="sm:col-span-2 text-xs font-bold text-gray-500">
            Daten deines Kindes (wenn zutreffend)
          </div>
          <div class="-mt-4">
            <label for="child-first-name" class="block text-sm font-medium text-gray-700">Vorname</label>
            <div class="mt-1">
              <input type="text" name="child-first-name" id="child-first-name" autocomplete="none"
                [(ngModel)]="dto.childFirstName"
                class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
            </div>
          </div>
          <div class="sm:-mt-4">
            <label for="child-last-name" class="block text-sm font-medium text-gray-700">Nachname</label>
            <div class="mt-1">
              <input type="text" name="child-last-name" id="child-last-name" autocomplete="none"
                [(ngModel)]="dto.childLastName"
                class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
            </div>
          </div>
        </div>

        <div class="grid mt-6 grid-cols-1 gap-y-6 sm:gap-x-8">
          <div class="text-xs font-bold text-gray-500">
            Fragen zur Veranstaltung
          </div>
          <div class="sm:-mt-4" *ngFor="let question of event.questions; index as i">
            <label [for]="'question-' + question.id" class="block text-sm font-medium text-gray-700">{{question.question}}</label>
            <div class="mt-1">
              <input type="text" [name]="'question-' + question.id" [id]="'question-' + question.id" autocomplete="none"
                [(ngModel)]="dto.answers[i].answer"
                class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
            </div>
          </div>
        </div>
        <button (click)="submit()" class="self-center mb-2 bg-orange text-white px-6 py-3 font-bold rounded-full shadow-md"
          >
          Jetzt anmelden
        </button>
      </form>
    </div>
  </div>
</div>