import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Column } from '../../../models/column';
import { AssetsService } from '../../../services/assets.service';
import { TsHttpService } from '../../../services/ts-http.service';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ts-video-field',
    templateUrl: './video-field.component.html',
    styleUrls: ['./video-field.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, MatIcon]
})
export class VideoFieldComponent implements OnInit {

  private _column: Column;
  video: any;
  uploadProgress: number = 0;
  @Output() columnChange = new EventEmitter();

  @Input() set column(val: Column) {
    this._column = val;
  }

  get column() {
    return this._column;
  }

  getVideoPath(filename: string) {
    return `${TsHttpService.assetsRoot}${filename}`;
  }

  constructor(private assetsSrv: AssetsService) { }

  onChange($event) {
    const formData = new FormData();
    formData.append('file', $event.target.files[0]);
    this.assetsSrv.upload(formData).subscribe(event => {

      // not yet finished
      if (event.type === 1) {
        this.uploadProgress = event.loaded / event.total;

        // finished
      } else if (!!event.body) {
        this.column.data = event.body.filename;
        this.video = event.body;
        this.uploadProgress = 0;
      }
    });
    this.columnChange.emit(this.column);
  }

  ngOnInit(): void {
  }

}
