import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Column } from '../../../models/column';
import { DbObj } from '../../../models/dbObj';
import { EnumFieldComponent } from '../../data-fields/enum-field/enum-field.component';
import { BooleanFieldComponent } from '../../data-fields/boolean-field/boolean-field.component';
import { JoinManyFieldComponent } from '../../data-fields/join-many-field/join-many-field.component';
import { JoinFieldComponent } from '../../data-fields/join-field/join-field.component';
import { FileFieldComponent } from '../../data-fields/file-field/file-field.component';
import { VideoFieldComponent } from '../../data-fields/video-field/video-field.component';
import { ImgFieldComponent } from '../../data-fields/img-field/img-field.component';
import { TimeFieldComponent } from '../../data-fields/time-field/time-field.component';
import { DatetimeFieldComponent } from '../../data-fields/datetime-field/datetime-field.component';
import { HtmlFieldComponent } from '../../data-fields/html-field/html-field.component';
import { StringFieldComponent } from '../../data-fields/string-field/string-field.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ts-generic-form-element',
    templateUrl: './generic-form-element.component.html',
    styleUrls: ['./generic-form-element.component.css'],
    standalone: true,
    imports: [NgIf, StringFieldComponent, HtmlFieldComponent, DatetimeFieldComponent, TimeFieldComponent, ImgFieldComponent, VideoFieldComponent, FileFieldComponent, JoinFieldComponent, JoinManyFieldComponent, BooleanFieldComponent, EnumFieldComponent]
})
export class GenericFormElementComponent {

  @Input() column: Column = new Column();
  @Input() service: any;
  @Input() item: DbObj;
  @Output() columnChange = new EventEmitter<Column>();

  onChange() {
    this.columnChange.emit(this.column);
  }

}
