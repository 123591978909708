<div class="py-6">
  <div class="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="bg-orange p-6 rounded-lg shadow-none flex justify-between">
      <div>
        <h2 class="text-sm text-white">
          {{ video?.videoCategory?.name_pretty }}
        </h2>
        <h1 class="mt-2 text-4xl font-semibold text-white">
          {{ video?.title }}
        </h1>
      </div>
      <img class="h-32" [src]="video.spotify_album_cover_url" alt="" />
    </div>
  </div>
  <div class="max-w-6xl mx-auto px-4 sm:px-6 md:px-8 p-6 relative w-full flex gap-6">
    <div class="w-2/3">
      <div class="aspect-w-16 aspect-h-9 relative w-full rounded-lg overflow-hidden shadow-none">
        <iframe *ngIf="video?.youtube_video_id" class="absolute inset-0" [src]="
            'https://www.youtube-nocookie.com/embed/' +
              video.youtube_video_id +
              '?rel=0' | safe
          " frameborder="0" allowfullscreen="allowfullscreen"></iframe>

        <iframe *ngIf="video?.vimeo_video_id" class="vimeo-player absolute inset-0" [src]="
            'https://player.vimeo.com/video/' + video.vimeo_video_id | safe
          " frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      </div>
    </div>
    <div class="w-1/3 p-6 bg-white shadow-none rounded-lg">
      <p class="text-sm text-gray-600">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae, iusto!
        Hic eos praesentium minima reprehenderit. Vero nihil corporis saepe
        expedita.
      </p>
      <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 mt-4">
        <div *ngIf="video.song_title" class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">Song</dt>
          <dd class="mt-1 text-sm text-gray-800">{{ video.song_title }}</dd>
        </div>
        <div *ngIf="video.artist" class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">Interpret</dt>
          <dd class="mt-1 text-sm text-gray-800">{{ video.artist }}</dd>
        </div>
        <div *ngIf="video.date" class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">Veröffentlicht</dt>
          <dd class="mt-1 text-sm text-gray-800">
            {{ video.date | date: "dd.MM.yyyy" }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>
