import { DbObj } from './dbObj';
import { School } from './school';

export class VideoCategory implements DbObj {

  public id: number;
  public name: string;
  public name_pretty: string;
  public visible: boolean;
  public schools: School[];
  public hasDances: boolean;

  constructor() { }

  get prettyName() {
    return this.name;
  }

  get subtitle() {
    return this.name_pretty;
  }
}
