<div class="py-6">
  <div class="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="bg-orange p-6 rounded-lg shadow-none">
      <h1 class="text-4xl font-semibold text-white">
        {{ videoCat.name_pretty }}
      </h1>
      <p class="pt-2 text-white">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic nam quae
        sint, soluta impedit ipsa adipisci iure praesentium voluptate deserunt
        doloribus, aspernatur iusto minima sapiente. Minus temporibus quis
        excepturi error?
      </p>
    </div>
  </div>
  <div *ngIf="loading">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      style="margin: auto; display: block; shape-rendering: auto" width="200px" height="200px" viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid">
      <circle cx="30" cy="50" fill="#e05a11" r="20">
        <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="30;70;30"
          begin="-0.5s"></animate>
      </circle>
      <circle cx="70" cy="50" fill="#a72800" r="20">
        <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="30;70;30" begin="0s">
        </animate>
      </circle>
      <circle cx="30" cy="50" fill="#e05a11" r="20">
        <animate attributeName="cx" repeatCount="indefinite" dur="1s" keyTimes="0;0.5;1" values="30;70;30"
          begin="-0.5s"></animate>
        <animate attributeName="fill-opacity" values="0;0;1;1" calcMode="discrete" keyTimes="0;0.499;0.5;1" dur="1s"
          repeatCount="indefinite"></animate>
      </circle>
      <!-- [ldio] generated by https://loading.io/ -->
    </svg>
  </div>
  <div class="max-w-6xl mx-auto px-4 sm:px-6 md:px-8 pt-6" *ngIf="!loading">
    <ul role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
      <li class="relative" *ngFor="let video of videos" [routerLink]="[
          '/online/portal',
          {
            outlets: {
              portal: ['video', video.id]
            }
          }
        ]">
        <div
          class="group block w-full aspect-w-16 aspect-h-9 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-orange overflow-hidden">
          <img *ngIf="video.youtube_video_id" [src]="
              'http://i3.ytimg.com/vi/' +
              video.youtube_video_id +
              '/hqdefault.jpg'
            " alt="" class="object-cover pointer-events-none group-hover:opacity-75" />

          <img *ngIf="video.vimeo_video_id" [src]="video.vimeo_thumbnail_url" alt=""
            class="object-cover pointer-events-none group-hover:opacity-75" />
          <button type="button" class="absolute inset-0 focus:outline-none">
            <span class="sr-only">Video ansehen {{ video.title }}</span>
          </button>
        </div>
        <p class="mt-2 block text-sm font-medium text-gray-800 truncate pointer-events-none">
          {{ video.title }}
        </p>
        <p class="block text-sm font-medium text-gray-500 pointer-events-none">
          {{ video.date | date: "dd.MM.yyyy" }}
        </p>
      </li>
    </ul>
  </div>
</div>
