import { Component, OnInit } from "@angular/core";
import { School } from "../../models/school";
import { routerTransition } from "../../router.animations";
import { SchoolService } from "../../services/school.service";
import { TeamGridComponent } from "../../team/team-grid/team-grid.component";

@Component({
    selector: "ts-routing-team",
    templateUrl: "./routing-team.component.html",
    styleUrls: ["./routing-team.component.css"],
    animations: [routerTransition()],
    standalone: true,
    imports: [TeamGridComponent],
})
export class RoutingTeamComponent implements OnInit {
  school: School = new School();

  constructor(private schoolSrv: SchoolService) {}

  ngOnInit() {
    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
    });
  }
}
