import { Component, Input, OnChanges } from '@angular/core';
import { CourseLevel } from '../../models/courseLevel';
import { School } from '../../models/school';
import { CourseLevelService } from '../../services/course-level.service';
import { CourseService } from '../../services/course.service';
import { SchoolService } from '../../services/school.service';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'ts-course-buttons',
    templateUrl: './course-buttons.component.html',
    styleUrls: ['./course-buttons.component.css'],
    standalone: true,
    imports: [NgFor, NgIf, RouterLink]
})
export class CourseButtonsComponent implements OnChanges {

  @Input() ageClassId: number;

  public courseLevelsPair: CourseLevel[] = new Array();
  public courseLevelsSolo: CourseLevel[] = new Array();
  public courseLevels = [
    this.courseLevelsPair,
    this.courseLevelsSolo
  ];
  public school: School = new School();

  public displayedColumns = [
    'name',
    'startdates',
    'weekday',
    'time',
    'action'
  ];

  constructor(
    private courseSrv: CourseService,
    private CourseLevelSrv: CourseLevelService,
    private schoolSrv: SchoolService
  ) { }

  ngOnChanges() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
      this.CourseLevelSrv.byAgeClassId(
        this.ageClassId, this.school.id, 'paar'
      ).subscribe(courseLevels => {
        // only consider levels with more than 0 courses
        this.courseLevelsPair = courseLevels
          .filter(courseLevel => courseLevel.courses && courseLevel.courses.length > 0);
        this.courseLevels[0] = this.courseLevelsPair;
      });
      this.CourseLevelSrv.byAgeClassId(
        this.ageClassId, this.school.id, 'solo'
      ).subscribe(courseLevels => {
        // only consider levels with more than 0 courses
        this.courseLevelsSolo = courseLevels
          .filter(courseLevel => courseLevel.courses && courseLevel.courses.length > 0);
        this.courseLevels[1] = this.courseLevelsSolo;
      });
    });
  }

}
