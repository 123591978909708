import { NgFor } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { DrinkCategoryComponent } from "../../drink-category/drink-category.component";
import { DrinkCategory } from "../../models/drinkCategory";
import { News } from "../../models/news";
import { School } from "../../models/school";
import { DrinkCategoryService } from "../../services/drink-category.service";
import { NewsService } from "../../services/news.service";
import { SchoolService } from "../../services/school.service";
import { PictureComponent } from "../../shared/picture/picture.component";

@Component({
    selector: "ts-routing-drinks",
    templateUrl: "./routing-drinks.component.html",
    styleUrls: ["./routing-drinks.component.css"],
    standalone: true,
    imports: [
        PictureComponent,
        NgFor,
        DrinkCategoryComponent,
    ],
    host: { ngSkipHydration: "true" },
})
export class RoutingDrinksComponent implements OnInit {
  school: School = new School();
  drinkCategories: DrinkCategory[] = [];
  firstNews: News = new News();

  constructor(
    private drinkCategorySrv: DrinkCategoryService,
    private schoolSrv: SchoolService,
    private newsSrv: NewsService
  ) {}

  ngOnInit(): void {
    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
      this.newsSrv.bySchoolId(this.school.id).subscribe((news) => {
        this.firstNews = news[0];
      });

      this.drinkCategorySrv
        .findBySchool(this.school.id)
        .subscribe((drinkCategories) => {
          this.drinkCategories = drinkCategories;
        });
    });
  }
}
