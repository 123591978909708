import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AngularEditorConfig, AngularEditorModule } from '@kolkov/angular-editor';
import { Column } from '../../../models/column';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ts-html-field',
    templateUrl: './html-field.component.html',
    styleUrls: ['./html-field.component.css'],
    standalone: true,
    imports: [NgIf, AngularEditorModule, FormsModule]
})
export class HtmlFieldComponent {

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    placeholder: 'Hier den Text eingeben...',
    translate: 'no',
    fonts: [
      { name: 'Libre Franklin', class: 'libre_franklin' }
    ],
    defaultFontName: 'Libre Franklin',
  };

  @Input() column: Column = new Column();
  @Output() columnChange = new EventEmitter();

  onChange() {
    this.columnChange.emit(this.column);
  }

}
