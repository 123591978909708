<div>
  <mat-list>
    <mat-list-item>
      <span>Bezeichnung</span>
      <span>{{ course.description }}</span>
    </mat-list-item>
    <mat-list-item>
      <span>Wochentag</span>
      <span>{{ course.weekday }}</span>
    </mat-list-item>
    <mat-list-item>
      <span>Uhrzeit</span>
      <span>{{ course.getTimeText() }}</span>
    </mat-list-item>
    <mat-list-item
      *ngIf="
        (course.course_start_dates && course.course_start_dates.length === 1) ||
        (startDate && startDate.date)
      "
    >
      <span>Startdatum</span>
      <span>{{
        startDate ? startDate.date : course.course_start_dates[0].date
      }}</span>
    </mat-list-item>
    <mat-list-item
      *ngIf="
        course.course_start_dates && course.course_start_dates.length === 0
      "
    >
      <span>Startdatum</span>
      <span>{{ courseLevel.no_start_dates_text }}</span>
    </mat-list-item>
  </mat-list>
</div>
