import { DbObj } from "./dbObj";
import { Drink } from "./drink";
import { School } from "./school";

export class DrinkCategory implements DbObj {
  get prettyName() {
    return this.name;
  }

  get subtitle() {
    return "";
  }

  id: number;
  name: string;
  img: string;
  fk_school: number;
  school: School;
  drinks: Drink[] = [];
}
