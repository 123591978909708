import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Column } from '../../../models/column';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'ts-boolean-field',
    templateUrl: './boolean-field.component.html',
    styleUrls: ['./boolean-field.component.css'],
    standalone: true,
    imports: [FormsModule]
})
export class BooleanFieldComponent implements OnChanges {

  @Input() column: Column = new Column();
  @Output() columnChange = new EventEmitter();

  onChange() {
    this.column.data = this.column.data ? this.column.data : false;
    this.columnChange.emit(this.column);
  }

  ngOnChanges() {
    this.onChange();
  }

}
