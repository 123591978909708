<div class="max-w-6xl mx-auto mt-4">
  <div [@routerTransition]="" class="mx-2">
    <h1 class="text-3xl font-bold">Die Konditionen unserer Tanzkurse</h1>
    <p class="mt-2 prose text-sm">
      Wir möchten all unsere Tanzkurse zu fairen Konditionen anbieten und haben
      beispielsweise Preismodelle für verschiedene Laufzeiten.<br />
      Untenstehend finden Sie eine Aufschlüsselung, bei Fragen wenden Sie sich
      gerne an uns!
    </p>
    <p class="prose mt-2" [innerHTML]="school.conditions"></p>
  </div>
</div>
