import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Backend } from '../../models/backend';
import { BackendEditService } from '../../services/backend-edit.service';
import { BackendService } from '../../services/backend.service';
import { ServiceLoaderService } from '../../services/service-loader.service';
import { LoaderComponent } from '../../loader/loader.component';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'ts-backend-menu',
    templateUrl: './backend-menu.component.html',
    styleUrls: ['./backend-menu.component.css'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, RouterLink, MatIcon, LoaderComponent]
})
export class BackendMenuComponent implements OnInit, OnChanges {

  @Output() itemSelected: EventEmitter<string> = new EventEmitter();
  @Input() selectedBackend: Backend;
  backends: Backend[];
  tables: Backend[];
  selectedIndex = -1;
  updating = false;


  constructor(
    private backendSrv: BackendService,
    private backendEditSrv: BackendEditService,
    private loaderSrv: ServiceLoaderService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.updating = true;
    this.backendSrv.findAll().subscribe((backends) => {
      this.backends = backends;
      this.updating = false;
      this.tables = this.backends.filter(backend => backend.isOwnTable);
      this.selectedIndex = this.tables
        .findIndex(backend => backend.dbName === this.selectedBackend.dbName);
    });
  }

  ngOnChanges() {
    this.selectedIndex = this.tables?.findIndex(backend => backend.dbName === this.selectedBackend.dbName);
  }

}
