import { columnType } from './columnType';

export class Column {

  public dbName: string;
  public prettyName: string;
  public isVisibleInTable: boolean;
  public required: boolean;
  public columnType: columnType;
  public dataType: string;
  public enumValues: {
    label: string,
    value: string
  }[];
  public refTable?: string;
  public joinTable?: string;
  public data?: any;
  public component?: any;

  constructor() { }
}
