import { Component, OnInit } from '@angular/core';
import { routerTransition } from '../../router.animations';
import { SchoolContactComponent } from '../../school/school-contact/school-contact.component';

@Component({
    selector: 'ts-routing-contact',
    templateUrl: './routing-contact.component.html',
    styleUrls: ['./routing-contact.component.css'],
    animations: [routerTransition()],
    standalone: true,
    imports: [SchoolContactComponent],
})
export class RoutingContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
