<div>
  <ul role="list" class="grid sm:grid-cols-3 gap-x-6 gap-y-12 lg:grid-cols-4 lg:gap-x-8" [ngClass]="
      {'grid-cols-2': !hasText}
    ">
    <li *ngFor="let member of members">
      <div class="space-y-4">
        <div class="shadow-lg rounded-lg overflow-hidden">
          <ts-picture class="object-cover" [img]="member?.image_name">
          </ts-picture>
        </div>
        <div class="space-y-2">
          <div class="text-lg leading-6 font-medium space-y-1">
            <h3 class="font-bold text-2xl">
              {{ member.first_name }} {{ member.last_name }}
            </h3>
            <div class="text-sm flex flex-col">
              <p class="text-gray-700 -mt-1" *ngIf="member?.position">
                {{ member.position }}
              </p>
              <p class="italic text-sm text-gray-600 mt-1" *ngIf="member?.text">
                {{ member.text }}
              </p>
              <strong class="mt-2" *ngIf="member?.courses">Kurse</strong>
              <p class="text-gray-600" *ngIf="member?.courses">
                {{ member.courses }}
              </p>
              <strong class="mt-2" *ngIf="member?.extra">Extras</strong>
              <p class="text-gray-600">
                {{ member.extra }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>

  <!--<mat-card class="imgCard" *ngFor="let member of members">
    <ts-picture [img]="member?.image_name"> </ts-picture>
    <div class="ts-grid-text">
      <h2>{{ member.first_name }} {{ member.last_name }}</h2>
      <h3 *ngIf="member?.position">{{ member.position }}</h3>
      <p class="teamtext" *ngIf="member?.text">
        {{ member.text }}
      </p>
      <strong *ngIf="member?.courses">Kurse</strong>
      <p *ngIf="member?.courses">
        {{ member.courses }}
      </p>
      <strong *ngIf="member?.extra">Extras</strong>
      <p>
        {{ member.extra }}
      </p>
    </div>
  </mat-card>-->
</div>
