import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { School } from '../../models/school';
import { AuthService } from '../../services/auth.service';

export enum ViewMode {
  items = 'items',
  attributes = 'attributes',
}

@Component({
    selector: 'ts-routing-backend',
    templateUrl: './routing-backend.component.html',
    styleUrls: ['./routing-backend.component.css'],
    standalone: true,
    imports: [RouterOutlet]
})
export class RoutingBackendComponent implements OnInit {

  school: School = new School();
  service: any;
  viewMode: ViewMode = ViewMode.items;
  itemId = 0;

  constructor(
    private authSrv: AuthService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.authSrv.school.subscribe(school => this.school = school);

    this.route.queryParams.subscribe(params => {
      if (params.viewMode === 'attributes') {
        this.viewMode = ViewMode.attributes;
      }

      if (params.viewMode === 'items') {
        this.viewMode = ViewMode.items;
      }
    })

  }

}
