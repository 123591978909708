import { DbObj } from "./dbObj";
import { Event } from "./event";
import { EventRegistrationAnswer } from "./eventRegistrationAnswer";

export class EventRegistration implements DbObj {
  id: number;
  createdAt: Date;
  updatedAt: Date;
  firstName: string;
  lastName: string;
  email: string | null;
  phone: string | null;
  street: string | null;
  zip: string | null;
  city: string | null;
  childFirstName: string | null;
  childLastName: string;
  fk_event: number;
  event: Event;
  answers: EventRegistrationAnswer[];

  get prettyName(): string {
    return `${this.firstName} ${this.lastName}`;
  };
  
  get subtitle(): string {
    return this.event.subtitle;
  };
  
}