<div class="menu pl-[1.125rem] pr-2.5 pb-2">
  <div class="flex flex-row justify-between space-x-2 items-center">
    <h3 class="font-bold text-2xl text-gray-800 truncate">
      {{ backend?.prettyName }}
    </h3>

    <button
      class="flex font-bold bg-orange text-white shadow-md hover:bg-orangeHover rounded-full px-3 py-1.5 text-sm items-center gap-1"
      [routerLink]="[
    '/backend',
    { outlets: { backendOutlet: [this.backend.dbName], backendItemOutlet: [this.backend.dbName, 'new'] } },
  ]">
      <mat-icon>add</mat-icon><span>neu</span>
    </button>
  </div>

  <div class="relative mt-4 rounded-md shadow-sm">
    <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
      </svg>
    </div>
    <input type="text" [(ngModel)]="filterStr" (input)="onFilter(filterStr)" placeholder="Suchen" name="filter"
      id="filter"
      class="pl-10 pr-2 py-2 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md">
  </div>

  <!-- <div class="flex flex-col">
    <mat-form-field>
      <mat-label>
        <mat-icon>filter_list</mat-icon>filtern
      </mat-label>
      <mat-select [(ngModel)]="filteredColumns" multiple>
        <mat-option *ngFor="let column of filterBackend?.columns" [value]="column">
          {{ column.prettyName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="mb-4" *ngIf="filteredColumns.length > 0">
      <div *ngFor="let column of filteredColumns">
        <ts-generic-form-element [column]="column"></ts-generic-form-element>
      </div>
    </div>
  </div> -->
</div>
