import { Component, OnInit } from '@angular/core';
import { School } from '../../models/school';
import { SchoolService } from '../../services/school.service';
import { MovieService } from '../../services/movie.service';
import { ImageMovie } from '../../models/imageMovie';
import { RouterLink } from '@angular/router';
import { NgFor } from '@angular/common';

@Component({
    selector: 'ts-movie-list',
    templateUrl: './movie-list.component.html',
    styleUrls: ['./movie-list.component.css'],
    standalone: true,
    imports: [NgFor, RouterLink]
})
export class MovieListComponent implements OnInit {

  public school: School = new School();
  public movies: ImageMovie[] = new Array();

  constructor(
    private schoolSrv: SchoolService,
    private movieSrv: MovieService
  ) { }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
      this.movieSrv.bySchoolId(this.school.id).subscribe(movies => {
        this.movies = movies;
      });
    });
  }

}
