import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BackendEditService {
  filter: Subject<string> = new Subject<string>();
  itemHasBeenEdited: Subject<void> = new Subject<void>();

  constructor() {

  }
}
