import { Component, OnInit } from '@angular/core';
import { NgForm, FormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { School } from '../../models/school';
import { AuthService } from '../../services/auth.service';
import { SchoolService } from '../../services/school.service';
import { LoaderComponent } from '../../loader/loader.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ts-routing-login',
    templateUrl: './routing-login.component.html',
    styleUrls: ['./routing-login.component.css'],
    standalone: true,
    imports: [RouterLink, NgIf, LoaderComponent, FormsModule]
})
export class RoutingLoginComponent implements OnInit {

  errorMsg: string = null;
  isLoading = false;
  school: School = new School();

  constructor(private authSrv: AuthService, private router: Router, private schoolSrv: SchoolService) { }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    this.isLoading = true;
    const username = form.value.username;
    const password = form.value.password;

    this.authSrv.login(username, password).subscribe(res => {
      this.isLoading = false;
      this.router.navigate(['/backend']);
    }, error => {
      this.isLoading = false;
      this.errorMsg = 'Fehler bei der Anmeldung!';
    });
  }

  ngOnInit() {
    this.schoolSrv.current().subscribe((current) => {
      this.school = current;
    })
  }

}
