<div class="max-w-6xl mx-auto">
  <div [@routerTransition]="" class="ageClassHeader mx-2">
    <nav
      mat-tab-nav-bar
      mat-align-tabs="center"
      class="justify-center hidden sm:block"
    >
      <a
        mat-tab-link
        [active]="ageClass.nameUrl == params['ageClass']"
        *ngFor="let ageClass of ageClasses"
        [routerLink]="['/kurse', ageClass.nameUrl]"
      >
        <mat-icon [innerHtml]="ageClass.icon"></mat-icon>
        &nbsp;{{ ageClass.name }}
      </a>
    </nav>
    <div class="flex flex-col sm:flex-row items-center">
      <div class="mt-12 mb-4">
        <h1 class="text-3xl font-bold">
          <ng-container *ngIf="!ageClass?.nameTitle?.includes('kurs')"
            >Unsere {{ school.city }}er Tanzkurse für </ng-container
          >{{ ageClass.nameTitle }}
        </h1>
        <p class="mt-1 text-sm text-gray-600" [innerHTML]="ageClass.text"></p>
      </div>
      <ts-picture
        *ngIf="ageClass.imgCoursepage"
        [img]="ageClass?.imgCoursepage"
      ></ts-picture>
    </div>

    <ts-course-buttons
      *ngIf="ageClass.id !== 4"
      [ageClassId]="ageClass.id"
    ></ts-course-buttons>
  </div>
  <div class="mx-2">
    <ts-course-list [ageClassId]="ageClass.id"></ts-course-list>
  </div>
</div>
