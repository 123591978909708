import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Backend } from "../../../models/backend";
import { BackendEditItem } from "../../../models/backendEditItem";
import { DbObj } from "../../../models/dbObj";
import { BackendService } from "../../../services/backend.service";
import { GenericListComponent } from "../../generic-list/generic-list.component";

@Component({
    selector: 'ts-join-select-list',
    template: `
    <div style="overflow:scroll; max-height: 100vh">
    <ts-generic-list mode="select" [backend]="this.refBackend" (itemClicked)="onItemClick($event)" [enableEditSelection]="false" [genericService]="refService"></ts-generic-list>
    </div>
  `,
    styleUrls: ['./join-many-field.component.css'],
    standalone: true,
    imports: [GenericListComponent]
})
export class JoinManySelectListComponent implements OnInit {

  localService: any;
  refService: any;
  item: DbObj;
  refBackend: Backend;

  constructor(
    public dialogRef: MatDialogRef<JoinManySelectListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { ref: BackendEditItem, localService: any, item: DbObj, refBackend: Backend },
    public backendSrv: BackendService,
    ) {
    this.refService = data.ref.service;
    this.localService = data.localService;
    this.item = data.item;
    this.refBackend = data.refBackend;
  }

  onItemClick($event) {
    this.localService.addRef(this.item.id, this.refService, $event.id).subscribe(() => {
      this.dialogRef.close();
    });
  }

  ngOnInit(): void {
  }
}
