import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Backend } from '../../../models/backend';
import { Column } from '../../../models/column';
import { AuthService } from '../../../services/auth.service';
import { BackendService } from '../../../services/backend.service';
import { SchoolService } from '../../../services/school.service';
import { ServiceLoaderService } from '../../../services/service-loader.service';

@Component({
    selector: 'ts-join-field',
    templateUrl: './join-field.component.html',
    styleUrls: ['./join-field.component.css'],
    standalone: true,
    imports: [NgIf, NgClass, FormsModule, NgFor]
})
export class JoinFieldComponent implements OnInit {

  refService: any;
  refBackend: Backend = new Backend();
  refItems: any[] = [];
  filteredRefItems: any[] = [];
  loading: boolean = false;
  filterStr: string = '';

  dropdownOpen: boolean = false;

  constructor(
    private loaderSrv: ServiceLoaderService,
    private backendSrv: BackendService,
    private snackBar: MatSnackBar,
    private schoolSrv: SchoolService,
    private authSrv: AuthService,
    private elementRef: ElementRef,
  ) { }

  @Input() column: Column = new Column();
  @Output() columnChange = new EventEmitter();

  onItemSelect(newId: any) {
    this.column.data = newId;
    this.dropdownOpen = false
    this.filterStr = '';
    this.filteredRefItems = this.refItems;
    this.onChange();
  }

  onChange() {
    this.columnChange.emit(this.column);
  }

  findItemById(id: number) {
    return this.refItems?.find(refItem => refItem.id === id);
  }

  onFilter() {
    this.filteredRefItems = this.refItems
      .filter(refItem =>
        refItem.prettyName.toLowerCase().includes(this.filterStr.toLowerCase())
      );
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.dropdownOpen = false;
    }
  }

  ngOnInit() {
    this.loading = true;
    if (this.column.refTable) {
      this.refService = this.loaderSrv.load(this.column.refTable);
    } else {
      this.snackBar.open('Es fehlt die Relationsdefinition für diesen Datentypen!', null, {
        duration: 3000
      });
    }

    if (this.refService) {

      this.backendSrv.findByName(this.refService.dbTable).subscribe((backend) => {
        this.refBackend = backend;

        this.authSrv.school.subscribe(school => {
          const obs = this.refBackend.isDependentOnSchool
            ? this.refService.findBySchool(school.id)
            : this.refService.findAll();

          obs.subscribe((data) => {
            this.refItems = data;
            this.filteredRefItems = this.refItems;
            this.loading = false;
          });
        });
      });
    }
  }

}
