import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Course } from '../../models/course';
import { CourseLevel } from '../../models/courseLevel';
import { CourseStartDate } from '../../models/courseStartDate';
import { CourseLevelService } from '../../services/course-level.service';
import { CourseStartDatesService } from '../../services/course-start-dates.service';
import { CourseService } from '../../services/course.service';
import { NgIf } from '@angular/common';
import { MatList, MatListItem } from '@angular/material/list';

@Component({
    selector: 'ts-course-data',
    templateUrl: './course-data.component.html',
    styleUrls: ['./course-data.component.css'],
    standalone: true,
    imports: [MatList, MatListItem, NgIf]
})
export class CourseDataComponent implements OnChanges, OnInit {

  @Input() courseId: number;
  @Input() startDateId: number;

  public startDate: CourseStartDate;
  public course: Course = new Course();
  public courseLevel: CourseLevel = new CourseLevel();

  constructor(private courseSrv: CourseService,
    private courseLevelSrv: CourseLevelService,
    private startDateSrv: CourseStartDatesService, ) { }

  updateCourse() {
    if (this.courseId !== undefined) {
      this.courseSrv.findById(this.courseId).subscribe(course => {
        this.course = course;
        this.courseLevel = this.course.courseLevel;
      });
    }
    if (this.startDateId) {
      this.startDateSrv.findById(this.startDateId).subscribe(startDate => {
        this.startDate = startDate;
      });
    }
  }

  ngOnInit() {
    this.updateCourse();
  }

  ngOnChanges() {
    this.updateCourse();
  }

}
