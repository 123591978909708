import { Component, OnInit } from '@angular/core';
import { SchoolService } from '../../services/school.service';
import { School } from '../../models/school';
import { GalleryService } from '../../services/gallery.service';
import { TimeService } from '../../services/time.service';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';
import { LoaderComponent } from '../../loader/loader.component';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { MatCard } from '@angular/material/card';
import { NgIf, NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'ts-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.css'],
    standalone: true,
    imports: [NgIf, MatCard, MatTabGroup, NgFor, MatTab, LoaderComponent, MatIcon, MatDivider, MatGridList, MatGridTile, NgClass]
})
export class GalleryComponent implements OnInit {


  /** CONSTS */

  /**
   * Amount of images per page
   */
  public pageSize = 40;

  public school: School = new School();
  public years = [];

  /**
   * URL of the image that is beeing viewed on the right side
   */
  public currImg = '';
  public currImgIndex = 0;
  public currGallery = undefined;
  public currImgs = undefined;
  public currGalleryPageIndex = 0;
  public currentYear = 0;

  public dummyImgUrl = '';

  constructor(
    private schoolSrv: SchoolService,
    public gallerySrv: GalleryService
  ) { }

  /**
   * Splits an array into chunks and returns an array
   * containing them
   * @param arr the Array to process
   * @param len the length of the subarrays
   * @return an array includung chunks of size len
   */
  public splitArray (arr, len) {
    const chunks = [];
    let i = 0;
    const n = arr.length;
    while (i < n) {
      chunks.push(arr.slice(i, i += len));
    }
    return chunks;
  }

  public getImgPercentage() {
    return Math.round(((this.currGalleryPageIndex) * this.pageSize
    + this.currImgIndex) / this.currGallery.content.length * 100);
  }

  /**
   * Sets the currently viewed page of items in a gallery to the next
   */
  public incCurrGalleryPage() {
    this.currGalleryPageIndex = this.currGalleryPageIndex < this.currImgs.length - 1
                            ? this.currGalleryPageIndex + 1
                            : 0;
  }

  /**
   * Sets the currently viewed page of items in a gallery to the previous
   */
  public decCurrGalleryPage() {
    this.currGalleryPageIndex = this.currGalleryPageIndex > 0
                            ? this.currGalleryPageIndex - 1
                            : this.currImgs.length - 1;
  }

  public decImage() {
    this.currImgIndex--;
    const totalImgIndex = (this.currGalleryPageIndex) * this.pageSize
                      + this.currImgIndex;
    // prev page
    if (this.currImgIndex % this.pageSize === -1
        || totalImgIndex < 0) {
      this.decCurrGalleryPage();
      if (this.currGalleryPageIndex !== this.currImgs.length - 1) {
        this.currImgIndex = this.pageSize - 1;
      } else {
        this.currImgIndex = (this.currGallery.content.length % this.pageSize) - 1;
      }
    }

    this.currImg = this.currGallery.content[totalImgIndex];
  }

  public incImage() {
    this.currImgIndex++;
    const totalImgIndex = (this.currGalleryPageIndex) * this.pageSize
                      + this.currImgIndex;
    // next page
    if (this.currImgIndex % this.pageSize === 0
      || totalImgIndex >= this.currGallery.content.length) {
      this.incCurrGalleryPage();
      this.currImgIndex = 0;
    }
    this.currImg = this.currGallery.content[totalImgIndex];
  }

  public setCurrImg(url: string, pos: number) {
    this.currImg = url;
    this.currImgIndex = pos;
  }

  /**
   * Opens the gallery provided as param
   * @param year Number of the year of the gallery
   * @param gallery gallery to open
   */
  public openGallery(year: number, gallery: any[]) {
    this.currGallery = gallery;
    this.currGalleryPageIndex = 0;
    this.currImgs = this.splitArray(this.currGallery.content, this.pageSize);
    this.currentYear = year;
    // First image of the first page
    this.currImg = this.currImgs[0][0];
    this.currImgIndex = 0;
  }

  /**
   * is called after leaving the gallery view and resets the values
   * for doing so
   */
  public resetView() {
    this.currImg = undefined;
    this.currImgIndex = 0;
    this.currImgs = undefined;
    this.currGallery = undefined;
    this.currGalleryPageIndex = 0;
  }

  /**
   * gets the full path of an image
   * @param img fileName of the image
   */
  public getImagePath(img: String): String {
    return GalleryService.imagePrefix + this.school.short_name
           + '/' + this.currentYear + '/'
           + this.currGallery.name + '/' + img;
  }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
      this.dummyImgUrl = `assets/img/${this.school.short_name}.saal.jpg`;
      this.gallerySrv.bySchoolName(this.school.short_name)
        .subscribe(gallery => {
          this.years = gallery;
          this.currentYear = this.years[0].name;
      });
    });
  }

}
