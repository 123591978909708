import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Course } from '../../models/course';
import { School } from '../../models/school';
import { CourseService } from '../../services/course.service';
import { SchoolService } from '../../services/school.service';

@Component({
    selector: 'ts-routing-checked-out',
    templateUrl: './routing-checked-out.component.html',
    styleUrls: ['./routing-checked-out.component.css'],
    standalone: true,
    imports: [RouterLink]
})
export class RoutingCheckedOutComponent implements OnInit {

  public course: Course = new Course();
  public school: School = new School();

  constructor(
    private courseSrv: CourseService,
    private schoolSrv: SchoolService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.courseSrv.findById(this.route.snapshot.params.id).subscribe((course) => {
      this.course = course;
    });

    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
    });
  }

}
