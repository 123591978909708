<div class="relative py-8 sm:py-16 bg-white" [ngClass]="{
    'pb-0': !courseLevel.courses || courseLevel.courses.length === 0
  }">
  <div class="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block" aria-hidden="true"></div>
  <div class="max-w-6xl mx-auto bg-orange lg:bg-transparent lg:px-8">
    <div class="lg:grid lg:grid-cols-12">
      <div class="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
        <div class="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden" aria-hidden="true"></div>
        <div class="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
          <div class="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
            <ts-picture *ngIf="courseLevel?.img"
              class="object-cover object-center rounded-3xl shadow-2xl overflow-hidden" [alt]="courseLevel?.img"
              [img]="courseLevel?.img">
            </ts-picture>

            <ts-picture *ngIf="!courseLevel?.img"
              class="object-cover object-center rounded-3xl shadow-2xl overflow-hidden" [alt]="'Tanzspass.com'"
              [img]="school.imgRoomTeaser">
            </ts-picture>
          </div>
        </div>
      </div>

      <div
        class="relative bg-orange lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
        <div
          class="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
          <h2 class="text-3xl font-extrabold text-white" id="join-heading">
            {{ courseLevel.description }}
          </h2>
          <p class="text-xl text-white" [innerHtml]="courseLevel.text"></p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="max-w-6xl mx-auto flex flex-col gap-4">
  <div class="mx-2" *ngIf="courseLevel.courses?.length > 0">
    <h2 class="text-2xl sm:text-3xl font-bold text-center text-gray-800">
      Jetzt zu einem Kurs<br /> anmelden
    </h2>
    <ts-course-table class="mt-4" [courses]="courseLevel.courses"
      *ngIf="courseLevel.courses && courseLevel.courses.length > 0">
    </ts-course-table>
  </div>
  <div
    class="mt-8 sm:mx-2 shadow-inner sm:shadow-sm lg:mx-0 bg-gray-200 sm:bg-gray-100 sm:rounded-xl py-6 px-8"
    *ngIf="courseLevel?.long_text || courseLevel?.dance?.id">
    <p *ngIf="courseLevel.dance && courseLevel.dance.id" class="text-gray-700 sm:text-gray-600 text-sm prose"
      [innerHtml]="courseLevel.dance.text"></p>
    <p *ngIf="!courseLevel.dance || !courseLevel.dance.id" class="text-gray-700 sm:text-gray-600 text-sm prose"
      [innerHtml]="courseLevel.long_text"></p>
  </div>
</div>
<div *ngIf="courseLevel.dance && courseLevel.dance.id && courseLevel.dance.spotify_playlist" class="max-w-6xl mx-auto">
  <h3 class="text-xl font-bold mt-8">
    Spotify-Playlist zu {{ courseLevel.description }}
  </h3>
  <iframe class="overflow-hidden rounded-lg mt-2" style="width: 100%; min-height: 250px"
    *ngIf="courseLevel.dance.spotify_playlist" [src]="
      'https://open.spotify.com/embed/playlist/' +
        courseLevel.dance.spotify_playlist | safe
    " frameborder="0"></iframe>
</div>
<div class="bg-white">
  <div class="max-w-6xl mx-auto sm:mt-8 lg:mt-12 pb-8 px-4 sm:pb-12 sm:px-6 lg:px-8 lg:justify-between">
    <div class="flex flex-col sm:flex-row items-center justify-between mt-2">
      <div class="flex-shrink-0">
        <h3 class="text-xl text-gray-500 pl-2">Über uns:</h3>
        <h2 class="text-3xl font-extrabold text-gray-800 sm:tracking-tight">
          {{ school.name }}
        </h2>
      </div>
      <img class="h-24 order-first sm:order-none max-w-32 w-32 flex-grow-0 block" [src]="school?.getFaviconPath()"
        [alt]="school?.name" />
    </div>
    <p class="pl-5 mt-5 text-md text-gray-500" [innerHTML]="school.aboutUsText"></p>
  </div>
</div>
