import { Component, forwardRef, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BackendEditItem } from "../../models/backendEditItem";
import { GenericFormComponent } from "../generic-form/generic-form.component";

@Component({
    selector: "ts-generic-form-dialog",
    templateUrl: "./generic-form-dialog.component.html",
    styleUrls: ["./generic-form-dialog.component.css"],
    standalone: true,
    imports: [forwardRef(() => GenericFormComponent)],
})
export class GenericFormDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<GenericFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BackendEditItem
  ) {
    data = new BackendEditItem();
  }

  ngOnInit() {}

  abort(): void {
    this.dialogRef.close();
  }
}
