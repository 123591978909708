import { Component, OnChanges, OnInit } from '@angular/core';
import { AgeClass } from '../../models/ageClass';
import { AgeClassService } from '../../services/age-class.service';
import { ActivatedRoute, Params, RouterLink } from '@angular/router';
import { routerTransition } from '../../router.animations';
import { EventListComponent } from '../../event/event-list/event-list.component';
import { NgFor } from '@angular/common';
import { MatTabNav, MatTabLink } from '@angular/material/tabs';

@Component({
    selector: 'ts-routing-event',
    templateUrl: './routing-event.component.html',
    styleUrls: ['./routing-event.component.css'],
    animations: [routerTransition()],
    standalone: true,
    imports: [
        MatTabNav,
        NgFor,
        MatTabLink,
        RouterLink,
        EventListComponent,
    ],
})
export class RoutingEventComponent implements OnChanges, OnInit {

  public ageClasses: AgeClass[] = new Array();
  public ageClass: AgeClass = new AgeClass();
  public params: Params;
  public loaded = false;

  constructor(private ageClassSrv: AgeClassService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.ageClassSrv.findAll().subscribe(ageClasses => {
      this.ageClasses = ageClasses.slice(0, 3);
    });
    this.route.params.subscribe(params => {
      this.params = params;
      this.ageClassSrv.byNameUrl(params['ageClass']).subscribe(ageClass => {
        this.ageClass = ageClass;
        this.loaded = true;
      });
    });
  }

  ngOnChanges() {
  }

}
