<div *ngIf="imageCount > 0">
  <h3 class="text-orangeLight text-lg font-bold">{{room.name}}</h3>
  <div class="grid gap-4 mt-3" [ngClass]="{'grid-cols-2': imageCount > 1}">
    <div *ngIf="room?.img1" class="overflow-auto rounded-xl shadow-lg w-full max-h-72">
      <ts-picture [img]="room.img1">
      </ts-picture>
    </div>
    <div *ngIf="room?.img2" class="overflow-auto rounded-xl shadow-lg w-full max-h-72">
      <ts-picture [img]="room.img2">
      </ts-picture>
    </div>
    <div *ngIf="room?.img3" class="overflow-auto rounded-xl shadow-lg w-full max-h-72">
      <ts-picture [img]="room.img3">
      </ts-picture>
    </div>
    <div *ngIf="room?.img4" class="overflow-auto rounded-xl shadow-lg w-full max-h-72">
      <ts-picture [img]="room.img4">
      </ts-picture>
    </div>
  </div>
</div>
