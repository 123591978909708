import { Component, Input, OnInit } from '@angular/core';
import { Room } from '../../models/room';
import { PictureComponent } from '../../shared/picture/picture.component';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'ts-room-elem',
    templateUrl: './room-elem.component.html',
    styleUrls: ['./room-elem.component.css'],
    standalone: true,
    imports: [NgIf, NgClass, PictureComponent]
})
export class RoomElemComponent implements OnInit {


  imageCount = 0;

  @Input() room: Room;

  constructor() { }

  ngOnInit(): void {
    if (this.room.img1) {
      this.imageCount++;
    }

    if (this.room.img2) {
      this.imageCount++;
    }

    if (this.room.img3) {
      this.imageCount++;
    }

    if (this.room.img4) {
      this.imageCount++;
    }
  }

}
