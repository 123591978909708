<div class="mt-16 -mb-6" *ngFor="let courseLevelType of courseLevels">
  <ng-container *ngIf="courseLevelType.length > 0">
    <h2 class="font-bold text-3xl text-center" *ngIf="ageClassId !== 4">
      {{
        courseLevelType[0].type.charAt(0).toUpperCase() +
          courseLevelType[0].type.slice(1)
      }}tanz
    </h2>
    <div
      class="mt-12"
      [id]="courseLevel.description + '_card'"
      *ngFor="let courseLevel of courseLevelType"
    >
      <div class="flex space-x-4 items-center">
        <h2 class="text-lg font-bold">
          {{ courseLevel.description }}
          <span *ngIf="ageClassId === 5 && courseLevel.ageClass.id !== 4"
            >[{{ courseLevel.ageClass.name }}]</span
          >
        </h2>
        <button
          *ngIf="courseLevel.long_text && courseLevel.long_text.length > 0"
          class="text-xs border py-1.5 px-3 rounded-full border-gray-300 text-secondary"
          [routerLink]="[courseLevel.description_url]"
        >
          mehr erfahren
        </button>
      </div>

      <p class="mt-2 text-gray-600 text-sm" [innerHTML]="courseLevel.text"></p>
      <ts-course-table
        *ngIf="courseLevel.courses"
        [courses]="courseLevel.courses"
        [noStartDatesText]="courseLevel.no_start_dates_text"
      >
      </ts-course-table>
    </div>
  </ng-container>
</div>
