<div class="relative bg-yellow-800 h-92 top-menu">
  <div class="absolute inset-0">
    <img class="w-full h-full object-cover" src="/assets/img/tmp/hero.jpg" alt="" />
    <div class="absolute inset-0 bg-yellow-800 mix-blend-multiply" aria-hidden="true"></div>
  </div>
  <div class="relative max-w-6xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
    <h1 class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
      Preise
    </h1>
    <p class="mt-6 text-xl text-indigo-100 max-w-3xl">
      Mattis amet hendrerit dolor, quisque lorem pharetra. Pellentesque lacus
      nisi urna, arcu sociis eu. Orci vel lectus nisl eget eget ut consectetur.
      Sit justo viverra non adipisicing elit distinctio.
    </p>
  </div>
</div>
<div class="pt-24 max-w-6xl mx-auto pb-32">
  <div
    class="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
    <div *ngFor="let price of prices" class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
      <div class="p-6">
        <h2 class="text-xl leading-6 font-medium text-gray-800">
          {{ price.name }}
        </h2>
        <p class="mt-4 text-sm text-gray-500">
          {{ price.description }}
        </p>
        <p class="mt-8">
          <span class="text-4xl font-extrabold text-gray-800">
            {{ price.price / 100 | currency: "€" }}</span>
          <span class="text-base font-medium text-gray-500">/Monat</span>
        </p>
        <a routerLink="/online/registrieren"
          class="mt-8 block w-full bg-gray-800 border border-gray-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-gray-900">{{
          price.name }} buchen</a>
      </div>
      <div class="pt-6 pb-8 px-6">
        <h3 class="text-xs font-medium text-gray-800 tracking-wide uppercase">
          Was ist enthalten?
        </h3>
        <ul class="mt-6 space-y-4">
          <li class="flex space-x-3" *ngFor="let feature of price.features">
            <!-- Heroicon name: solid/check -->
            <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd" />
            </svg>
            <span class="text-sm text-gray-500">{{ feature }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
