<div>
  <ng-container *ngFor="let courseLevelType of courseLevels">
    <div *ngIf="courseLevelType.length > 0" style="display: inline">
      <h2 class="font-bold text-lg mt-4">
        {{
          courseLevelType[0].type.charAt(0).toUpperCase() +
            courseLevelType[0].type.slice(1)
        }}tanz
      </h2>
      <div class="flex gap-2 flex-wrap mt-1">
        <button
          [routerLink]="['./']"
          [fragment]="courseLevel.description + '_card'"
          class="px-3 py-1.5 bg-orange text-white rounded-full text-xs"
          *ngFor="let courseLevel of courseLevelType"
        >
          {{ courseLevel.description }}
        </button>
        <button
          *ngIf="ageClassId === 3 && courseLevelType[0].type === 'paar'"
          [routerLink]="['/', 'kurse', 'sonderkurse']"
          class="px-3 py-1.5 bg-orange text-white rounded-full text-xs"
        >
          Kurzkurse
        </button>
      </div>
    </div>
  </ng-container>
</div>
