<div class="mt-2 overflow-hidden">
  <span class="text-sm font-medium text-gray-700">{{column.prettyName}}<span *ngIf="column.required">*</span></span>
  <div class="flex items-center gap-2">
    <div class="flex flex-col">
      <div *ngIf="!column.data" class="flex items-center justify-center bg-orangeLight text-white rounded-md h-36 w-80">
        <span class="text-sm">Kein Bild vorhanden.</span>
      </div>
      <ts-picture class="max-w-xs rounded-md overflow-hidden" *ngIf="column.data" [img]="column.data"></ts-picture>
      <div [style]="'width: ' + uploadProgress * 320 + 'px'" class="mt-1 h-1 bg-orange rounded-full"></div>
    </div>

    <form class="flex flex-col items-start gap-2.5">
      <button *ngIf="column.data" type="button" (click)="column.data = null"
        class="rounded-full flex items-center gap-1 px-3 py-1.5 text-orangeLight bg-white border border-orangeLight hover:text-orange shadow-md text-sm font-bold">
        <mat-icon>delete</mat-icon>
        <span>Bild löschen</span>
      </button>
      <label [for]="column.dbName"
        class="rounded-full flex items-center gap-1 px-3 py-1.5 text-orangeLight bg-white border border-orangeLight hover:text-orange shadow-md text-sm font-bold">
        <mat-icon>file_upload</mat-icon>
        <span *ngIf="!column.data">Bild hochladen</span>
        <span *ngIf="column.data">Bild austauschen</span>
      </label>
      <input class="hidden" [id]="column.dbName" accept="image/png, image/gif, image/jpeg" placeholder="Bild hochladen"
        [name]="column.dbName" type="file" (change)="onChange($event)" />
    </form>
  </div>
</div>
