import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ImageMovie } from "../../models/imageMovie";
import { MovieService } from "../../services/movie.service";
import { SafePipe } from "../../safe.pipe";

@Component({
    selector: "ts-routing-movie",
    templateUrl: "./routing-movie.component.html",
    styleUrls: ["./routing-movie.component.css"],
    standalone: true,
    imports: [SafePipe],
})
export class RoutingMovieComponent implements OnInit {
  movie: ImageMovie = new ImageMovie();

  constructor(private router: ActivatedRoute, private movieSrv: MovieService) {}

  public updateNews() {
    this.router.params.subscribe((params) => {
      this.movieSrv.findById(params.movieId).subscribe((movie) => {
        this.movie = movie;
      });
    });
  }

  ngOnInit(): void {
    this.updateNews();
  }
}
