<div class="max-w-6xl mx-auto pb-6">
  <!-- desktop -->
  <div class="hidden sm:block relative w-full h-auto sm:mt-8 sm:rounded-larger sm:shadow-lg overflow-hidden"
    *ngIf="news">
    <ts-picture class="object-cover w-full h-full" [img]="news.image_name" width="100%" height="100%">
    </ts-picture>

    <div
      class="sm:absolute sm:bottom-12 sm:left-12 space-y-4 sm:bg-translucent p-4 sm:p-8 shadow-sm sm:rounded-larger sm:text-white">
      <h1 class="text-xl sm:text-4xl font-bold">{{ news.title }}</h1>
      <p class="text-sm mt-6 max-w-prose">
        {{ news.teaser }}
      </p>
    </div>
  </div>

  <!-- mobile -->
  <div class="waves sm:hidden relative shadow-xl sm:rounded-2xl sm:overflow-hidden h-72 sm:mt-8 p-8 mx-0 sm:mx-2">
    <div class="absolute inset-0">
      <ts-picture class="object-cover w-full h-full" [img]="news.image_name" width="100%" height="100%">
      </ts-picture>
      <div class="absolute inset-0 bg-gray-600 mix-blend-multiply"></div>
    </div>

    <div class="relative h-full flex flex-col justify-center items-center">
      <h1 class="text-center text-2xl font-extrabold tracking-tight sm:text-3xl lg:text-5xl text-white">
        {{ news.title }}
      </h1>
      <p class="text-xs mt-3 max-w-prose text-white">
        {{ news.teaser }}
      </p>

    </div>
  </div>

  <div class="mx-2 sm:mx-auto mt-4">
    <p class="prose prose-sm" [innerHtml]="news.text"></p>
    <button [routerLink]="'/' + news.cta_link" *ngIf="news.cta_active"
      class="rounded-full px-3 py-1.5 bg-orange text-white text-sm font-bold">
      {{ news.cta_text }}
    </button>
  </div>
</div>
