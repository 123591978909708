<div class="flex h-full overflow-y-auto">
  <ts-backend-menu [selectedBackend]="backend" class="flex flex-col h-full overflow-y-auto flex-shrink-0 w-64 pb-4">
  </ts-backend-menu>
  <div *ngIf="backend?.id" class="flex h-full overflow-y-auto w-full animate-in fade-in">
    <div class="flex flex-col w-80 flex-shrink-0 h-full overflow-y-auto">
      <ts-generic-menu [backend]="backend" class="w-full"></ts-generic-menu>
      <ts-generic-list mode="navigate" [backend]="backend" [genericService]="genericService" class="w-full h-full overflow-y-auto">
      </ts-generic-list>
    </div>

    <!-- <ts-generic-form [backend]="backend" ></ts-generic-form> -->
  </div>
</div>
