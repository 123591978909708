import { Component, OnInit } from '@angular/core';
import { Room } from '../../models/room';
import { School } from '../../models/school';
import { routerTransition } from '../../router.animations';
import { RoomService } from '../../services/room.service';
import { SchoolService } from '../../services/school.service';
import { RoomElemComponent } from '../../room/room-elem/room-elem.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'ts-routing-room',
    templateUrl: './routing-room.component.html',
    styleUrls: ['./routing-room.component.css'],
    animations: [routerTransition()],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        RoomElemComponent,
    ],
})
export class RoutingRoomComponent implements OnInit {

  public school: School = new School();
  public rooms: Room[] = [];

  constructor(private schoolSrv: SchoolService, private roomSrv: RoomService) { }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
      this.roomSrv.bySchoolId(school.id).subscribe(rooms => {
        this.rooms = rooms;
      });
    });
  }

}
