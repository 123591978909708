<ul class="flex flex-col divide-y divide-orangeLightX overflow-y-auto h-full bg-warmGray rounded-xl p-2"
  *ngIf="!updating">
  <h3 class="font-bold text-gray-600">Website-Elemente</h3>
  <div class="flex flex-col overflow-y-auto h-full mt-1">
    <li class="flex cursor-pointer rounded-md m-0.5"
      [ngClass]="selectedIndex === i ? 'bg-orangeLight text-white': 'text-gray-700 hover:bg-orangeLightX hover:text-orange'"
      *ngFor="let backend of tables; let i = index" (click)="selectedIndex = i" [routerLink]="[
    '/backend',
    { outlets: { backendOutlet: [this.backend.dbName] } },
  ]">
      <div class="flex mx-4 my-2 items-center space-x-2">
        <mat-icon *ngIf="backend && backend.icon">
          {{backend?.icon}}
        </mat-icon>
        <span class="text-sm font-semibold">{{backend.prettyName}}</span>
      </div>
    </li>
  </div>
</ul>
<ts-loader class="center" *ngIf="updating"></ts-loader>
