<div class="mt-2">
  <label id="listbox-label" class="block text-sm font-medium text-gray-700">{{column?.prettyName}}<span
      *ngIf="column.required">*</span></label>
  <div class="relative mt-1">
    <button [disabled]="loading" type="button" (click)="dropdownOpen = !dropdownOpen"
      class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-orange focus:outline-none focus:ring-1 focus:ring-orange sm:text-sm"
      aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
      <span *ngIf="!loading" class="block truncate">{{findItemById(column?.data)?.prettyName
        || 'kein Element ausgewählt'}}</span>
      <span class="text-gray-500" *ngIf="loading">lädt...</span>
      <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <!-- Heroicon name: mini/chevron-up-down -->
        <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
          aria-hidden="true">
          <path fill-rule="evenodd"
            d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
            clip-rule="evenodd" />
        </svg>
      </span>
    </button>

    <div [ngClass]="dropdownOpen ? '' : 'hidden'"
      class="shadow-md rounded-md absolute z-10 max-h-60 w-full overflow-hidden bg-warmGray">
      <ul
        class="mt-1 w-full max-h-60 bg-warmGray text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm rounded-md overflow-hidden overflow-y-auto"
        tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
        <li
          [ngClass]="column.data === null ? 'text-white bg-orange' : 'text-gray-900 hover:bg-orangeLightX hover:text-orange'"
          (click)="onItemSelect(null)"
          class="relative m-0.5 font-bold rounded-md cursor-pointer select-none py-2 pl-3 pr-9" id="listbox-option-0"
          role="option">
          kein Element ausgewählt
        </li>
        <div class="m-2">
          <input class="w-full py-2 px-4 block bg-white rounded-md border border-orangeLightX shadow-sm text-base"
            type="text" [(ngModel)]="filterStr" (input)="onFilter()" placeholder="filtern...">
        </div>
        <li (click)="onItemSelect(item.id)" *ngFor="let item of filteredRefItems"
          [ngClass]="column.data === item.id ? 'text-white bg-orange' : 'text-gray-900 hover:bg-orangeLightX hover:text-orange'"
          class="relative cursor-pointer m-0.5 rounded-md select-none py-2 pl-3 pr-9" id="listbox-option-0"
          role="option">
          <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
          <div class="flex flex-col w-full">
            <span class="font-bold block">{{item?.prettyName}}</span>
            <span class="font-normal block whitespace-pre-line">{{item?.subtitle}}</span>
          </div>

          <!--
          Checkmark, only display for selected option.

          Highlighted: "text-white", Not Highlighted: "text-indigo-600"
        -->
          <span *ngIf="column.data === item.id" class="text-orange absolute inset-y-0 right-0 flex items-center pr-4">
            <!-- Heroicon name: mini/check -->
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              aria-hidden="true">
              <path fill-rule="evenodd"
                d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                clip-rule="evenodd" />
            </svg>
          </span>
        </li>
      </ul>
    </div>
  </div>
</div>
