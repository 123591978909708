<div class="max-w-6xl mx-auto">
  <div class="mt-4 mx-2" [@routerTransition]="">
    <h1 class="font-bold text-3xl">Kursfreie Zeiten</h1>
    <p class="prose">
      Das Kribbeln in den Füßen... <br />
      ...hat bald schon wieder ein Ende, wenn die kursfreien Zeiten überstanden
      sind.
      <br />
      <br />
      An folgenden Tagen finden keine Kurse statt:
    </p>
    <p class="prose mt-4" [innerHTML]="school.free_time"></p>
  </div>
</div>
