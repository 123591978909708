import { Component, OnChanges, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CourseLevel } from "../../models/courseLevel";
import { School } from "../../models/school";
import { routerTransition } from "../../router.animations";
import { CourseLevelService } from "../../services/course-level.service";
import { SchoolService } from "../../services/school.service";
import { SeoService } from "../../services/seo.service";
import { SafePipe } from "../../safe.pipe";
import { CourseTableComponent } from "../../course/course-table/course-table.component";
import { PictureComponent } from "../../shared/picture/picture.component";
import { NgClass, NgIf } from "@angular/common";

@Component({
    selector: "ts-routing-course-level",
    templateUrl: "./routing-course-level.component.html",
    styleUrls: ["./routing-course-level.component.css"],
    animations: [routerTransition()],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        PictureComponent,
        CourseTableComponent,
        SafePipe,
    ],
})
export class RoutingCourseLevelComponent implements OnInit, OnChanges {
  courseLevel = new CourseLevel();
  school = new School();

  constructor(
    private courseLevelSrv: CourseLevelService,
    private route: ActivatedRoute,
    private schoolSrv: SchoolService,
    private seoSrv: SeoService
  ) {}

  update() {
    this.route.params.subscribe((params) => {
      this.schoolSrv.current().subscribe((school) => {
        this.school = school;
        this.courseLevelSrv
          .getByDescription(params.courseLevelName, school.id)
          .subscribe((courseLevel) => {
            this.courseLevel = courseLevel;
            this.seoSrv.updateTitle(
              `${this.courseLevel.description} | ${this.school.prettyName}`
            );
            this.seoSrv.updateDescription(this.courseLevel.text);
          });
      });
    });
  }

  ngOnChanges() {
    this.update();
  }

  ngOnInit() {
    this.update();
  }
}
