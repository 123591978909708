<div class="max-w-6xl mx-auto sm:mt-4">
  <div>
    <div class="sm:rounded-larger shadow-lg overflow-hidden">
      <img
        class="w-full h-full object-cover"
        [src]="school.getRoomImgPath()"
        [alt]="school.name + ' Säle'"
      />
    </div>
    <div class="mt-4 mx-2">
      <h2 class="text-3xl font-bold">Vielen Dank für Ihre Anmeldung!</h2>
      <p class="text-gray-600 mt-1">
        Die Anmeldung für {{ course.description }} wurde an uns verschickt. Sie
        erhalten in den nächsten Minuten eine Mail mit weiteren Informationen.
      </p>
      <button
        class="mt-2 bg-orange rounded-full py-2 px-3 text-white font-bold"
        [routerLink]="['/']"
      >
        Zur Startseite
      </button>
    </div>
  </div>
</div>
