import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'ts-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.css'],
    standalone: true,
    imports: [RouterLink]
})
export class IndexComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
