import { ConvService } from '../services/conv.service';
import { DbObj } from './dbObj';

export class Teammember implements DbObj {

  public id: number;
  public first_name: string;
  public last_name: string;
  public image_name: string;
  public position: string;
  public courses: string;
  public text: string;
  public extra: string;
  public e_mail: string;
  private fk_school: number;

  constructor() { }

  get prettyName() {
    return `${this.first_name} ${this.last_name}`;
  }

  get subtitle() {
    return ConvService.truncate(this.position, 30);
  }

  public getImgUrl() {
    const baseUrl = `assets/img/team/`;
    return baseUrl + this.image_name;
  }
}
