import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AgeClass } from "../models/ageClass";
import { CourseLevel } from "../models/courseLevel";
import { Dance } from "../models/dance";
import { ConvService } from "./conv.service";
import { CourseService } from "./course.service";
import { CrudService } from "./crud.service";
import { TsHttpService } from "./ts-http.service";

@Injectable({
  providedIn: "root",
})
export class CourseLevelService extends CrudService<CourseLevel> {
  static prefix = "courseLevels";
  dbTable = "course_level";

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService,
    private courseSrv: CourseService
  ) {
    super(httpSrv, CourseLevelService.prefix, CourseLevel, convSrv);
  }

  public getByDescription(description: string, schoolId: number) {
    return this.httpSrv
      .get<CourseLevel>(`schools/${schoolId}/courseLevels/name/${description}`)
      .pipe(
        map((courseLevel) => {
          courseLevel = Object.assign(new CourseLevel(), courseLevel);

          courseLevel.courses = this.courseSrv.convertCourses(
            courseLevel.courses
          );
          courseLevel.dance = Object.assign(new Dance(), courseLevel.dance);
          courseLevel.ageClass = Object.assign(
            new AgeClass(),
            courseLevel.ageClass
          );
          return courseLevel;
        })
      );
  }

  public byAgeClassId(
    ageClassId: number,
    schoolId: number,
    type: string
  ): Observable<CourseLevel[]> {
    return this.httpSrv
      .get<CourseLevel[]>(
        `schools/${schoolId}/ageClasses/${ageClassId}/courseLevels/types/${type}`
      )
      .pipe(
        map((courseLevels) => {
          const newCourseLevels: CourseLevel[] = new Array();
          courseLevels.map((courseLevel) => {
            const newCourseLevel = Object.assign(
              new CourseLevel(),
              courseLevel
            );
            if (courseLevel.courses.length > 0) {
              newCourseLevel.courses = this.courseSrv.convertCourses(
                courseLevel.courses
              );
            }
            newCourseLevel.dance = Object.assign(
              new Dance(),
              newCourseLevel.dance
            );
            newCourseLevels.push(newCourseLevel);
          });
          console.debug("newCourseLevels", newCourseLevels);
          return newCourseLevels;
        })
      );
  }
}
