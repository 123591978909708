import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DatePipe, isPlatformBrowser, NgClass, NgFor, NgIf } from "@angular/common";
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";
import { FormsModule, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatIcon } from "@angular/material/icon";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Params } from "@angular/router";
import { Dance } from "../../models/dance";
import { School } from "../../models/school";
import { Video } from "../../models/video";
import { VideoCategory } from "../../models/videoCategory";
import { routerTransition } from "../../router.animations";
import { SafePipe } from "../../safe.pipe";
import { CustomerAuthService } from "../../services/customer-auth.service";
import { DanceService } from "../../services/dance.service";
import { SchoolService } from "../../services/school.service";
import { TimeService } from "../../services/time.service";
import { VideoService } from "../../services/video.service";

@Component({
    selector: "ts-routing-customer-video",
    templateUrl: "./routing-customer-video.component.html",
    styleUrls: ["./routing-customer-video.component.css"],
    animations: [
        routerTransition(),
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0", display: "none" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
        ]),
    ],
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        NgFor,
        NgIf,
        MatIcon,
        DatePipe,
        SafePipe,
    ],
})
export class RoutingCustomerVideoComponent implements OnInit, OnChanges {
  public school: School = new School();
  public years: any[] = [];
  public dances: Dance[] = [];
  public filteredDances: Dance[] = [];
  public categories: VideoCategory[] = [];
  public filteredCategories: VideoCategory[] = [];
  public yearSelected: number;
  public danceSelected: Dance;
  public categorySelected: VideoCategory;
  public params: Params = [];
  public videos: Video[] = [];
  public filteredVideos: Video[] = [];
  storedPw: string;
  public categoryDropdownOpen: boolean;
  public categoryFilterStr: string;

  public yearDropdownOpen: boolean;
  public danceDropdownOpen: boolean;
  public danceFilterStr: string;
  public q: string;

  public form: UntypedFormGroup;

  @ViewChild("categorySelect") categorySelect: ElementRef;
  @ViewChild("yearSelect") yearSelect: ElementRef;
  @ViewChild("danceSelect") danceSelect: ElementRef;

  constructor(
    private router: ActivatedRoute,
    private timeSrv: TimeService,
    private schoolSrv: SchoolService,
    private authSrv: CustomerAuthService,
    private videoSrv: VideoService,
    private danceSrv: DanceService,
    private snackbar: MatSnackBar,
    private formBuilder: UntypedFormBuilder,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  @HostListener("document:mousedown", ["$event"])
  onGlobalClick(event): void {
    if (!this.categorySelect?.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.categoryDropdownOpen = false;
    }

    if (!this.yearSelect?.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.yearDropdownOpen = false;
    }

    if (!this.danceSelect?.nativeElement.contains(event.target)) {
      // clicked outside => close dropdown list
      this.danceDropdownOpen = false;
    }
  }

  public updateVideos(options?: { refreshDances?: boolean }) {
    this.videoSrv
      .filter(
        this.school,
        this.storedPw,
        this.yearSelected,
        this.danceSelected,
        this.categorySelected
      )
      .subscribe((videos) => {
        this.videos = videos;
        this.filteredVideos = videos;
        if (options && options.refreshDances) {
          this.danceSrv.findAll().subscribe((dances) => {
            const danceIds = this.videos.map((video) => video.fk_dance);
            // this.dances = dances.filter((dance) => danceIds.includes(dance.id));
          });
        }
      });
  }

  public resetSelection() {
    this.yearSelected = null;
    this.categorySelected = this.categories[0];
    this.danceSelected = new Dance();
    this.updateVideos();
  }

  playVideo($event) {
    $event.playVideo();
  }

  findCategoryById(id: number) {
    return this.categories?.find((refItem) => refItem.id === id);
  }

  findDanceById(id: number) {
    return this.dances?.find((refItem) => refItem.id === id);
  }

  onFilterCategory() {
    this.filteredCategories = this.categories.filter((refItem) =>
      refItem.subtitle
        .toLowerCase()
        .includes(this.categoryFilterStr.toLowerCase())
    );
  }

  onFilterDance() {
    this.filteredDances = this.dances.filter((refItem) =>
      refItem.prettyName
        .toLowerCase()
        .includes(this.danceFilterStr.toLowerCase())
    );
  }

  onCategorySelect(newItem: VideoCategory | null) {
    this.categorySelected = newItem;
    this.categoryDropdownOpen = false;
    this.categoryFilterStr = "";
    this.filteredCategories = this.categories;
    this.filteredDances = this.dances;
    this.yearSelected = null;
    this.danceSelected = new Dance();
    this.q = "";
    this.updateVideos({ refreshDances: true });
  }

  onYearSelect(year: number) {
    this.yearSelected = year;
    this.yearDropdownOpen = false;
    this.filteredDances = this.dances;
    this.danceSelected = new Dance();
    this.q = "";
    this.updateVideos({ refreshDances: true });
  }

  onDanceSelect(dance: Dance) {
    this.danceSelected = dance;
    this.danceDropdownOpen = false;
    this.danceFilterStr = "";
    this.filteredDances = this.dances;
    this.q = "";
    this.updateVideos({ refreshDances: true });
  }

  onFilterBySearch() {
    this.filteredVideos = this.videos.filter((video) =>
      video.sequence_name.toLowerCase().includes(this.q.toLowerCase())
      || (!!video.dance && video.dance.name.toLowerCase().includes(this.q.toLowerCase()))
    );
  }

  ngOnInit() {
    this.danceSrv.findAll().subscribe((dances) => {
      this.dances = dances;
      this.filteredDances = dances;
    });

    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
      this.videoSrv.categories(this.school).subscribe((categories) => {
        this.categories = categories.filter((cat) => cat.visible);
        this.filteredCategories = categories;
        this.categorySelected = this.categories[0];

        this.resetSelection();
        this.updateVideos();
      });
    });
    this.videoSrv.years().subscribe((years) => {
      this.years = years;
    });
  }

  ngOnChanges() {
    this.updateVideos();
    if (isPlatformBrowser(this.platformId)) {
      this.storedPw = localStorage.getItem("customer_pw");
    }
  }
}
