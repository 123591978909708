import { Component, OnInit } from "@angular/core";
import { NgFor } from "@angular/common";

@Component({
    selector: "ts-portal-live",
    templateUrl: "./portal-live.component.html",
    styleUrls: ["./portal-live.component.css"],
    standalone: true,
    imports: [NgFor],
})
export class PortalLiveComponent implements OnInit {
  livestreams: { name: string; logo: string; url: string }[] = [];

  constructor() {
    this.livestreams = [
      {
        name: "Riemer Stream 1",
        logo: "/assets/img/logos/logo_riemer.svg",
        url: "https://bbb.solutionshosted.de/b/tan-maw-t32",
      },
      {
        name: "Riemer Stream 2",
        logo: "/assets/img/logos/logo_riemer.svg",
        url: "https://bbb.solutionshosted.de/b/tan-gkr-j62",
      },
      {
        name: "Leseberg Stream 1",
        logo: "/assets/img/logos/logo_leseberg.svg",
        url: "https://bbb.solutionshosted.de/b/tan-jap-wpd",
      },
      {
        name: "Leseberg Stream 2",
        logo: "/assets/img/logos/logo_leseberg.svg",
        url: "https://bbb.solutionshosted.de/b/tan-jdg-2nj",
      },
      {
        name: "Higle Stream 1",
        logo: "/assets/img/logos/logo_higle.svg",
        url: "https://bbb.solutionshosted.de/b/tan-4xg-kx9",
      },
      {
        name: "Higle Stream 2",
        logo: "/assets/img/logos/logo_higle.svg",
        url: "https://bbb.solutionshosted.de/b/tan-6hc-j23",
      },
    ];
  }

  ngOnInit(): void {}
}
