import { Injectable } from "@angular/core";
import { AgeClassService } from "./age-class.service";
import { ConceptService } from "./concept.service";
import { CourseLevelService } from "./course-level.service";
import { CourseStartDatesService } from "./course-start-dates.service";
import { CourseService } from "./course.service";
import { DanceService } from "./dance.service";
import { DrinkAdditiveService } from "./drink-additive.service";
import { DrinkCategoryService } from "./drink-category.service";
import { DrinkService } from "./drink.service";
import { EventService } from "./event.service";
import { FooterIconService } from "./footer-icon.service";
import { MovieService } from "./movie.service";
import { NewsService } from "./news.service";
import { PageService } from "./page.service";
import { PartnerService } from "./partner.service";
import { PaymentMethodService } from "./payment-method.service";
import { QuestionService } from "./question.service";
import { RoomService } from "./room.service";
import { SchoolService } from "./school.service";
import { TeamService } from "./team.service";
import { TvSlideService } from "./tv-slide.service";
import { VideoCategorieService } from "./video-categorie.service";
import { VideoService } from "./video.service";

@Injectable({
  providedIn: "root",
})
export class ServiceLoaderService {
  constructor(
    private ageClassSrv: AgeClassService,
    private courseLevelSrv: CourseLevelService,
    private courseSrv: CourseService,
    private courseStartDatesSrv: CourseStartDatesService,
    private danceSrv: DanceService,
    private eventSrv: EventService,
    private movieSrv: MovieService,
    private newsSrv: NewsService,
    private partnerSrv: PartnerService,
    private teamSrv: TeamService,
    private paymentMethodSrv: PaymentMethodService,
    private videoSrv: VideoService,
    private schoolSrv: SchoolService,
    private pageSrv: PageService,
    private footerIconSrv: FooterIconService,
    private videoCatSrv: VideoCategorieService,
    private conceptSrv: ConceptService,
    private roomSrv: RoomService,
    private tvSlideSrv: TvSlideService,
    private drinkSrv: DrinkService,
    private drinkCategoryServive: DrinkCategoryService,
    private drinkAdditiveServive: DrinkAdditiveService,
    private questionSrv: QuestionService,
  ) { }

  load(name: string): any {
    switch (name) {
      case "age_class":
        return this.ageClassSrv;
      case "course_level":
        return this.courseLevelSrv;
      case "course":
        return this.courseSrv;
      case "course_start_date":
        return this.courseStartDatesSrv;
      case "dance":
        return this.danceSrv;
      case "event":
        return this.eventSrv;
      case "image_movie":
        return this.movieSrv;
      case "news":
        return this.newsSrv;
      case "business_partner":
        return this.partnerSrv;
      case "payment_method":
        return this.paymentMethodSrv;
      case "teammember":
        return this.teamSrv;
      case "video":
        return this.videoSrv;
      case "school":
        return this.schoolSrv;
      case "page":
        return this.pageSrv;
      case "footer_icon":
        return this.footerIconSrv;
      case "video_category":
        return this.videoCatSrv;
      case "concept":
        return this.conceptSrv;
      case "room":
        return this.roomSrv;
      case "tv_slide":
        return this.tvSlideSrv;
      case "drink":
        return this.drinkSrv;
      case "drink_category":
        return this.drinkCategoryServive;
      case "drink_additive":
        return this.drinkAdditiveServive;
      case "question":
          return this.questionSrv;
    }
  }
}
