<form (ngSubmit)="onSave(backend.columns)" class="h-full w-full pb-2 overflow-y-auto flex flex-col animate-in fade-in"
  *ngIf="!updating && item">
  <div class="flex flex-row w-full items-center justify-between mb-2 pl-4 pr-2">
    <div class="flex w-full items-baseline gap-3 truncate">
      <h3 class="whitespace-nowrap text-gray-800 truncate font-bold text-2xl" *ngIf="item?.id"
        [innerHtml]="item?.prettyName">
      </h3>
      <h3 class="font-bold text-2xl truncate" *ngIf="item && !item.id">
        Neues {{ backend.prettyName }}-Element erstellen:
      </h3>
      <span class="text-xs" *ngIf="!isValidForm()">Bitte alle Felder mit * ausfüllen!</span>
    </div>

    <div class="flex items-center gap-2.5">
      <button *ngIf="item?.id && !isDuplicateMode" type="button"
        class="rounded-full flex items-center gap-1 px-3 py-1.5 text-orange bg-white border border-orange hover:text-orangeLight hover:border-orangeLight shadow-md text-sm font-bold"
        [routerLink]="[
    '/backend',
    { outlets: { backendOutlet: [this.backend.dbName], backendItemOutlet: [this.backend.dbName, 'from', item.id] } },
  ]">
        <mat-icon>library_add</mat-icon>
        <span>duplizieren</span>
      </button>
      <button *ngIf="item?.id && !isDuplicateMode" type="button"
        class="rounded-full flex items-center gap-1 px-3 py-1.5 text-orange bg-white border border-orange hover:text-orangeLight hover:border-orangeLight shadow-md text-sm font-bold"
        (click)="onDeleteClick()">
        <mat-icon>delete</mat-icon>
        <span>löschen</span>
      </button>
      <button *ngIf="!isDialog && service" [disabled]="!isValidForm()" type="submit"
        class="rounded-full flex items-center gap-1 px-3 py-1.5 shadow-md text-white text-sm font-bold"
        [ngClass]="isValidForm() ? 'bg-orange hover:bg-orangeHover' : 'bg-gray-400'">
        <mat-icon>save</mat-icon>
        <span class="whitespace-nowrap">{{ item?.id && !isDuplicateMode ? "Änderungen speichern" : "Neu erstellen"
          }}</span>
      </button>
    </div>
  </div>

  <div class="flex h-full flex-col overflow-y-auto ml-4 mr-2">
    <div *ngFor="let column of backend.columns">
      <ts-generic-form-element *ngIf="column?.isVisibleInTable" [column]="column" [service]="service" [item]="item">
      </ts-generic-form-element>
    </div>
    <button *ngIf="isDialog && service" [disabled]="!isValidForm()" type="submit"
      class="rounded-full mt-2 self-start flex items-center gap-1 px-3 py-1.5 shadow-md text-white text-sm font-bold"
      [ngClass]="isValidForm() ? 'bg-orange hover:bg-orangeHover' : 'bg-gray-400'">
      <mat-icon>save</mat-icon>
      <span class="whitespace-nowrap">{{ item?.id ? "Änderungen speichern" : "Neu erstellen" }}</span>
    </button>
  </div>
</form>
<div *ngIf="!item" class="flex justify-center items-center gap-2 h-full">
  <mat-icon>error</mat-icon>
  <small>Kein Objekt ausgewählt.</small>
</div>
