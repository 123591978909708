<div class="max-w-6xl mx-auto">
  <mat-card [@routerTransition]="" style="margin: auto">
    <ts-picture
      *ngIf="page.img"
      style="padding-bottom: 44.65%"
      mat-card-image
      [alt]="page.title"
      [img]="page?.img"
    >
    </ts-picture>
    <div class="ts-padding">
      <a [routerLink]="['/']">Startseite</a>
      <mat-icon style="position: relative; top: 6px"
        >keyboard_arrow_right</mat-icon
      >
      <a [routerLink]="['/static', page.titleUrl]">{{ page.title }}</a>
      <h2>{{ page.title }}</h2>
      <p [innerHtml]="page.text"></p>
    </div>
  </mat-card>
</div>
