<ul role="list" class="mt-4 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
  <li *ngFor="let movie of movies" class="relative">
    <div [routerLink]="['/film', movie.id]"
      class="group block w-full aspect-w-16 aspect-h-9 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-orange overflow-hidden">
      <img [src]="
          'http://i3.ytimg.com/vi/' + movie.youtube_video_id + '/hqdefault.jpg'
        " [alt]="movie.title" class="object-cover pointer-events-none group-hover:opacity-75" />
      <button type="button" class="absolute inset-0 focus:outline-none">
        <span class="sr-only">Details für {{ movie.title }} anschauen</span>
      </button>
    </div>
    <p class="mt-2 block text-sm font-medium text-gray-800 truncate pointer-events-none">
      {{ movie.title }}
    </p>
    <p class="block text-sm font-medium text-gray-500 pointer-events-none">
      {{ movie.text }}
    </p>
  </li>
</ul>
