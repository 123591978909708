import { Component, OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";
import { NgFor, CurrencyPipe } from "@angular/common";

@Component({
    selector: "ts-prices",
    templateUrl: "./prices.component.html",
    styleUrls: ["./prices.component.css"],
    standalone: true,
    imports: [
        NgFor,
        RouterLink,
        CurrencyPipe,
    ],
})
export class PricesComponent implements OnInit {
  prices: {
    name: string;
    description: string;
    price: number;
    features: string[];
  }[] = [];

  constructor() {
    this.prices = [
      {
        name: "Starter",
        description:
          "Eos doloremque, fugiat provident quibusdam rem dolore soluta maxime minus",
        price: 999,
        features: ["alle Kursvideos auf Abruf"],
      },
      {
        name: "Standard",
        description:
          "Expedita nobis molestias quae mollitia delectus voluptatum officia unde autem.",
        price: 1999,
        features: [
          "alle Kursvideos auf Abruf",
          "Teilname an Paar-Online-Kursen",
        ],
      },
      {
        name: "Solo",
        description:
          "Expedita nobis molestias quae mollitia delectus voluptatum officia unde autem.",
        price: 1999,
        features: [
          "alle Kursvideos auf Abruf",
          "Teilname an Solo-Online-Kursen",
        ],
      },
      {
        name: "Komplett",
        description:
          "Expedita nobis molestias quae mollitia delectus voluptatum officia unde autem.",
        price: 2999,
        features: [
          "alle Kursvideos auf Abruf",
          "Teilname an Solo-Online-Kursen",
          "Teilname an Paar-Online-Kursen",
        ],
      },
    ];
  }

  ngOnInit(): void {}
}
