<div class="max-w-6xl mx-auto">
  <div class="mt-4 mx-2" *ngIf="school.id === 1" [@routerTransition]="">
    <h1 class="font-bold text-3xl">Werbepartner der Tanzschule</h1>
    <div class="prose text-sm">
      <p>
        Wir sind dankbar für tolle Werbepartner in Wedel und Hamburg!<br />
        Hier sehen Sie eine Übersicht inklusive der Vorteile, die Sie durch die
        Vorlage Ihrer Riemer-Card erhalten.
      </p>

      <p>
        Riemer-Vorteile sind mit anderen Gutscheinen und Rabattaktionen nicht
        kombinierbar!
      </p>
    </div>
    <ts-partner-list></ts-partner-list>
  </div>
</div>
