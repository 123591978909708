<div class="mt-2">
  <label [for]="column.dbName" class="block text-sm font-medium text-gray-700">{{column?.prettyName}}<span
      *ngIf="column.required">*</span></label>
  <div class="mt-1">
    <select (select)="onChange()" [id]="column.dbName" [name]="column.dbName" [required]="column.required" [(ngModel)]="column.data"
      class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md">
      <option *ngFor="let enumValue of column?.enumValues" [value]="enumValue.value">{{enumValue.label}}</option>
    </select>
  </div>
</div>
