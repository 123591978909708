import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CourseStartDate } from "../../models/courseStartDate";
import { Person } from "../../models/person";
import { MatDivider } from "@angular/material/divider";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { NgIf } from "@angular/common";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { MatFormField, MatError, MatSuffix } from "@angular/material/form-field";

@Component({
    selector: "ts-course-data-form",
    templateUrl: "./course-data-form.component.html",
    styleUrls: ["./course-data-form.component.css"],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatSelect,
        MatOption,
        NgIf,
        MatError,
        MatInput,
        MatIcon,
        MatSuffix,
        MatDivider,
    ],
})
export class CourseDataFormComponent implements OnInit {
  @Input() person: Person;
  @Input() required: boolean;
  @Input() startDate: CourseStartDate;
  @Output() getFormValues: EventEmitter<Person> = new EventEmitter();
  @Output() getForm: EventEmitter<UntypedFormGroup> = new EventEmitter();
  @Output() isValid: EventEmitter<boolean> = new EventEmitter();
  public formGroup: UntypedFormGroup;

  private personalTemplateRequired = {
    gender: new UntypedFormControl(null, Validators.required),
    first_name: new UntypedFormControl(null, Validators.required),
    last_name: new UntypedFormControl(null, Validators.required),
    birthday: new UntypedFormControl(null, Validators.required),
    street: new UntypedFormControl(null, Validators.required),
    streetNo: new UntypedFormControl(null, [
      Validators.required,
      Validators.maxLength(10),
    ]),
    plz: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(5),
      Validators.maxLength(5),
    ]),
    city: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl(null, [Validators.required, Validators.email]),
    telephone: new UntypedFormControl(null, Validators.required),
  };

  private personalTemplate = {
    gender: new UntypedFormControl(null),
    first_name: new UntypedFormControl(null),
    last_name: new UntypedFormControl(null),
    birthday: new UntypedFormControl(null),
    street: new UntypedFormControl(null),
    streetNo: new UntypedFormControl(null, [Validators.maxLength(10)]),
    plz: new UntypedFormControl(null),
    city: new UntypedFormControl(null),
    email: new UntypedFormControl(null),
    telephone: new UntypedFormControl(null),
  };

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.formGroup = this.formBuilder.group(
      Object.assign(
        {},
        this.required ? this.personalTemplateRequired : this.personalTemplate
      )
    );

    this.formGroup.valueChanges.subscribe(() => {
      this.getFormValues.emit(this.formGroup.value);
      this.getForm.emit(this.formGroup);
      this.isValid.emit(this.formGroup.valid);
    });
  }
}
