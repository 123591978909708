<ng-container>
  <ts-string-field *ngIf="column.dataType === 'string'" [(column)]="column"></ts-string-field>
  <ts-html-field *ngIf="column.dataType === 'html'" [(column)]="column"></ts-html-field>
  <ts-datetime-field *ngIf="column.dataType === 'datetime'" [(column)]="column"></ts-datetime-field>
  <ts-time-field *ngIf="column.dataType === 'time'" [(column)]="column"></ts-time-field>
  <ts-img-field *ngIf="column.dataType === 'img'" [(column)]="column"></ts-img-field>
  <ts-video-field *ngIf="column.dataType === 'video'" [(column)]="column"></ts-video-field>
  <ts-file-field *ngIf="column.dataType === 'file'" [(column)]="column"></ts-file-field>
  <ts-join-field *ngIf="column.dataType === 'join'" [(column)]="column"></ts-join-field>
  <ts-join-many-field *ngIf="column.dataType === 'joinMany'" [(column)]="column" [service]="service" [item]="item">
  </ts-join-many-field>
  <ts-boolean-field *ngIf="column.dataType === 'boolean'" [(column)]="column"></ts-boolean-field>
  <ts-enum-field *ngIf="column.dataType === 'enum'" [(column)]="column"></ts-enum-field>
</ng-container>
