import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Backend } from '../../models/backend';
import { BackendEditService } from '../../services/backend-edit.service';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'ts-generic-menu',
    templateUrl: './generic-menu.component.html',
    styleUrls: ['./generic-menu.component.css'],
    standalone: true,
    imports: [RouterLink, MatIcon, FormsModule]
})
export class GenericMenuComponent {

  genericService: any;
  filterStr: string = '';
  @Input() backend: Backend = new Backend();

  constructor(
    private backendEditSrv: BackendEditService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  onFilter(str: string) {
    this.backendEditSrv.filter.next(str.toLowerCase());
  }

  onNewClick() {
    this.router.navigate([
      '/backend',
      { outlets: { backendList: [this.route.snapshot.params.serviceName, 'new'] } },
    ]);
  }

}
