import { Type } from 'class-transformer';
import { IsArray, IsEmail, IsNumber, IsOptional, IsString, MaxLength, MinLength } from 'class-validator';
import { CreateEventRegistrationAnswerDto } from './create-event-registration-answer.dto';

export class CreateEventRegistrationDto {
  
  @IsString()
  firstName: string;

  @IsString()
  lastName: string;

  @IsEmail()
  email: string;

  @IsString()
  phone: string;

  @IsString()
  street: string;

  @IsString()
  @MaxLength(5)
  @MinLength(5)
  zip: string;

  @IsString()
  city: string;

  @IsString()
  @IsOptional()
  childFirstName: string;

  @IsString()
  @IsOptional()
  childLastName: string;

  @IsArray()
  @Type(() => CreateEventRegistrationAnswerDto)
  answers: CreateEventRegistrationAnswerDto[];

  @IsNumber()
  fkEvent: number;
}
