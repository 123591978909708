
import { Injectable } from '@angular/core';
import { PaymentMethod } from '../models/paymentMethod';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService extends CrudService<PaymentMethod> {

  static prefix = 'paymentMethods';
  dbTable = 'payment_method';

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService
  ) {
    super(httpSrv, PaymentMethodService.prefix, PaymentMethod, convSrv);
  }
}
