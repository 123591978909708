import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Course } from '../../models/course';
import { RouterLink } from '@angular/router';
import { MatTooltip } from '@angular/material/tooltip';
import { NgFor, NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'ts-course-table',
    templateUrl: './course-table.component.html',
    styleUrls: ['./course-table.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        MatTooltip,
        NgClass,
        RouterLink,
    ],
})
export class CourseTableComponent implements OnInit {

  public displayedColumns = ['name', 'startdates', 'weekday', 'time', 'action'];

  expandedElement: Course | null;
  @Input() noStartDatesText = '';
  @Input() courses: Course[] = [];

  constructor() { }

  ngOnInit() {

  }

}
