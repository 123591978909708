import { Component, OnInit } from '@angular/core';
import { School } from '../../models/school';
import { routerTransition } from '../../router.animations';
import { SchoolService } from '../../services/school.service';

@Component({
    selector: 'ts-routing-conditions',
    templateUrl: './routing-conditions.component.html',
    styleUrls: ['./routing-conditions.component.css'],
    animations: [routerTransition()],
    standalone: true,
    host: { ngSkipHydration: 'true' },
})
export class RoutingConditionsComponent implements OnInit {

  public school: School = new School();

  constructor(private schoolSrv: SchoolService) { }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
    });
  }

}
