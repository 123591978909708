import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { Column } from '../../../models/column';
import { AssetsService } from '../../../services/assets.service';
import { PictureComponent } from '../../../shared/picture/picture.component';


@Component({
    selector: 'ts-img-field',
    templateUrl: './img-field.component.html',
    styleUrls: ['./img-field.component.css'],
    standalone: true,
    imports: [NgIf, PictureComponent, FormsModule, MatIcon]
})
export class ImgFieldComponent {

  uploadProgress: number = 0;
  private _column: Column;
  public img = {
    width: 320,
    height: 240,
  };
  get column() {
    return this._column;
  }
  @Input() set column(val: Column) {
    this._column = val;
  }
  @Output() columnChange = new EventEmitter();

  constructor(private assetsSrv: AssetsService) { }

  onChange($event) {
    const formData = new FormData();
    formData.append('file', $event.target.files[0]);
    this.assetsSrv.upload(formData).subscribe(event => {
      // not yet finished
      if (event.type === 1) {
        this.uploadProgress = event.loaded / event.total;

        // finished
      } else if (!!event.body) {
        this.column.data = event.body.filename;
        this.img = event.body;
        this.uploadProgress = 0;
      }
    });
    this.columnChange.emit(this.column);
  }

}
