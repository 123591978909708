<div class="flex flex-col mt-2">
  <h3 class="text-sm font-medium text-gray-700">{{ column && column.prettyName }}</h3>
  <div *ngIf="!loading" class="flex gap-2.5 flex-row mt-1">
    <button type="button"
      class="rounded-full gap-1.5 flex items-center px-3 py-1.5 border border-orangeLight hover:border-orange hover:text-orange shadow-md text-orangeLight text-sm font-bold"
      (click)="openDialogOneItem(newEditItem())">
      <mat-icon>add</mat-icon>neu hinzufügen
    </button>
    <button type="button"
      class="rounded-full gap-1.5 flex items-center px-3 py-1.5 border border-orangeLight hover:border-orange hover:text-orange shadow-md text-orangeLight text-sm font-bold"
      (click)="openDialogList(newEditItem())">
      <mat-icon>playlist_add</mat-icon>bestehend hinzufügen
    </button>
  </div>
</div>
<table mat-table *ngIf="refItems && refItems.length > 0" [dataSource]="refItems">
  <ng-container *ngFor="let column of this.refBackend.columns" [matColumnDef]="column.dbName">
    <th mat-header-cell *matHeaderCellDef>{{ column.prettyName }}</th>
    <td mat-cell class="ts-padding" *matCellDef="let element">
      {{ element[column.dbName] ? element[column.dbName].toString() : '-' | slice: 0:50 }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef>Aktionen</th>
    <td mat-cell class="ts-padding" *matCellDef="let element">
      <button type="button" (click)="openDialogOneItem(getEditItem(element.id))">
        <mat-icon>edit</mat-icon>
      </button>
      <button type="button" (click)="onDelete(element.id)">
        <mat-icon>delete</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
  <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()"></tr>
</table>
<div></div>

<span class="text-gray-600" *ngIf="loading">lädt...</span>

<div class="mt-1 flex items-center gap-2 text-sm h-12" *ngIf="!loading && (!refItems || refItems.length === 0)">
  Keine {{ column && column.prettyName }} vorhanden
</div>
