import { Component, OnInit } from '@angular/core';

import { School } from '../../models/school';
import { SchoolService } from '../../services/school.service';

@Component({
    selector: 'ts-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.css'],
    standalone: true
})
export class EmptyStateComponent implements OnInit {

  school: School = new School();

  constructor(private schoolSrv: SchoolService) { }

  ngOnInit(): void {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
    });
  }

}
