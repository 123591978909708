import { isPlatformServer } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
  Inject,
  Injectable,
  Injector,
  isDevMode,
  PLATFORM_ID,
} from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TsHttpService {
  static root: String;
  static assetsRoot: String;
  public host: String;
  public imgRoot = "https://img.tanzspass.com:4434/";

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(
    public httpSrv: HttpClient,
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: Object,
    /* @Inject('HOSTNAME') private serverHostname: string, */
  ) {
    if (isPlatformServer(this.platformId)) {
      const serverHostname = this.injector.get('HOSTNAME');
      this.host = serverHostname;
    } else {
      this.host = window.location.hostname;
    }

    if (this.host !== "dev.tanzspass.com") {
      if (!isDevMode()) {
        TsHttpService.assetsRoot = "https://api.tanzspass.com/assets/";
        TsHttpService.root = "https://api.tanzspass.com/";
      } else {
        TsHttpService.root = "http://localhost:3001/";
        TsHttpService.assetsRoot = "http://localhost:3001/assets/";
      }
    } else {
      TsHttpService.root = "https://dev.api.tanzspass.com/";
      TsHttpService.assetsRoot = "https://dev.api.tanzspass.com/assets/";
    }
  }

  get<T>(path: string, options?: any): Observable<T> {
    options = options ? options : {};
    options = Object.assign(options, this.httpOptions);
    return this.httpSrv.get<T>(`${TsHttpService.root}${path}`);
  }

  public getImg<T>(path: string) {
    return this.httpSrv.get<T>(this.imgRoot + path);
  }

  put<T>(path: string, body: any, options?: any) {
    options = options ? options : {};
    options = Object.assign(options, this.httpOptions);
    return this.httpSrv.put<T>(`${TsHttpService.root}${path}`, body, options);
  }

  post<T>(path: string, body: any, options?: any) {
    options = options ? options : {};
    options = Object.assign(options, this.httpOptions);
    return this.httpSrv.post<T>(`${TsHttpService.root}${path}`, body, options);
  }

  delete<T>(path: string, options?: any) {
    options = options ? options : {};
    options = Object.assign(options, this.httpOptions);
    return this.httpSrv.delete<T>(`${TsHttpService.root}${path}`);
  }
}
