import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { ActivatedRoute, Params, RouterLink } from '@angular/router';
import { CourseCheckinStepperComponent } from '../../course/course-checkin-stepper/course-checkin-stepper.component';
import { CourseDataComponent } from '../../course/course-data/course-data.component';
import { Course } from '../../models/course';
import { routerTransition } from '../../router.animations';
import { CourseService } from '../../services/course.service';

@Component({
    selector: 'ts-routing-course-checkout',
    templateUrl: './routing-course-checkout.component.html',
    styleUrls: ['./routing-course-checkout.component.css'],
    animations: [routerTransition()],
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        CourseDataComponent,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        CourseCheckinStepperComponent,
    ],
    host: { ngSkipHydration: 'true' },
})
export class RoutingCourseCheckoutComponent implements OnInit {

  public params: Params;
  public course: Course = new Course();
  public startDateId: number;
  public panelOpenState: false;

  constructor(private router: ActivatedRoute, private courseSrv: CourseService) { }

  ngOnInit() {
    this.router.params.subscribe(params => {
      this.params = params;
      this.courseSrv.findById(this.params['id']).subscribe(course => {
        this.course = course;
        this.startDateId = this.params['startDateId'];
      });
    });
  }
}
