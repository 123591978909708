import { Component, Input, OnChanges, OnInit, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AgeClass } from "../../models/ageClass";
import { Event } from "../../models/event";
import { AgeClassService } from "../../services/age-class.service";
import { EventService } from "../../services/event.service";
import { SchoolService } from "../../services/school.service";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "ts-event-list",
    templateUrl: "./event-list.component.html",
    styleUrls: ["./event-list.component.css"],
    standalone: true,
    imports: [NgIf, NgFor],
})
export class EventListComponent implements OnInit, OnChanges {
  @Input() ageClassId: number;
  public events: Event[] = [];
  public ageClass: AgeClass = new AgeClass();

  public displayedColumns = ["name", "description", "date", "time"];
  public dataSource = new MatTableDataSource(this.events);

  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private eventSrv: EventService,
    private schoolSrv: SchoolService,
    private ageClassSrv: AgeClassService
  ) {}

  ngOnInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges() {
    this.schoolSrv.current().subscribe((school) => {
      this.eventSrv
        .byAgeClass(this.ageClassId, school.id)
        .subscribe((events) => {
          this.events = events;
        });
    });
    this.ageClassSrv.findById(this.ageClassId).subscribe((ageClass) => {
      this.ageClass = ageClass;
    });
  }
}
