<div class="h-exceptMenu relative bg-white top-menu">
  <img class="object-cover h-full w-full" src="/assets/img/tmp/login.jpg" alt="" />
  <div class="
      absolute
      inset-0
      md:inset-auto md:inset-y-0 md:right-24
      flex
      items-center
      justify-center
    ">
    <div class="bg-gray-50 rounded-lg py-8 px-12 shadow-none">
      <div class="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <h2 class="mt-6 text-3xl font-extrabold text-gray-800">
            Erstellen Sie einfach einen Account
          </h2>
          <p class="mt-2 text-sm text-gray-600 max-w">
            und machen Sie 30 Tage lang kostenlos mit.
          </p>
        </div>

        <div class="mt-8">
          <div class="mt-6">
            <form class="space-y-6">
              <div>
                <label for="firstname" class="block text-sm font-medium text-gray-700">
                  Vorname
                </label>
                <div class="mt-1">
                  <input id="firstname" name="firstname" type="text" required class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none focus:ring-orange focus:border-orange
                      sm:text-sm
                    " />
                </div>
              </div>
              <div>
                <label for="lastname" class="block text-sm font-medium text-gray-700">
                  Nachname
                </label>
                <div class="mt-1">
                  <input id="lastname" name="lastname" type="text" required class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none focus:ring-orange focus:border-orange
                      sm:text-sm
                    " />
                </div>
              </div>
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">
                  E-Mail-Adresse
                </label>
                <div class="mt-1">
                  <input id="email" name="email" type="email" autocomplete="email" required class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none focus:ring-orange focus:border-orange
                      sm:text-sm
                    " />
                </div>
              </div>

              <div>
                <button type="submit" class="
                    inline-block
                    bg-orange
                    py-2
                    px-4
                    border border-transparent
                    rounded-md
                    text-base
                    font-medium
                    text-white
                    hover:bg-orangeLight
                    w-full
                  ">
                  Registrieren
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
