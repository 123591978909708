import { Injectable } from "@angular/core";
import { Cancellation } from "../models/cancellation";
import { TsHttpService } from "./ts-http.service";

@Injectable({
  providedIn: "root",
})
export class CancellationService {
  static prefix = "cancellation";

  constructor(private httpSrv: TsHttpService) {}

  send(cancellation: Cancellation) {
    return this.httpSrv.post(`${CancellationService.prefix}`, cancellation);
  }
}
