<div class="h-exceptMenu relative bg-white top-menu">
  <img class="object-cover h-full w-full" src="/assets/img/tmp/login.jpg" alt="" />
  <div class="
      absolute
      inset-0
      md:inset-auto md:inset-y-0 md:right-24
      flex
      items-center
      justify-center
    ">
    <div class="bg-gray-50 rounded-lg py-8 px-12 shadow-md max-w-sm">
      <div class="space-y-6">
        <div>
          <h2 class="mt-6 text-3xl font-extrabold text-gray-800">
            Melden Sie sich hier mit Ihrem Account an.
          </h2>
          <p class="mt-2 text-sm text-gray-600 max-w">
            Oder
            <a routerLink="/online/registrieren" class="font-medium text-orange hover:text-^">
              starten Sie hier Ihre 30-Tage-Testphase
            </a>
          </p>
        </div>
        <div class="mt-8">
          <div class="mt-6">
            <form action="#" method="POST" class="space-y-6">
              <div>
                <label for="email" class="block text-sm font-medium text-gray-700">
                  E-Mail-Adresse
                </label>
                <div class="mt-1">
                  <input id="email" name="email" type="email" [(ngModel)]="formMail" autocomplete="email" required
                    class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none focus:ring-orange focus:border-orange
                      sm:text-sm
                    " />
                </div>
              </div>

              <div class="space-y-1">
                <label for="password" class="block text-sm font-medium text-gray-700">
                  Passwort
                </label>
                <div class="mt-1">
                  <input id="password" [(ngModel)]="formPw" name="password" type="password"
                    autocomplete="current-password" required class="
                      appearance-none
                      block
                      w-full
                      px-3
                      py-2
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      placeholder-gray-400
                      focus:outline-none focus:ring-orange focus:border-orange
                      sm:text-sm
                    " />
                </div>
              </div>

              <div class="flex items-center justify-between">
                <div class="text-sm">
                  <a href="#" class="font-medium text-orange hover:text-^">
                    Kennwort vergessen?
                  </a>
                </div>
              </div>

              <div>
                <button (click)="onLogin()" class="
                    inline-block
                    bg-orange
                    py-2
                    px-4
                    border border-transparent
                    rounded-md
                    text-base
                    font-medium
                    text-white
                    hover:bg-orangeLight
                    w-full
                  ">
                  Anmelden
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
