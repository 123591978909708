import { NgFor, NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { CreateEventRegistrationDto } from "../../../../../shared/dtos";
import { Event } from "../../models/event";
import { EventRegistrationService } from "../../services/event-registration.service";
import { EventService } from "../../services/event.service";
import { TsHttpService } from "../../services/ts-http.service";
import { PictureComponent } from "../../shared/picture/picture.component";

@Component({
    selector: "ts-routing-event-form",
    templateUrl: "./routing-event-form.component.html",
    styleUrls: ["./routing-event-form.component.css"],
    standalone: true,
    imports: [
        NgIf,
        PictureComponent,
        FormsModule,
        NgFor,
    ],
    host: { ngSkipHydration: "true" },
})
export class RoutingEventFormComponent implements OnInit {
  event: Event;
  imgUrl: string;
  dto: CreateEventRegistrationDto = new CreateEventRegistrationDto();

  constructor(
    private eventService: EventService,
    private route: ActivatedRoute,
    private httpSrv: TsHttpService,
    private eventRegistrationService: EventRegistrationService,
  ) {}

  submit() {
    this.eventRegistrationService.createWithDto(this.dto);
  }

  ngOnInit(): void {
    this.route.params.subscribe(async (params) => {
      this.event = await firstValueFrom(
        this.eventService.findById(params.eventId),
      );
      this.dto.fkEvent = this.event.id;
      this.dto.answers = this.event.questions.map((question) => ({
        fkQuestion: question.id,
        answer: "",
      }));
      this.imgUrl = TsHttpService.assetsRoot + this.event.img;
    });
  }
}
