import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Backend } from '../../models/backend';
import { BackendService } from '../../services/backend.service';
import { ServiceLoaderService } from '../../services/service-loader.service';
import { BackendMenuComponent } from '../backend-menu/backend-menu.component';

@Component({
    selector: 'ts-attribute-routing',
    templateUrl: './attribute-routing.component.html',
    styleUrls: ['./attribute-routing.component.css'],
    standalone: true,
    imports: [BackendMenuComponent]
})
export class AttributeRoutingComponent implements OnInit {

  genericService: any;
  backend: Backend = new Backend();

  constructor(
    private route: ActivatedRoute,
    private serviceLoaderSrv: ServiceLoaderService,
    private backendSrv: BackendService,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      if (params.serviceName !== this.genericService?.dbTable) {
        this.genericService = this.serviceLoaderSrv.load(params.serviceName);
        this.backendSrv.findByName(this.genericService.dbTable).subscribe((backend) => {
          this.backend = backend;
        });
      }
    });
  }
}
