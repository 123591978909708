<div class="h-exceptBothMenus overflow-y-auto mx-auto">
  <div class="h-full flex overflow-y-auto mx-2 pt-4">

    <div class="flex-grow overflow-y-auto shrink-0">
      <router-outlet name="backendOutlet"></router-outlet>
    </div>
    <div class="overflow-y-auto w-full h-full">
      <router-outlet name="backendItemOutlet"></router-outlet>
    </div>

    <!-- <ts-attribute-config class="w-full h-full overflow-y-auto ml-2 mt-2" *ngIf="viewMode==='attributes'">
    </ts-attribute-config> -->
  </div>
</div>
