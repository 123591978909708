import { ApplicationConfig, LOCALE_ID, provideZoneChangeDetection } from "@angular/core";
import { provideRouter } from "@angular/router";

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { provideClientHydration } from "@angular/platform-browser";
import { routes } from "./app.routes";
import { AuthInterceptorService } from "./services/auth-interceptor.service";

export const appConfig: ApplicationConfig = {
  providers: [
    /* TsHttpService,
    SchoolService,
    EventService, */
    { provide: LOCALE_ID, useValue: "de" },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
  ],
};
