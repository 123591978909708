import { Component, OnInit } from '@angular/core';
import { SeoService } from '../../services/seo.service';
import { InfosystemIndexComponent } from '../../infosystem/infosystem-index/infosystem-index.component';

@Component({
    selector: 'ts-routing-tv',
    templateUrl: './routing-tv.component.html',
    styleUrls: ['./routing-tv.component.css'],
    standalone: true,
    imports: [InfosystemIndexComponent]
})
export class RoutingTvComponent implements OnInit {

  constructor(private seoSrv: SeoService) { }

  ngOnInit(): void {
    this.seoSrv.updateTitle('Tanzspass TV-Screen');
  }

}
