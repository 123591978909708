import { Routes } from "@angular/router";
import { AttributeRoutingComponent } from "./backend/attribute-routing/attribute-routing.component";
import { BackendRoutingComponent } from "./backend/backend-routing/backend-routing.component";
import { EmptyStateComponent } from './backend/empty-state/empty-state.component';
import { GenericFormComponent } from "./backend/generic-form/generic-form.component";
import { DanceGridComponent } from "./dance/dance-grid/dance-grid.component";
import { FaqComponent } from "./online-tanzspass/faq/faq.component";
import { IndexComponent } from "./online-tanzspass/index/index.component";
import { LoginComponent } from "./online-tanzspass/login/login.component";
import { PortalIndexComponent } from "./online-tanzspass/portal/portal-index/portal-index.component";
import { PortalLiveComponent } from "./online-tanzspass/portal/portal-live/portal-live.component";
import { PortalVideoCatComponent } from "./online-tanzspass/portal/portal-video-cat/portal-video-cat.component";
import { PortalVideoComponent } from "./online-tanzspass/portal/portal-video/portal-video.component";
import { PricesComponent } from "./online-tanzspass/prices/prices.component";
import { RegisterComponent } from "./online-tanzspass/register/register.component";
import { RoutingAboutUsComponent } from "./routing/routing-about-us/routing-about-us.component";
import { RoutingAgbHigleAppComponent } from "./routing/routing-agb-higle-app/routing-agb-higle-app.component";
import { RoutingAgbComponent } from "./routing/routing-agb/routing-agb.component";
import { RoutingBackendComponent } from "./routing/routing-backend/routing-backend.component";
import { RoutingCancelationComponent } from "./routing/routing-cancelation/routing-cancelation.component";
import { RoutingCheckedOutComponent } from "./routing/routing-checked-out/routing-checked-out.component";
import { RoutingConditionsComponent } from "./routing/routing-conditions/routing-conditions.component";
import { RoutingContactComponent } from "./routing/routing-contact/routing-contact.component";
import { RoutingCourseCheckoutComponent } from "./routing/routing-course-checkout/routing-course-checkout.component";
import { RoutingCourseLevelComponent } from "./routing/routing-course-level/routing-course-level.component";
import { RoutingCourseListComponent } from "./routing/routing-course-list/routing-course-list.component";
import { RoutingCustomerGalleryComponent } from "./routing/routing-customer-gallery/routing-customer-gallery.component";
import { RoutingCustomerInfoComponent } from "./routing/routing-customer-info/routing-customer-info.component";
import { RoutingCustomerMediumComponent } from "./routing/routing-customer-medium/routing-customer-medium.component";
import { RoutingCustomerVideoComponent } from "./routing/routing-customer-video/routing-customer-video.component";
import { RoutingCustomerComponent } from "./routing/routing-customer/routing-customer.component";
import { RoutingDatenschutzComponent } from "./routing/routing-datenschutz/routing-datenschutz.component";
import { RoutingDrinksComponent } from "./routing/routing-drinks/routing-drinks.component";
import { RoutingEventFormComponent } from "./routing/routing-event-form/routing-event-form.component";
import { RoutingEventSinglePageComponent } from "./routing/routing-event-single-page/routing-event-single-page.component";
import { RoutingEventComponent } from "./routing/routing-event/routing-event.component";
import { RoutingFreetimeComponent } from "./routing/routing-freetime/routing-freetime.component";
import { RoutingImprintComponent } from "./routing/routing-imprint/routing-imprint.component";
import { RoutingIndexComponent } from "./routing/routing-index/routing-index.component";
import { RoutingLoginComponent } from "./routing/routing-login/routing-login.component";
import { RoutingMovieComponent } from "./routing/routing-movie/routing-movie.component";
import { RoutingMoviesComponent } from "./routing/routing-movies/routing-movies.component";
import { RoutingNewsComponent } from "./routing/routing-news/routing-news.component";
import { RoutingPageComponent } from "./routing/routing-page/routing-page.component";
import { RoutingPartnersComponent } from "./routing/routing-partners/routing-partners.component";
import { RoutingPrivateComponent } from "./routing/routing-private/routing-private.component";
import { RoutingRoomComponent } from "./routing/routing-room/routing-room.component";
import { RoutingTeamComponent } from "./routing/routing-team/routing-team.component";
import { RoutingTvComponent } from "./routing/routing-tv/routing-tv.component";
import { AuthGuard } from "./services/auth.guard";

export const routes: Routes = [
  {
    path: "events",
    children: [
      {
        path: "",
        component: RoutingEventSinglePageComponent,
        data: {
          title: "Veranstaltungen | {SCHOOL}",
          ogUrl: "/events",
          description:
            "Für das besondere Erlebnis veranstalten wir regelmäßig Events für die verschiedenen Altersklassen! Feiert mit uns in unseren Sälen.",
        },
      },
      { path: ":ageClass", component: RoutingEventComponent },
      { path: "anmelden/:eventId", component: RoutingEventFormComponent },
    ],
  },
  {
    path: "kurse/:ageClass",
    children: [
      {
        path: "",
        component: RoutingCourseListComponent,
      },
      { path: ":courseLevelName", component: RoutingCourseLevelComponent },
    ],
  },
  { path: "buchen/:id", component: RoutingCourseCheckoutComponent },
  {
    path: "buchen/:id/:startDateId",
    component: RoutingCourseCheckoutComponent,
  },
  { path: "gebucht/:id", component: RoutingCheckedOutComponent },
  {
    path: "kontakt",
    component: RoutingContactComponent,
    data: {
      title: "Kontakt | {SCHOOL}",
      ogUrl: "/kontakt",
    },
  },
  {
    path: "team",
    component: RoutingTeamComponent,
    data: {
      title: "Unser Team | {SCHOOL}",
      ogUrl: "/team",
      description: "Das Team der {SCHOOL}",
    },
  },
  {
    path: "saele",
    component: RoutingRoomComponent,
    data: {
      title: "Unsere Räumlichkeiten | {SCHOOL}",
      ogUrl: "/saele",
      description:
        "Wir haben mehrere Säle, ausgestattet mit moderner Lich- und Tontechnik, in denen wir das Tanzen unterrichten. Wir freuen und auf euch!",
    },
  },
  {
    path: "säle",
    component: RoutingRoomComponent,
    data: {
      title: "Unsere Räumlichkeiten | {SCHOOL}",
      ogUrl: "/saele",
      description:
        "Wir haben mehrere Säle, ausgestattet mit moderner Lich- und Tontechnik, in denen wir das Tanzen unterrichten. Wir freuen und auf euch!",
    },
  },
  {
    path: "taenze",
    component: DanceGridComponent,
  },
  {
    path: "getraenke",
    component: RoutingDrinksComponent,
    data: {
      title: "Getränke | {SCHOOL}",
      ogUrl: "/getraenke",
    },
  },
  { path: "news/:urlTitle", component: RoutingNewsComponent },
  { path: "static/:titleUrl", component: RoutingPageComponent },
  {
    path: "konditionen",
    component: RoutingConditionsComponent,
    data: {
      title: "Die Konditionen unserer Tanzkurse | {SCHOOL}",
      ogUrl: "/konditionen",
      description:
        "Wir möchten all unsere Tanzkurse zu fairen Konditionen anbieten und haben beispielsweise Preismodelle für verschiedene Laufzeiten.",
    },
  },
  {
    path: "kursfreie-zeiten",
    component: RoutingFreetimeComponent,
    data: {
      title: "Kursfreie Zeiten | {SCHOOL}",
      ogUrl: "/kursfreie-zeiten",
    },
  },
  {
    path: "privatunterricht",
    component: RoutingPrivateComponent,
    data: {
      title: "Privater Tanzunterricht | {SCHOOL}",
      ogUrl: "/privatunterricht",
    },
  },
  {
    path: "about",
    component: RoutingAboutUsComponent,
    data: {
      title: "Über uns | {SCHOOL}",
      ogUrl: "/about",
    },
  },
  {
    path: "kuendigung",
    component: RoutingCancelationComponent,
    data: {
      title: "Kündigung | {SCHOOL}",
      ogUrl: "/kuendigung",
    },
  },
  {
    path: "filme",
    component: RoutingMoviesComponent,
    data: {
      title: "Filme über uns | {SCHOOL}",
      ogUrl: "/filme",
      description:
        "Viel Spaß bei unserer kleinen Filmesammlung der Tanzschule.",
    },
  },
  { path: "film/:movieId", component: RoutingMovieComponent },
  {
    path: "partner",
    component: RoutingPartnersComponent,
    data: {
      title: "Unsere Partner | {SCHOOL}",
      ogUrl: "/partner",
      description:
        "Wir sind dankbar für tolle Werbepartner in Wedel und Hamburg! Hier sehen Sie eine Übersicht der Vorteile, die Sie durch die Vorlage Ihrer Riemer-Card erhalten.",
    },
  },
  { path: "tv", component: RoutingTvComponent },

  {
    path: "kundenbereich",
    data: {
      title: "Kundenbereich | {SCHOOL}",
      ogUrl: "/kundenbereich",
    },
    component: RoutingCustomerComponent,
    children: [
      {
        path: "galerie",
        component: RoutingCustomerGalleryComponent,
        data: {
          title: "Kundenbereich - Galerie | {SCHOOL}",
          ogUrl: "/kundenbereich/galerie",
        },
      },
      {
        path: "videos",
        component: RoutingCustomerVideoComponent,
        data: {
          title: "Kundenbereich - Mediathek | {SCHOOL}",
          ogUrl: "/kundenbereich/videos",
        },
      },
      {
        path: "masterclass/:year",
        component: RoutingCustomerVideoComponent,
        data: {
          title: "Kundenbereich - Masterclass | {SCHOOL}",
          ogUrl: "/kundenbereich/masterclass",
        },
      },
      {
        path: "kursprogramm",
        component: RoutingCustomerMediumComponent,
        data: {
          title: "Kundenbereich - Kursprogramm | {SCHOOL}",
          ogUrl: "/kundenbereich/kursprogramm",
        },
      },
      {
        path: "info",
        component: RoutingCustomerInfoComponent,
        data: {
          title: "Kundenbereich - Aktuelle Info | {SCHOOL}",
          ogUrl: "/kundenbereich/info",
        },
      },
    ],
  },
  { path: "impressum", component: RoutingImprintComponent },
  { path: "datenschutz", component: RoutingDatenschutzComponent },
  { path: "agb", component: RoutingAgbComponent },
  { path: "agb-app", component: RoutingAgbHigleAppComponent },
  {
    path: "taenze",
    children: [
      { path: "", component: RoutingIndexComponent },
    ],
  },
  {
    path: "online",
    children: [
      { path: "", component: IndexComponent },
      { path: "login", component: LoginComponent },
      { path: "registrieren", component: RegisterComponent },
      { path: "preise", component: PricesComponent },
      { path: "faq", component: FaqComponent },
      {
        path: "portal",
        component: PortalIndexComponent,
        children: [
          {
            path: "live",
            component: PortalLiveComponent,
            outlet: "portal",
          },
          {
            path: "video/:videoId",
            component: PortalVideoComponent,
            outlet: "portal",
          },
          {
            path: ":videoCatId",
            component: PortalVideoCatComponent,
            outlet: "portal",
          },
        ],
      },
    ],
  },

  { path: "login", component: RoutingLoginComponent },
  {
    path: 'backend/attributes',
    component: AttributeRoutingComponent,
  },
  {
    path: "backend",
    canActivate: [AuthGuard],
    component: RoutingBackendComponent,
    children: [
      {
        path: '',
        component: BackendRoutingComponent,
        outlet: 'backendOutlet',
      },
      {
        path: ':serviceName',
        component: BackendRoutingComponent,
        outlet: 'backendOutlet',
      },
      {
        path: ':serviceName/:itemId',
        component: BackendRoutingComponent,
        outlet: 'backendOutlet',
      },
      {
        path: ':serviceName/new',
        component: GenericFormComponent,
        outlet: 'backendItemOutlet',
      },
      {
        path: '',
        component: EmptyStateComponent,
        outlet: 'backendItemOutlet',
      },
      {
        path: ':serviceName/from/:itemId',
        component: GenericFormComponent,
        outlet: 'backendItemOutlet',
      },
      {
        path: ':serviceName/:itemId',
        component: GenericFormComponent,
        outlet: 'backendItemOutlet',
      },
    ],
  },
  { path: "screen/:id", component: RoutingIndexComponent },
  { path: "", component: RoutingIndexComponent },
  {path: '404', component: RoutingIndexComponent},
  {path: '**', redirectTo: '/404'}
];