import { Injectable } from '@angular/core';
import { BooleanFieldComponent } from '../backend/data-fields/boolean-field/boolean-field.component';
import { DatetimeFieldComponent } from '../backend/data-fields/datetime-field/datetime-field.component';
import { ImgFieldComponent } from '../backend/data-fields/img-field/img-field.component';
import { JoinFieldComponent } from '../backend/data-fields/join-field/join-field.component';
import { StringFieldComponent } from '../backend/data-fields/string-field/string-field.component';
import { TimeFieldComponent } from '../backend/data-fields/time-field/time-field.component';

@Injectable({
  providedIn: 'root'
})
export class DataFieldLoaderService {

  constructor() { }

  load(name: string) {
    switch (name) {
      case 'string': return StringFieldComponent;
      case 'datetime': return DatetimeFieldComponent;
      case 'time': return TimeFieldComponent;
      case 'img': return ImgFieldComponent;
      case 'join': return JoinFieldComponent;
      case 'boolean': return BooleanFieldComponent;
    }
  }
}
