<div class="row">
  <!-- gallery selection -->
  <mat-card class="galleryListCard imgCard ts-no-padding flex flex-1 w-1/2" *ngIf="currGallery == undefined">
    <mat-tab-group>
      <mat-tab [label]="year.name" *ngFor="let year of years">
        <!-- <mat-nav-list>
          <mat-list-item (click)="openGallery(year.name, gallery)" *ngFor="let gallery of year.content">
            {{ gallery.name }}
          </mat-list-item>
        </mat-nav-list> -->
      </mat-tab>
    </mat-tab-group>
    <ts-loader class="center" *ngIf="!years || years.length === 0"></ts-loader>
  </mat-card>

  <!-- image selection -->
  <mat-card class="galleryListCard ts-no-padding md:flex md:w-1/2" *ngIf="currGallery != undefined">
    <div class="ts-margin">
      <button mat-mini-fab (click)="resetView()">
        <mat-icon>&#xe14c;</mat-icon>
      </button>
      <span class="ts-right">
        <button mat-mini-fab (click)="decCurrGalleryPage()">
          <mat-icon>&#xe314;</mat-icon>
        </button>
        <strong class="ts-margin">Seite {{ currGalleryPageIndex + 1 }} von
          {{ currImgs.length }}</strong>
        <button mat-mini-fab (click)="incCurrGalleryPage()">
          <mat-icon>&#xe315;</mat-icon>
        </button>
      </span>
    </div>
    <mat-divider class="ts-margin"></mat-divider>
    <mat-grid-list cols="4" rowHeight="800:533">
      <mat-grid-tile *ngFor="let image of currImgs[currGalleryPageIndex]; let i = index"
        [ngClass]="{ activeImage: image === currImg }">
        <img [src]="getImagePath(image)" [alt]="currGallery.name + '(' + i + ')'" (click)="setCurrImg(image, i)" />
      </mat-grid-tile>
    </mat-grid-list>
  </mat-card>

  <!-- current image -->
  <mat-card class="ts-no-padding imgCard">
    <div *ngIf="currGallery !== undefined && currGallery.name.length > 0">
      <div class="flex">
        <button class="ts-margin" mat-mini-fab (click)="decImage()">
          <mat-icon>&#xe314;</mat-icon>
        </button>
        <h2>{{ currGallery.name.substring(3) }}</h2>
        <button class="ts-margin" mat-mini-fab (click)="incImage()">
          <mat-icon>&#xe315;</mat-icon>
        </button>
      </div>
      <img *ngIf="currImg !== undefined && currImg.length > 0" [src]="getImagePath(currImg)"
        [alt]="currGallery.name.substring(3)" />
      <!-- <mat-progress-bar mode="determinate" [value]="getImgPercentage()"></mat-progress-bar> -->
    </div>
    <img *ngIf="currImg === undefined || currImg.length === 0" [src]="dummyImgUrl" alt="" />
  </mat-card>
</div>
