import { Component, OnInit } from "@angular/core";
import { VideoCategory } from "../../../models/videoCategory";
import { VideoCategorieService } from "../../../services/video-categorie.service";
import { NgFor } from "@angular/common";
import { RouterLink, RouterOutlet } from "@angular/router";

@Component({
    selector: "ts-portal-index",
    templateUrl: "./portal-index.component.html",
    styleUrls: ["./portal-index.component.css"],
    standalone: true,
    imports: [
        RouterLink,
        NgFor,
        RouterOutlet,
    ],
})
export class PortalIndexComponent implements OnInit {
  videoCats: VideoCategory[] = [];

  constructor(private videoCatSrv: VideoCategorieService) {}

  ngOnInit(): void {
    this.videoCatSrv.findAll().subscribe((videoCats) => {
      this.videoCats = videoCats;
    });
  }
}
