import { Column } from './column';
import { DbObj } from './dbObj';

export class Backend implements DbObj {

  public id: number;
  public dbName: string;
  public prettyName: string;
  public icon: string;
  public isOwnTable: boolean;
  public isDependentOnSchool: boolean;
  public columns: Column[];
  public onlyDeleteRefs: boolean;

  get subtitle() {
    return '';
  }

  constructor() { }
}
