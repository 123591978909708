import { Injectable } from "@angular/core";
import { Drink } from "../models/drink";
import { ConvService } from "./conv.service";
import { CrudService } from "./crud.service";
import { TsHttpService } from "./ts-http.service";

@Injectable({
  providedIn: "root",
})
export class DrinkService extends CrudService<Drink> {
  static prefix = "drinks";
  dbTable = "drink";

  constructor(public httpSrv: TsHttpService, public convServ: ConvService) {
    super(httpSrv, DrinkService.prefix, Drink, convServ);
  }
}
