import { Injectable } from '@angular/core';
import { Question } from '../models/question';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class QuestionService extends CrudService<Question> {

  static prefix = 'questions';
  dbTable = 'question';

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService,
  ) {
    super(httpSrv, QuestionService.prefix, Question, convSrv);
  }
}
