<h1 class="font-bold text-3xl mt-6">
  {{ ageClass.nameTitle }}
</h1>

<div *ngIf="events !== undefined && events?.length > 0"
  class="mb-4 mt-3 max-w-6xl mx-auto flex flex-col divide-y divide-gray-200">
  <div class="mb-1.5 flex justify-between w-full text-xs text-gray-500">
    <div class="flex w-full">
      <div class="flex-1">Name</div>
      <div class="flex-1 hidden sm:block">Datum</div>
      <div class="flex-1 hidden sm:block">Uhrzeit</div>
      <div class="flex-1 block sm:hidden">Termin</div>
    </div>
  </div>


  <ul class="divide-y divide-gray-200" *ngIf="events !== undefined && events?.length > 0">
    <li class="py-1.5 flex space-x-2" *ngFor="let event of events">

      <!-- name-->
      <div class="flex flex-col flex-1 overflow-ellipsis">
        <span class="font-bold text-sm text-gray-800 overflow-ellipsis">{{
          event.name
          }}</span>
        <span class="text-gray-600 text-xs overflow-ellipsis">{{
          event.description
          }}</span>
      </div>

      <!-- date -->
      <div class="text-sm flex-1 text-gray-700 flex flex-col">
        <span class="font-bold sm:font-normal">{{ event.getDateText() }}</span>
        <span class="block sm:hidden">{{ event.getTimeTextWithEnd() }}</span>
      </div>

      <!-- time -->
      <div class="flex-1 text-sm text-gray-700 hidden sm:block">
        {{ event.getTimeTextWithEnd() }}
      </div>
    </li>
  </ul>
</div>


<div class="mt-2 text-gray-600 text-sm flex flex-col items-center" *ngIf="events?.length === 0">
  <svg class="w-24 h-24" viewBox="0 0 96 96" fill="#4B5563">
    <g transform="matrix(2,0,0,2,0,0)">
      <path
        d="M9,44C8.2,44 7.5,43.7 6.9,43.1C6.3,42.5 6,41.8 6,41L6,10C6,9.2 6.3,8.5 6.9,7.9C7.5,7.3 8.2,7 9,7L12.25,7L12.25,4L15.5,4L15.5,7L32.5,7L32.5,4L35.75,4L35.75,7L39,7C39.8,7 40.5,7.3 41.1,7.9C41.7,8.5 42,9.2 42,10L42,41C42,41.8 41.7,42.5 41.1,43.1C40.5,43.7 39.8,44 39,44L9,44ZM9,41L39,41L39,19.5L9,19.5L9,41ZM9,16.5L39,16.5L39,10L9,10L9,16.5ZM9,16.5L9,10L9,16.5ZM14,27L14,24L34,24L34,27L14,27ZM14,36L14,33L27.95,33L27.95,36L14,36Z"
        style="fill-rule:nonzero;" />
    </g>
  </svg>
  <div class="mt-2">Die nächsten Termine kommen bestimmt!</div>
</div>
