import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router, RouterLink } from "@angular/router";
import { FormsModule } from "@angular/forms";

@Component({
    selector: "ts-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"],
    standalone: true,
    imports: [RouterLink, FormsModule],
})
export class LoginComponent implements OnInit {
  formMail: string = "";
  formPw: string = "";
  mail = "";
  pw = "";

  constructor(private router: Router, private snackBar: MatSnackBar) {}

  onLogin() {
    if (this.formMail === this.mail && this.formPw === this.pw) {
      this.router.navigate(["/online/portal"]);
    } else {
      this.snackBar.open("Benutzer oder Passwort falsch!", null, {
        duration: 4000,
      });
    }
  }

  ngOnInit(): void {}
}
