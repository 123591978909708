import { NgIf } from '@angular/common';
import { Component, OnChanges, OnInit } from '@angular/core';
import { MatCard, MatCardImage } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { Page } from '../../models/page';
import { routerTransition } from '../../router.animations';
import { PageService } from '../../services/page.service';
import { PictureComponent } from '../../shared/picture/picture.component';

@Component({
    selector: 'ts-routing-page',
    templateUrl: './routing-page.component.html',
    styleUrls: ['./routing-page.component.css'],
    animations: [routerTransition()],
    standalone: true,
    imports: [
        MatCard,
        NgIf,
        PictureComponent,
        MatCardImage,
        RouterLink,
        MatIcon,
    ],
    host: { ngSkipHydration: 'true' },
})
export class RoutingPageComponent implements OnInit, OnChanges {

  public page: Page = new Page();

  constructor(
    private router: ActivatedRoute,
    private pageSrv: PageService,
  ) { }

  update() {
    this.router.params.subscribe(params => {
      this.pageSrv.findByTitleUrl(params.titleUrl).subscribe(page => {
        this.page = page;
      });
    });
  }

  ngOnInit() {
    this.update();
  }

  ngOnChanges() {
    this.update();
  }

}
