import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Cancellation } from "../../models/cancellation";
import { School } from "../../models/school";
import { CancellationService } from "../../services/cancellation.service";
import { SchoolService } from "../../services/school.service";
import { NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";

@Component({
    selector: "ts-routing-cancelation",
    templateUrl: "./routing-cancelation.component.html",
    styleUrls: ["./routing-cancelation.component.css"],
    standalone: true,
    imports: [FormsModule, NgIf],
})
export class RoutingCancelationComponent implements OnInit {
  school: School = new School();
  cancellation: Cancellation = new Cancellation();
  sent: boolean = false;
  cancelImmediately: "yes" | "no" = "yes";

  constructor(
    private schoolSrv: SchoolService,
    private cancellationSrv: CancellationService,
    private snackBar: MatSnackBar
  ) {}

  onSend() {
    this.cancellationSrv.send(this.cancellation).subscribe(
      () => {
        this.sent = true;
        this.snackBar.open(
          "Die Kündigung wurde verschickt. Du erhältst eine Bestätigung von unserem Team.",
          null,
          {
            duration: 4000,
          }
        );
        this.cancellation = new Cancellation();
      },
      (err) => {
        this.snackBar.open(
          "Die Kündigung konnte leider nicht verschickt werden. Sind Sie online?"
        );
      }
    );
  }

  ngOnInit(): void {
    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
      this.cancellation.schoolId = this.school.id;
    });
  }
}
