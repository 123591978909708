import { Component, OnInit } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { School } from '../../models/school';
import { CustomerAuthService } from '../../services/customer-auth.service';
import { SchoolService } from '../../services/school.service';

@Component({
    selector: 'ts-routing-customer-info',
    templateUrl: './routing-customer-info.component.html',
    styleUrls: ['./routing-customer-info.component.scss'],
    standalone: true,
    imports: [MatIcon],
    host: { ngSkipHydration: 'true' },
})
export class RoutingCustomerInfoComponent implements OnInit {

  public school: School = new School();

  constructor(
    private schoolSrv: SchoolService,
    private authSrv: CustomerAuthService,
    private snackbar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
    });
  }

}
