import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { School } from "../../models/school";
import { CustomerAuthService } from "../../services/customer-auth.service";
import { SchoolService } from "../../services/school.service";
import { TsHttpService } from "../../services/ts-http.service";

@Component({
    selector: "ts-routing-customer-medium",
    templateUrl: "./routing-customer-medium.component.html",
    styleUrls: ["./routing-customer-medium.component.css"],
    standalone: true,
})
export class RoutingCustomerMediumComponent implements OnInit {
  public school: School = new School();
  public programUrl: string;

  constructor(
    private schoolSrv: SchoolService,
    private authSrv: CustomerAuthService,
    private snackbar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
      this.programUrl = TsHttpService.assetsRoot + this.school.course_program_file;
    });
  }
}
