export class Cancellation {
  whoCancels: "me" | "partner" | "child" = "me";
  firstName: string = "";
  lastName: string = "";
  firstNamePartner?: string = "";
  lastNamePartner?: string = "";
  firstNameChild?: string = "";
  lastNameChild?: string = "";
  date: Date = new Date();
  email: string = "";
  message: string = "";
  type: "ordentlich" | "außerordentlich" = "ordentlich";
  schoolId: number = 0;
}
