import { Injectable } from '@angular/core';
import { CourseStartDate } from '../models/courseStartDate';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class CourseStartDatesService extends CrudService<CourseStartDate> {

  static prefix = 'courseStartDates';
  dbTable = 'course_start_date';

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService,
  ) {
    super(httpSrv, CourseStartDatesService.prefix, CourseStartDate, convSrv);
  }
}
