import { Component, OnInit } from '@angular/core';
import { routerTransition } from '../../router.animations';
import { MovieListComponent } from '../../movie/movie-list/movie-list.component';

@Component({
    selector: 'ts-routing-movies',
    templateUrl: './routing-movies.component.html',
    styleUrls: ['./routing-movies.component.css'],
    animations: [routerTransition()],
    standalone: true,
    imports: [MovieListComponent],
})
export class RoutingMoviesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
