import { NgFor, NgIf, SlicePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatCell, MatCellDef, MatColumnDef, MatHeaderCell, MatHeaderCellDef, MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable } from '@angular/material/table';
import { Backend } from '../../../models/backend';
import { BackendEditItem } from '../../../models/backendEditItem';
import { Column } from '../../../models/column';
import { DbObj } from '../../../models/dbObj';
import { BackendService } from '../../../services/backend.service';
import { SchoolService } from '../../../services/school.service';
import { ServiceLoaderService } from '../../../services/service-loader.service';
import { GenericFormDialogComponent } from '../../generic-form-dialog/generic-form-dialog.component';
import { JoinManySelectListComponent } from './join-many-select-list.component';
@Component({
    selector: 'ts-join-many-field',
    templateUrl: './join-many-field.component.html',
    styleUrls: ['./join-many-field.component.css'],
    standalone: true,
    imports: [NgIf, MatIcon, MatTable, NgFor, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, SlicePipe]
})
export class JoinManyFieldComponent implements OnInit {

  refService: any;
  refBackend: Backend = new Backend();
  refItems: any[];
  newItem: any = [
    new DbObj()
  ];
  refEditItems: BackendEditItem[] = [];
  loading: boolean = false;

  constructor(
    private loaderSrv: ServiceLoaderService,
    private backendSrv: BackendService,
    private snackBar: MatSnackBar,
    private schoolSrv: SchoolService,
    public dialog: MatDialog,
  ) { }

  @Input() column: Column = new Column();
  @Input() service: any;
  @Input() item: DbObj;
  @Output() columnChange = new EventEmitter();

  onChange() {
    this.columnChange.emit(this.column);
  }

  getDisplayedColumns() {
    return this.refBackend
      && this.refBackend.columns
      && this.refBackend.columns
        .map(column => column.dbName)
        .filter((col, index) => index < 7)
        .concat(['actions']);
  }

  newEditItem() {
    const item = new BackendEditItem();
    item.backend = this.refBackend;
    item.service = this.refService;
    item.item = new DbObj();

    // Set the link to "our" item
    const linkColumn = this.refBackend.columns
      .find(column => column.refTable === this.service.dbTable);
    if (linkColumn) {
      item.item[linkColumn.dbName] = this.item.id;
    }
    return item;
  }

  getEditItem(id: number): BackendEditItem {
    return this.refEditItems.find(item => item.item.id === id);
  }

  onDelete(elementId: number) {
    const item = this.getEditItem(elementId);

    // Items are either directly deleted or only the ref is deleted
    const obs = this.refBackend.onlyDeleteRefs
      ? this.service.removeRef(this.item.id, this.refService, elementId)
      : item.service.delete(elementId)


    obs.subscribe(data => {
      this.snackBar.open('Erfolgreich gelöscht!', null, { duration: 4000 });
      this.update();
    });
  }

  openDialogList(data: BackendEditItem): void {
    const dialogRef = this.dialog.open(JoinManySelectListComponent, {
      width: '600px',
      data: { ref: data, localService: this.service, item: this.item, refBackend: data.backend }
    });

    dialogRef.afterClosed().subscribe(result => { this.update() });
  }

  openDialogOneItem(data: BackendEditItem): void {
    const dialogRef = this.dialog.open(GenericFormDialogComponent, {
      width: '450px',
      data,
    });

    dialogRef.afterClosed().subscribe(result => { this.update(); });
  }

  update() {
    this.loading = true;
    if (this.column.refTable) {
      this.refService = this.loaderSrv.load(this.column.refTable);
    } else {
      this.snackBar.open('Es fehlt die Relationsdefinition für diesen Datentypen!', null, {
        duration: 3000
      });
    }
    if (this.service && this.item) {

      this.backendSrv.findByName(this.refService.dbTable).subscribe((backend) => {
        this.refBackend = backend;

        this.service.findSubItems(this.item.id, this.refService.dbTable).subscribe(items => {
          this.refItems = items;
          this.refItems.forEach(item => {
            this.refEditItems.push({
              item,
              backend: this.refBackend,
              service: this.refService,
            });
          });

          this.refEditItems.forEach((data, itemIndex) => {
            data.backend.columns.forEach((column, columnIndex) => {
              if (this.refItems[itemIndex] && this.refItems[itemIndex][column.dbName]) {
                column.data = this.refItems[itemIndex][column.dbName];
              }
            });
          });
          this.loading = false;
        });
      });
    }
  }



  ngOnInit() {
    this.update();

  }

}
