import { Injectable } from '@angular/core';
import { DrinkAdditive } from '../models/drinkAdditive';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class DrinkAdditiveService extends CrudService<DrinkAdditive> {
  static prefix = "drink-additive";
  dbTable = "drink_additive";

  constructor(public httpSrv: TsHttpService, public convServ: ConvService) {
    super(httpSrv, DrinkAdditiveService.prefix, DrinkAdditive, convServ);
  }
}
