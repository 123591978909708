import { Injectable } from "@angular/core";
import { DrinkCategory } from "../models/drinkCategory";
import { ConvService } from "./conv.service";
import { CrudService } from "./crud.service";
import { TsHttpService } from "./ts-http.service";

@Injectable({
  providedIn: "root",
})
export class DrinkCategoryService extends CrudService<DrinkCategory> {
  static prefix = "drink-category";
  dbTable = "drink_category";

  constructor(public httpSrv: TsHttpService, public convServ: ConvService) {
    super(httpSrv, DrinkCategoryService.prefix, DrinkCategory, convServ);
  }
}
