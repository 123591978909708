<div class="max-w-6xl mx-auto">
  <div [@routerTransition]="" class="mx-2 mt-4">
    <h2 class="font-bold text-3xl">
      AGB - Allgemeine Geschäftsbedingungen der {{ school.name }}
    </h2>
    <h3>unser Kleingedrucktes „VERTRAG KOMMT VON VERTRAGEN“</h3>
    <h2 class="text-lg font-semibold mt-2.5">Widerruf</h2>
    <p class="prose">
      Laden Sie sich <a class="underline" [href]="'/assets/pdf/widerruf/widerrufsbelehrung_' + school.short_name + '.pdf'" target="_blank">hier</a> das Formular zum Widerruf herunter.
    </p>
    <ol class="prose list-decimal">
      <li>
        Vertragsdauer:<br />
        Die Vereinbarung verlängert sich jeweils für die Dauer von einem Monat,
        sofern sie nicht unter Einhaltung einer Kündigungsfrist von mindestens 1
        Monat vor dem jeweiligen Beendigungszeitpunkt schriftlich gekündigt
        wird. Maßgeblich ist der Zugang der Kündigungserklärung.
      </li>
      <li>
        Ordentliche und außerordentliche Kündigungen bedürfen der Schriftform.
      </li>
      <li>
        Das vereinbarte Nutzungsentgelt wird jeweils zum 1. eines Monats im
        Voraus zur Zahlung fällig. Die Frist für die Vorabinformation zum
        SEPA-Lastschrifteinzug wird auf einen Tag gekürzt. Alternative
        Zahlungsarten werden mit € 3,-p.Pers. pro Monat Bearbeitungsgebühr
        berechnet.
      </li>
      <li>
        Geraten die Tänzer:innen, bzw. Vertragsparter:innen bei Minderjährigen
        schuldhaft mit mehr als zwei Monatsbeiträgen in Zahlungsverzug, so
        werden die gesamten Nutzungsentgelte bis zum nächst möglichen
        Vertragsende sofort zur Zahlung fällig.
      </li>
      <li>
        Die Rechte und Pflichten der Tänzer:innen aus der Vereinbarung sind
        nicht übertragbar.
      </li>
      <li>
        Änderung des Namens, der Adresse und der Bankverbindung der Tänzer:innen
        sind der Tanzschule unverzüglich mitzuteilen. Durch schuldhafte
        Unterlassung entstehende Mehrkosten gehen zu Lasten der Tänzer:innen,
        bzw. Vertragspartner:innen bei Minderjährigen.
      </li>
      <li>
        Mündliche Nebenabreden bestehen nicht. Teilnichtigkeit bedeutet nicht
        Gesamtnichtigkeit.
      </li>
      <li>
        Hinweis nach § 33 BDSG: Es wird darauf hingewiesen, dass die Tanzschule
        Daten, die sich aus den Vertragsunterlagen oder der Vertragsdurchführung
        ergeben, im erforderlichem Umfang speichert.
      </li>
      <li>
        Während der Schleswig-Holsteiner Ferien, an Feiertagen und in der INTAKO
        – Woche findet kein Unterricht statt.
      </li>
      <li>
        Bei Minderjährigen ist der Vertragspartner der/die
        Erziehungsberechtigte/n. Der Vertragsnutzer ist der/die Teilnehmer/in.
      </li>
      <li>
        Für die Einlösung von Geschenkgutscheinen finden die Allgemeinen
        Geschäftsbedingungen und Datenschutzbestimmungen Anwendung, die auf
        unserer Website abrufbar sind.<br />
        Dieser Gutschein kann nur in den Tanzschulen von Tanzspass.com eingelöst
        werden. Der Gutschein kann nur für die Buchung eines Tanzkurses
        eingelöst werden und nicht für den Kauf eines weiteren Gutscheines
        verwendet werden.<br />
        Der Gutschein ist bis zum Ende des dritten Jahres nach dem Jahr des
        Gutscheinkaufs einlösbar und nicht übertragbar. Restguthaben werden bis
        zum Ablaufdatum Ihrem Geschenkgutscheinkonto gutgeschrieben, danach kann
        es nicht mehr verwendet werden.<br />
        Die nachträgliche Anrechnung eines Gutscheins ist nicht möglich. Das
        Guthaben eines Gutscheins wird nicht in Bargeld ausgezahlt.
      </li>
    </ol>
  </div>
</div>