<ul class="flex flex-col pl-4 pr-2" *ngIf="!updating && items">
  <li class="cursor-pointer rounded-md m-0.5"
    [ngClass]="selectedIndex === i ? 'bg-orangeLight text-white': 'text-gray-700 hover:bg-orangeLightX hover:text-orange'"
    (click)="onItemClick(item)"
    *ngFor="let item of filteredItems; let i = index" (click)="selectItem(i)" [routerLink]="mode === 'navigate' ? ['/backend', { outlets: { backendOutlet: [this.backend?.dbName, item.id], backendItemOutlet: [this.backend.dbName, item.id] } } ] : []" >
    <div class="flex flex-col mx-4 my-2">
      <h3 class="whitespace-nowrap font-semibold text-sm truncate" [innerHtml]="item.prettyName"></h3>
      <p class="whitespace-nowrap text-sm truncate" *ngIf="item.subtitle && item.subtitle.length > 0" matLine
        [innerHtml]="item.subtitle"></p>
    </div>
  </li>
</ul>
<div class="flex h-full w-full items-center justify-center p-4 gap-2"
  *ngIf="!updating && (!items || items.length === 0)">
  <mat-icon>{{ backend?.icon }}</mat-icon>
  <span class="text-xs" *ngIf="backend?.prettyName">
    Keine {{ backend?.prettyName }}<br /> vorhanden.</span>
  <span *ngIf="!backend ||!backend?.id" class="text-xs">
    Bitte Kategorie aus der Liste auswählen.
  </span>

</div>
<ts-loader *ngIf="updating"></ts-loader>
