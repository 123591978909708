import { DbObj } from './dbObj';
import { Event } from './event';

export enum QuestionType {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  RADIO = 'RADIO',
}

export class Question implements DbObj {
  public id: number;
  public question: string;
  public fkEvent: number;
  public event: Event;
  public questionType: QuestionType;

  public answer1: string;
  public answer2: string;
  public answer3: string;
  public answer4: string;
  public answer5: string;
  public answer6: string;
  public answer7: string;
  public answer8: string;
  public answer9: string;

  get prettyName() {
    return this.question;
  }

  get subtitle() {
      return '-';
  }
  
}