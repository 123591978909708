<div class="max-w-6xl mx-auto">
  <div class="flex flex-col mx-2">
    <div>
      <h3 class="text-3xl font-bold mt-4">
        {{ categorySelected?.name_pretty }}
      </h3>
      <!-- <mat-form-field class="mt-2" *ngIf="categories?.length > 1">
        <mat-label>Kurs/Kategorie auswählen</mat-label>
        <mat-select
          [(ngModel)]="categorySelected"
          (selectionChange)="updateVideos({ refreshDances: true })"
        >
          <mat-option *ngFor="let category of categories" [value]="category">{{
            category.name_pretty
          }}</mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- video categories -->
      <div class="grid grid-cols-2 lg:grid-cols-4 gap-2.5">
        <div class="mt-2" #categorySelect>
          <label id="listbox-label" class="block text-sm font-medium text-gray-700">Kurs/Kategorie auswählen</label>
          <div class="relative mt-1">
            <button type="button" (click)="categoryDropdownOpen = !categoryDropdownOpen"
              class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-orange focus:outline-none focus:ring-1 focus:ring-orange sm:text-sm"
              aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
              <span class="block truncate">{{findCategoryById(categorySelected?.id)?.subtitle
                || 'keine Kategorie ausgewählt'}}</span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <!-- Heroicon name: mini/chevron-up-down -->
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                  fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </button>

            <div [ngClass]="categoryDropdownOpen ? '' : 'hidden'"
              class="shadow-md rounded-md absolute z-10 max-h-60 w-full overflow-hidden bg-warmGray">
              <ul
                class="mt-1 w-full max-h-60 bg-warmGray text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm rounded-md overflow-hidden overflow-y-auto"
                tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                <div class="m-2">
                  <input
                    class="w-full py-2 px-4 block bg-white rounded-md border border-orangeLightX shadow-sm text-base"
                    type="text" [(ngModel)]="categoryFilterStr" (input)="onFilterCategory()" placeholder="filtern...">
                </div>
                <li (click)="onCategorySelect(item)" *ngFor="let item of filteredCategories"
                  [ngClass]="categorySelected.id === item.id ? 'text-white bg-orange' : 'text-gray-900 hover:bg-orangeLightX hover:text-orange'"
                  class="relative cursor-pointer m-0.5 rounded-md select-none py-2 pl-3 pr-9" id="listbox-option-0"
                  role="option">
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <div class="flex flex-col w-full">
                    <span class="font-bold block">{{item?.subtitle}}</span>
                    <!-- <span class="font-normal block" [innerHtml]="item?.subtitle"></span> -->
                  </div>

                  <!--
                Checkmark, only display for selected option.
      
                Highlighted: "text-white", Not Highlighted: "text-indigo-600"
              -->
                  <span *ngIf="categorySelected.id === item.id"
                    class="text-orange absolute inset-y-0 right-0 flex items-center pr-4">
                    <!-- Heroicon name: mini/check -->
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                      aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- years -->
        <div class="mt-2 w-full" #yearSelect>
          <label id="listbox-label" class="block text-sm font-medium text-gray-700">Jahr auswählen</label>
          <div class="relative mt-1">
            <button type="button" (click)="yearDropdownOpen = !yearDropdownOpen"
              class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-orange focus:outline-none focus:ring-1 focus:ring-orange sm:text-sm"
              aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
              <span class="block truncate">{{yearSelected || 'kein Jahr ausgewählt'}}</span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <!-- Heroicon name: mini/chevron-up-down -->
                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                  fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd"
                    d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                    clip-rule="evenodd" />
                </svg>
              </span>
            </button>

            <div [ngClass]="yearDropdownOpen ? '' : 'hidden'"
              class="shadow-md rounded-md absolute z-10 max-h-60 w-full overflow-hidden bg-warmGray">
              <ul
                class="mt-1 w-full max-h-60 bg-warmGray text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm rounded-md overflow-hidden overflow-y-auto"
                tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                <li
                  [ngClass]="yearSelected === null ? 'text-white bg-orange' : 'text-gray-900 hover:bg-orangeLightX hover:text-orange'"
                  (click)="onYearSelect(null)"
                  class="relative m-0.5 font-bold rounded-md cursor-pointer select-none py-2 pl-3 pr-9"
                  id="listbox-option-0" role="option">
                  kein Jahr ausgewählt
                </li>
                <li (click)="onYearSelect(year)" *ngFor="let year of years"
                  [ngClass]="yearSelected === year ? 'text-white bg-orange' : 'text-gray-900 hover:bg-orangeLightX hover:text-orange'"
                  class="relative cursor-pointer m-0.5 rounded-md select-none py-2 pl-3 pr-9" id="listbox-option-0"
                  role="option">
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <div class="flex flex-col w-full">
                    <span class="font-bold block">{{year}}</span>
                    <!-- <span class="font-normal block" [innerHtml]="item?.subtitle"></span> -->
                  </div>

                  <!--
                Checkmark, only display for selected option.
      
                Highlighted: "text-white", Not Highlighted: "text-indigo-600"
              -->
                  <span *ngIf="yearSelected === year"
                    class="text-orange absolute inset-y-0 right-0 flex items-center pr-4">
                    <!-- Heroicon name: mini/check -->
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                      aria-hidden="true">
                      <path fill-rule="evenodd"
                        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                        clip-rule="evenodd" />
                    </svg>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div *ngIf="categorySelected && categorySelected.hasDances" #danceSelect>
          <div class="mt-2 w-full">
            <label id="listbox-label" class="block text-sm font-medium text-gray-700">Tanz auswählen</label>
            <div class="relative mt-1">
              <button type="button" (click)="danceDropdownOpen = !danceDropdownOpen"
                class="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-orange focus:outline-none focus:ring-1 focus:ring-orange sm:text-sm"
                aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                <span class="block truncate">{{findDanceById(danceSelected?.id)?.prettyName
                  || 'kein Tanz ausgewählt'}}</span>
                <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <!-- Heroicon name: mini/chevron-up-down -->
                  <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                    fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd"
                      d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                      clip-rule="evenodd" />
                  </svg>
                </span>
              </button>

              <div [ngClass]="danceDropdownOpen ? '' : 'hidden'"
                class="shadow-md rounded-md absolute z-10 max-h-60 w-full overflow-hidden bg-warmGray">
                <ul
                  class="mt-1 w-full max-h-60 bg-warmGray text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm rounded-md overflow-hidden overflow-y-auto"
                  tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                  <div class="m-2">
                    <input
                      class="w-full py-2 px-4 block bg-white rounded-md border border-orangeLightX shadow-sm text-base"
                      type="text" [(ngModel)]="danceFilterStr" (input)="onFilterDance()" placeholder="filtern...">
                  </div>
                  <li *ngFor="let item of filteredDances"
                    [ngClass]="danceSelected.id === item.id ? 'text-white bg-orange' : 'text-gray-900 hover:bg-orangeLightX hover:text-orange'"
                    class="relative cursor-pointer m-0.5 rounded-md select-none py-2 pl-3 pr-9" id="listbox-option-0"
                    role="option" (click)="onDanceSelect(item)">
                    <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                    <div class="flex flex-col w-full">
                      <span class="font-bold block">{{item?.prettyName}}</span>
                      <!-- <span class="font-normal block" [innerHtml]="item?.subtitle"></span> -->
                    </div>

                    <!--
                  Checkmark, only display for selected option.
        
                  Highlighted: "text-white", Not Highlighted: "text-indigo-600"
                -->
                    <span *ngIf="danceSelected.id === item.id"
                      class="text-orange absolute inset-y-0 right-0 flex items-center pr-4">
                      <!-- Heroicon name: mini/check -->
                      <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                        aria-hidden="true">
                        <path fill-rule="evenodd"
                          d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                          clip-rule="evenodd" />
                      </svg>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2 w-full">
          <label for="searchVideos" class="block text-sm font-medium text-gray-700">Suchen
              </label>
          <div class="mt-1">
            <input placeholder="Suchen" (input)="onFilterBySearch()" id="searchVideos" name="searchVideos"
              type="text" [(ngModel)]="q"
              class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md text-sm" />
          </div>
        </div>
        
      </div>
    </div>

    <div class="mt-2.5 grid sm:grid-cols-2 md:grid-cols-3 gap-4">
      <div *ngFor="let video of filteredVideos">
        <!-- Youtube -->
        <ng-container *ngIf="video?.youtube_video_id">
          <div (click)="video.clicked = true" class="
              rounded-lg
              shadow-lg
              overflow-hidden
              relative
              aspect-w-16 aspect-h-9
              object-cover
              cursor-pointer
            " *ngIf="!video.clicked">
            <img class="h-full w-full object-cover" (click)="video.clicked = true" [src]="
                'https://i3.ytimg.com/vi/' +
                video.youtube_video_id +
                '/hqdefault.jpg'
              " [alt]="video.getTitle()" />
            <div class="inset-0 absolute flex justify-center items-center">
              <img class="w-12 h-12" src="assets/img/youtube.svg" alt="play" />
            </div>
          </div>
          <div *ngIf="video.clicked" class="aspect-w-16 aspect-h-9 rounded-lg shadow-lg overflow-hidden">
            <iframe class="w-full" [src]="
                'https://www.youtube-nocookie.com/embed/' +
                  video.youtube_video_id +
                  '?autoplay=1' | safe
              " frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
          </div>
        </ng-container>

        <!-- Vimeo -->
        <ng-container *ngIf="video?.vimeo_video_id">
          <div class="
              rounded-lg
              shadow-lg
              overflow-hidden
              relative
              aspect-w-16 aspect-h-9
              object-cover
              cursor-pointer
            ">
            <iframe class="w-full" [src]="
                'https://player.vimeo.com/video/' + video.vimeo_video_id | safe
              " frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
          </div>
        </ng-container>
        <div>
          <div class="mt-2">
            <p class="flex space-x-2 items-center text-gray-600 text-sm">
              <mat-icon class="text-icon">date_range</mat-icon>
              <span>{{ video.date | date: "MMMM yyyy" }}</span>
            </p>
            <div class="font-bold mt-2">{{ video.getTitle() }}</div>
            <div class="text-sm text-gray-500">
              <span>{{video.getSubTitle()}}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="videos.length === 0">
        <div>
          <mat-icon class="text-icon text-7xl">slideshow</mat-icon>

          <p>Leider keine Videos mit der aktuellen Auswahl gefunden!</p>
          <button class="bg-white shadow-lg rounded-full px-3 py-2" (click)="resetSelection()">
            Zurücksetzen
          </button>
        </div>
      </div>
    </div>
  </div>
</div>