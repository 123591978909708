import { Component, Input, OnInit } from "@angular/core";
import { DrinkCategory } from "../models/drinkCategory";
import { NgFor } from "@angular/common";
import { PictureComponent } from "../shared/picture/picture.component";

@Component({
    selector: "ts-drink-category",
    templateUrl: "./drink-category.component.html",
    styleUrls: ["./drink-category.component.css"],
    standalone: true,
    imports: [PictureComponent, NgFor],
})
export class DrinkCategoryComponent implements OnInit {
  @Input() drinkCategory = new DrinkCategory();

  constructor() { }

  ngOnInit(): void {}
}
