<div class="my-4 max-w-6xl mx-auto flex flex-col divide-y divide-gray-200">
  <div class="mb-1.5 flex justify-between w-full text-xs text-gray-500">
    <div class="flex w-full">
      <div class="flex-1">Bezeichnung</div>
      <div class="flex-1 hidden sm:block">Starttermin</div>
      <div class="flex-1">
        <span class="xs:hidden">Tag</span>
        <span class="hidden xs:inline">Wochentag</span>
      </div>
      <div class="flex-1">Uhrzeit</div>
    </div>
    <div class="w-[90px] xs:w-[90px] sm:w-[90px] md:!w-[122px]"></div>
  </div>

  <div class="py-1.5 flex flex-col" *ngFor="let course of courses">
    <div class="flex items-center justify-between space-x-1.5">
      <div class="flex items-center w-full">
        <!-- description -->
        <div class="flex flex-col flex-1 overflow-ellipsis">
          <span class="font-bold text-sm text-gray-800 overflow-ellipsis">{{
            course.description
          }}</span>
          <span class="text-gray-600 text-xs overflow-ellipsis">{{
            course.subtext
          }}</span>
        </div>

        <!-- startDates desktop -->
        <div
          *ngIf="course?.course_start_dates?.length > 0"
          class="flex-1 gap-1 flex-wrap hidden sm:flex"
        >
          <div class="flex" *ngFor="let startDate of course.course_start_dates">
            <div
              [matTooltipDisabled]="!course.isDisabled() && !startDate.is_full_for_f && !startDate.is_full_for_m"
              matTooltipPosition="above" matTooltip="Der Termin ist leider ausgebucht."
              [ngClass]="!!startDate.is_full_for_f && !!startDate.is_full_for_m ? 'line-through' : ''"
              class="text-gray-800 bg-gray-100 rounded-full py-0.5 px-2 text-xs"
            >
              {{ startDate.date }}
            </div>
          </div>
        </div>
        <div
          class="text-sm text-gray-700 flex-1 hidden sm:flex"
          *ngIf="
            !course.course_start_dates || course.course_start_dates.length === 0
          "
        >
          {{
            course.noStartDatesText ? course.noStartDatesText : noStartDatesText
          }}
        </div>

        <!-- weekday -->
        <div class="text-sm flex-1 text-gray-700">
          <span class="xs:hidden">{{ course.getShortWeekday() }}</span>
          <span class="hidden xs:inline">{{ course.weekday }}</span>
        </div>

        <!-- time -->
        <div class="flex-1 text-sm text-gray-700">
          {{ course.getTimeText() }}
        </div>
      </div>
      <button
        [disabled]="course.isDisabled()"
        [matTooltipDisabled]="!course.isDisabled()"
        matTooltipPosition="above" matTooltip="Der Termin ist leider ausgebucht."
        [routerLink]="['/buchen', course.id]"
        class="px-2 py-1 rounded-full shadow-md bg-orange font-bold text-white text-xs sm:text-sm md:py-1.5 md:px-3"
      >
        Anmelden
      </button>
    </div>
    <div
      *ngIf="course?.course_start_dates?.length > 0"
      class="mt-2 self-end items-center space-x-2 sm:hidden flex"
    >
      <div class="text-xs text-gray-600 mt-1.5">Starttermine:</div>
      <div class="flex-1 gap-1 flex flex-wrap">
        <div
          class="flex mt-1"
          *ngFor="let startDate of course.course_start_dates"
        >
          <div class="text-gray-800 bg-gray-100 rounded-full py-1 px-2 text-xs">
            {{ startDate.date }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="self-start text-xs text-gray-700 flex-1 sm:hidden flex"
      *ngIf="
        !course.course_start_dates || course.course_start_dates.length === 0
      "
    >
      {{ course.noStartDatesText ? course.noStartDatesText : noStartDatesText }}
    </div>
  </div>
</div>
