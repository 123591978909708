import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { NgClass, NgFor, NgIf } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from "@angular/core";
import { MatLine } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatIcon } from "@angular/material/icon";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { take } from "rxjs";
import { LoaderComponent } from "../../loader/loader.component";
import { Backend } from "../../models/backend";
import { DbObj } from "../../models/dbObj";
import { School } from "../../models/school";
import { AuthService } from "../../services/auth.service";
import { BackendEditService } from "../../services/backend-edit.service";

@Component({
    selector: "ts-generic-list",
    templateUrl: "./generic-list.component.html",
    styleUrls: ["./generic-list.component.css"],
    animations: [
        trigger("detailExpand", [
            state("collapsed", style({ height: "0px", minHeight: "0" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
        ]),
    ],
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        NgClass,
        RouterLink,
        MatLine,
        MatIcon,
        LoaderComponent,
    ],
    host: { ngSkipHydration: "true" },
})
export class GenericListComponent<T extends DbObj> implements OnChanges {
  items: T[];
  filteredItems: T[];
  @Input() genericService: any;
  @Input() enableEditSelection = true;
  @Input() backend: Backend;
  @Input() mode: "navigate" | "select";
  @Output() itemClicked: EventEmitter<T> = new EventEmitter<T>();
  selectedIndex = 0;

  school: School = new School();

  updating = false;

  constructor(
    private editSrv: BackendEditService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private backendEditSrv: BackendEditService,
    private authSrv: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  onItemClick(item: T) {
    this.itemClicked.next(item);
  }

  ngOnChanges(changes) {
    if (changes.backend) {
      this.authSrv.school.subscribe((school) => {
        this.school = school;
        this.update();
        if (this.enableEditSelection) {
          this.editSrv.itemHasBeenEdited.asObservable().subscribe(() => {
            this.update();
          });
        }
      });
    }
  }

  selectItem(index: number) {
    if (this.mode === "select") {
      this.selectedIndex = index;
    }
  }

  private update() {
    this.selectedIndex = -1;
    if (this.backend?.id) {
      this.updating = true;
      this.loadItem();
    }
  }

  selectItemByRoute() {
    if (this.route.snapshot.params.itemId) {
      this.selectedIndex = this.items.findIndex(
        (item) => this.route.snapshot.params.itemId - item.id === 0
      );
    }
  }

  loadItem() {
    const observable = this.backend.isDependentOnSchool
      ? this.genericService.findBySchool(this.school.id)
      : this.genericService.findAll();
    observable.pipe(take(1)).subscribe({
      next: (items) => {
        this.filteredItems = items;
        this.items = items;
        this.updating = false;
        this.selectItemByRoute();

        this.backendEditSrv.filter.asObservable().subscribe((filterStr) => {
          this.filteredItems = this.items.filter((item) =>
            item.prettyName.toLowerCase().includes(filterStr)
          );
          this.selectItemByRoute();
          this.updating = false;
        });
      },
      error: (err) => {
        this.snackBar.open("Das Element konnte nicht geladen werden!", null, {
          duration: 3000,
        });
        this.updating = false;
        this.items = [];
      },
    });
  }
}
