import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Column } from '../../../models/column';
import { FormsModule } from '@angular/forms';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'ts-enum-field',
    templateUrl: './enum-field.component.html',
    styleUrls: ['./enum-field.component.css'],
    standalone: true,
    imports: [NgIf, FormsModule, NgFor]
})
export class EnumFieldComponent {

  @Input() column: Column = new Column();
  @Output() columnChange = new EventEmitter();

  onChange() {
    this.columnChange.emit(this.column);
  }
}
