import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class GalleryService {

  static prefix = 'gallery.php';
  static imagePrefix = 'https://img.tanzspass.com/';

  constructor(private http: TsHttpService) { }

  public bySchoolName(schoolName: string): Observable<any[]> {
    return this.http.getImg(`${GalleryService.prefix}?school=${schoolName}`);
  }
}
