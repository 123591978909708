import { DbObj } from './dbObj';

export class PaymentMethod implements DbObj {

    public id: number;
    public name: string;
    public nameUrl: string;
    public priceDelta: number;
    public pay_in_advance: number;

    constructor() { }

    get prettyName() {
        return this.name;
    }

    get subtitle() {
        return '';
    }
}
