import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, RouterLink } from "@angular/router";
import { AgeClass } from "../../models/ageClass";
import { School } from "../../models/school";
import { routerTransition } from "../../router.animations";
import { AgeClassService } from "../../services/age-class.service";
import { SchoolService } from "../../services/school.service";
import { SeoService } from "../../services/seo.service";
import { CourseListComponent } from "../../course/course-list/course-list.component";
import { CourseButtonsComponent } from "../../course/course-buttons/course-buttons.component";
import { PictureComponent } from "../../shared/picture/picture.component";
import { MatIcon } from "@angular/material/icon";
import { NgFor, NgIf } from "@angular/common";
import { MatTabNav, MatTabLink } from "@angular/material/tabs";

@Component({
    selector: "ts-routing-course-list",
    templateUrl: "./routing-course-list.component.html",
    styleUrls: ["./routing-course-list.component.css"],
    animations: [routerTransition()],
    standalone: true,
    imports: [
        MatTabNav,
        NgFor,
        MatTabLink,
        RouterLink,
        MatIcon,
        NgIf,
        PictureComponent,
        CourseButtonsComponent,
        CourseListComponent,
    ],
})
export class RoutingCourseListComponent implements OnInit {
  public school = new School();
  public ageClasses: AgeClass[] = new Array();
  public ageClass: AgeClass = new AgeClass();
  public params: Params;
  public loaded = false;

  constructor(
    private route: ActivatedRoute,
    private ageClassSrv: AgeClassService,
    private schoolSrv: SchoolService,
    private seoSrv: SeoService
  ) {}

  ngOnInit() {
    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
      this.ageClassSrv.findAll().subscribe((ageClasses) => {
        this.route.params.subscribe((params) => {
          this.params = params;
          this.ageClassSrv
            .byNameUrl(params["ageClass"])
            .subscribe((ageClass) => {
              this.ageClass = ageClass;

              if (ageClass.name.includes("kurs")) {
                this.seoSrv.updateTitle(
                  `Tanz-Kurzkurse in ${school.city} | ${school.name}`
                );
              } else {
                this.seoSrv.updateTitle(
                  `Tanzkurse in ${school.city} für ${ageClass.name} | ${school.name}`
                );
              }

              this.seoSrv.updateDescription(ageClass.text);
              this.seoSrv.updateOgUrl(
                `https://${school.homepage_url}/kurse/${ageClass.nameUrl}`
              );

              this.ageClasses = ageClasses.filter((filteredAgeClass) => {
                return (
                  this.school.show_holiday_courses ||
                  filteredAgeClass.nameUrl !== "ferienprogramm"
                );
              });
              this.loaded = true;
            });
        });
      });
    });
  }
}
