<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
  style="margin:auto;background:#fff;display:block;" width="200px" height="200px" viewBox="0 0 100 100"
  preserveAspectRatio="xMidYMid">
  <circle cx="84" cy="50" r="10" fill="#e05a11">
    <animate attributeName="r" repeatCount="indefinite" dur="1.0869565217391304s" calcMode="spline" keyTimes="0;1"
      values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
    <animate attributeName="fill" repeatCount="indefinite" dur="4.3478260869565215s" calcMode="discrete"
      keyTimes="0;0.25;0.5;0.75;1" values="#e05a11;#c9510f;#fae4d6;#ff8b43;#e05a11" begin="0s"></animate>
  </circle>
  <circle cx="16" cy="50" r="10" fill="#e05a11">
    <animate attributeName="r" repeatCount="indefinite" dur="4.3478260869565215s" calcMode="spline"
      keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
      begin="0s"></animate>
    <animate attributeName="cx" repeatCount="indefinite" dur="4.3478260869565215s" calcMode="spline"
      keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
      begin="0s"></animate>
  </circle>
  <circle cx="50" cy="50" r="10" fill="#ff8b43">
    <animate attributeName="r" repeatCount="indefinite" dur="4.3478260869565215s" calcMode="spline"
      keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
      begin="-1.0869565217391304s"></animate>
    <animate attributeName="cx" repeatCount="indefinite" dur="4.3478260869565215s" calcMode="spline"
      keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
      begin="-1.0869565217391304s"></animate>
  </circle>
  <circle cx="84" cy="50" r="10" fill="#fae4d6">
    <animate attributeName="r" repeatCount="indefinite" dur="4.3478260869565215s" calcMode="spline"
      keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
      begin="-2.1739130434782608s"></animate>
    <animate attributeName="cx" repeatCount="indefinite" dur="4.3478260869565215s" calcMode="spline"
      keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
      begin="-2.1739130434782608s"></animate>
  </circle>
  <circle cx="16" cy="50" r="10" fill="#c9510f">
    <animate attributeName="r" repeatCount="indefinite" dur="4.3478260869565215s" calcMode="spline"
      keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
      begin="-3.260869565217391s"></animate>
    <animate attributeName="cx" repeatCount="indefinite" dur="4.3478260869565215s" calcMode="spline"
      keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
      begin="-3.260869565217391s"></animate>
  </circle>
</svg>
