<div class="relative h-screen">
  <img
    class="object-cover h-screen"
    src="/assets/img/tmp/beach2.jpg
"
    alt=""
  />
  <div
    class="absolute bottom-12 left-12 flex flex-col items-center justify-center"
  >
    <div
      class="
        backdrop-filter backdrop-blur-md backdrop-saturate-50
        p-8
        shadow-sm
        rounded-larger
      "
    >
      <h1 class="text-6xl text-white font-bold">
        Starten Sie jetzt Ihren<br /><span class="text-orange"
          >Online-Tanzkurs</span
        >
      </h1>
      <p class="text-white mt-6 max-w-prose">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Dolorum,
        deleniti vitae perferendis hic debitis ducimus. Eveniet, ipsum explicabo
        corporis ea quos ducimus eum libero aliquid sed asperiores sequi
        consequuntur adipisci?
      </p>
      <div class="flex space-x-4 mt-8">
        <a
          routerLink="/online/registrieren"
          class="
            inline-block
            bg-orange
            py-3
            px-4
            border border-transparent
            rounded-md
            text-base
            font-medium
            text-white
            hover:bg-orangeLight
          "
          >Jetzt lostanzen</a
        >
        <a
          routerLink="/online/faq"
          class="
            inline-block
            bg-white
            py-3
            px-4
            border border-transparent
            rounded-md
            text-base
            font-medium
            text-lightOrange
            hover:bg-gray-100
          "
          >Wie funktioniert das?</a
        >
      </div>
    </div>
  </div>
</div>
<div class="h-96">
  <h2>Lorem ipsum dolor</h2>
</div>
