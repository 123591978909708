<div class="mt-4">
  <mat-vertical-stepper linear="true" #stepper>
    <!-- Step 1 -->
    <mat-step [stepControl]="startDatesForm" errorMessage="Das Auswählen eines Startdatums ist verpflichtend.">
      <ng-template matStepLabel>Startdatum auswählen</ng-template>
      <form [formGroup]="startDatesForm" class="startDatesForm">
        <h2>Startdatum auswählen</h2>
        <span *ngIf="
            course?.course_start_dates &&
            course?.course_start_dates.length === 0
          ">
          Dieser Kurs hat keine festen Starttermine.
        </span>
        <mat-radio-group formControlName="startDate">
          <mat-list>
            <mat-list-item *ngFor="let myStartDate of course.course_start_dates">
              <mat-radio-button [checked]="startDate && startDate.id === myStartDate.id"
                (change)="startDateSelected = true" [value]="myStartDate">
                {{ myStartDate.date }}
              </mat-radio-button>
            </mat-list-item>
          </mat-list>
        </mat-radio-group>
        <button class="rounded-full px-3 py-1.5 bg-orange hover:bg-orangeHover shadow-md text-white text-sm font-bold"
          matStepperNext [disabled]="!startDatesForm.valid">
          Weiter
        </button>
      </form>
    </mat-step>

    <!-- Step 2 -->
    <mat-step [completed]="person.valid">
      <ng-template matStepLabel>Persönliche Daten</ng-template>
      <h2>Persönliche Daten</h2>
      <ts-course-data-form [startDate]="startDate" (getFormValues)="person = $event" (isValid)="person.valid = $event"
        (getForm)="personForm = $event" [person]="person" [required]="true"></ts-course-data-form>

      <div *ngIf="courseHasPartner()">
        <h2>Persönliche Daten des Partners/der Partnerin</h2>
        <ts-course-data-form (getFormValues)="partner = $event" (isValid)="partner.valid = $event" [person]="partner">
        </ts-course-data-form>
      </div>

      <div *ngIf="courseNeedsParent()">
        <h2>
          Persönliche Daten eines/einer Erziehungsberechtigten (Freilassen
          möglich)
        </h2>
        <ts-course-data-form (getFormValues)="parent = $event" (isValid)="parent.valid = $event" [person]="parent">
        </ts-course-data-form>
      </div>

      <button class="rounded-full px-3 py-1.5 bg-orange hover:bg-orangeHover shadow-md text-white text-sm font-bold"
        matStepperNext [disabled]="!person.valid">
        Weiter
      </button>
    </mat-step>

    <!-- Step 3 -->
    <mat-step [stepControl]="paymentMethodForm">
      <ng-template matStepLabel>Vertrags- und Zahlungsart</ng-template>
      <form [formGroup]="paymentMethodForm" class="paymentMethodForm">
        <h2 *ngIf="course.priceComplete === null">Vertragsdauer auswählen</h2>
        <mat-radio-group formControlName="contractDuration" *ngIf="course.priceComplete === null">
          <mat-list>
            <mat-list-item *ngFor="let contractDuration of contractDurations">
              <mat-radio-button [value]="contractDuration">
                mindestens {{ contractDuration }} Monate
                <span>({{
                  course["price" + contractDuration] / 100
                  | currency: "EUR":"symbol"
                  }}
                  / Monat)</span>
              </mat-radio-button>
            </mat-list-item>
          </mat-list>
        </mat-radio-group>

        <h2>Zahlungsart auswählen</h2>

        <mat-radio-group formControlName="paymentMethod">
          <mat-list>
            <mat-list-item *ngFor="let method of methods">
              <mat-radio-button [value]="method">
                <!-- Daueraufträge statt Überweisung außer bei Ferienkursen und Kurzkursen -->
                {{
                method.name === "Überweisung" &&
                course.courseLevel?.ageClass?.id < 4 && course.courseLevel?.description_url !=="fitdankbaby"
                  ? "Dauerauftrag" : method.name }} <span *ngIf="method.priceDelta !== 0">(+{{
                  method.priceDelta / 100 | currency: "EUR":"symbol"
                  }} pro Monat)</span>
              </mat-radio-button>
            </mat-list-item>
          </mat-list>
        </mat-radio-group>

        <mat-form-field class="w-1/2" *ngIf="paymentMethod?.name === 'Lastschrift'">
          <input type="text" matInput placeholder="IBAN" formControlName="iban" />
          <mat-icon matSuffix>credit_card</mat-icon>
        </mat-form-field>

        <mat-form-field class="w-1/2" *ngIf="paymentMethod?.name === 'Lastschrift'">
          <input type="text" matInput placeholder="Kontoinhaber(in)" formControlName="bankaccount_owner" />
          <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>

        <span *ngIf="paymentMethod?.name === 'Lastschrift' && courseHasPartner()">
          <mat-form-field class="w-1/2">
            <input type="text" matInput ngxIban placeholder="IBAN des Partners / der Partnerin (optional)"
              formControlName="iban_partner" />
            <mat-icon matSuffix>credit_card</mat-icon>
          </mat-form-field>

          <mat-form-field class="w-1/2">
            <input type="text" matInput placeholder="Kontoinhaber(in)" formControlName="bankaccount_owner_partner" />
            <mat-icon matSuffix>person</mat-icon>
          </mat-form-field>
        </span>

        <mat-form-field class="w-1/2" *ngIf="paymentMethod?.name === 'Gutschein'">
          <input type="text" matInput placeholder="Gutscheincode" formControlName="voucher_code" />
          <mat-icon matSuffix>card_giftcard</mat-icon>
        </mat-form-field>
        <br />

        <div *ngIf="paymentMethod?.pay_in_advance">
          Bitte drei Monate im Voraus zahlen.
        </div>
        <br /><br />
        <button class="rounded-full px-3 py-1.5 bg-orange hover:bg-orangeHover shadow-md text-white text-sm font-bold"
          matStepperNext [disabled]="
            paymentMethodForm.invalid && paymentMethod?.name === 'Lastschrift'
          ">
          Weiter
        </button>
      </form>
    </mat-step>

    <!-- Step 4 -->
    <mat-step [stepControl]="contractDetailsForm">
      <ng-template matStepLabel>Vertragsdetails</ng-template>
      <form class="mt-6 flex flex-col contractDetailsForm" [formGroup]="contractDetailsForm">
        <h2 class="font-bold text-xl">Vertragsdetails</h2>
        <h3 class="font-bold text-lg">Zusammenfassung</h3>
        <div class="">
          <h4 class="font-bold">Starttermin</h4>
          <div class="mt-1" *ngIf="startDate">
            {{ startDate?.date }}
          </div>
          <div class="mt-1" *ngIf="!startDate?.date">
            {{ courseLevel?.no_start_dates_text }}
          </div>
        </div>
        <div class="flex flex-col sm:flex-row mt-4 gap-2">
          <div>
            <h4 class="font-bold">VertragspartnerIn</h4>
            {{ person?.first_name }} {{ person?.last_name }} <br />
            {{ person?.street }} {{ person?.streetNo }} <br />
            {{ person?.plz }} {{ person?.city }}
          </div>
          <div class="ts-margin" *ngIf="courseHasPartner()">
            <h4 class="font-bold">TanzpartnerIn</h4>
            {{ partner?.first_name }} {{ partner?.last_name }} <br />
            {{ partner?.street }} {{ partner?.streetNo }} <br />
            {{ partner?.plz }} {{ partner?.city }}
          </div>
          <div class="ts-margin " *ngIf="courseNeedsParent()">
            <h4 class="font-bold">Erziehungsberechtigte(r)</h4>
            {{ parent?.first_name }} {{ parent?.last_name }} <br />
            {{ parent?.street }} {{ parent?.streetNo }} <br />
            {{ parent?.plz }} {{ parent?.city }}
          </div>
        </div>
        <div class="ts-margin mt-4">
          <h4 class="font-bold">Zahlungsart</h4>
          <div class="mt-1">
            {{ paymentMethod?.name }}
            <div class="text-sm" *ngIf="paymentMethod?.pay_in_advance">
              Bitte drei Monate im Voraus zahlen.
            </div>
          </div>
        </div>
        <div class="mt-4">
          <h4 class="font-bold">Preis</h4>

          <!-- Fixed contract durations of 3, 6 or 12 months -->
          <div *ngIf="course?.priceComplete === null">
            {{
            course["price" + contractDuration] / 100 +
            paymentMethod?.priceDelta / 100 | currency: "EUR":"symbol"
            }} pro Person<br /><br />
            <div *ngIf="courseLevel.ageClass?.id == 3">
              Der Vertrag läuft {{ contractDuration }} Monate und verlängert
              sich nach Ablauf automatisch um weitere
              {{ contractDuration }}
              Monate.
              <br />
              Eine Kündigung ist mindestens 30 Tage vor Vertragsende
              auszusprechen.
            </div>
            <div *ngIf="courseLevel.ageClass?.id == 2">
              Der Vertrag läuft {{ contractDuration }} Monate und verlängert
              sich nach Ablauf automatisch um jeweils einen Monat. <br />
              Eine Kündigung ist mindestens 30 Tage vor Vertragsende
              auszusprechen.
            </div>
          </div>
          <div *ngIf="course?.priceComplete !== null">
            <span *ngIf="courseLevel.ageClass?.id == 1">Monatspreis:</span>
            <span *ngIf="
                courseLevel.ageClass?.id == 4 ||
                courseLevel.ageClass?.id == 5 ||
                courseLevel.description == 'Hochzeitskurs' ||
                courseLevel.description == 'Jumping (Trampolin Fitness)' ||
                courseLevel.description == 'fitdankbaby®'
              ">Gesamtpreis pro Person:</span>
            <span *ngIf="
                (courseLevel.ageClass?.id == 2 ||
                  courseLevel.ageClass?.id == 3) &&
                courseLevel.description != 'Hochzeitskurs' &&
                courseLevel.description != 'Jumping (Trampolin Fitness)' &&
                courseLevel.description != 'fitdankbaby®'
              ">
              Monatsbeitrag pro Person:
            </span>
            {{
            course?.priceComplete / 100 + paymentMethod?.priceDelta / 100
            | currency: "EUR":"symbol"
            }}
            <br />
            <span *ngIf="
                courseLevel.ageClass?.id == 4 ||
                courseLevel.ageClass?.id == 5 ||
                courseLevel.description == 'Hochzeitskurs' ||
                courseLevel.description == 'Jumping (Trampolin Fitness)' ||
                courseLevel.contract_auto_extend === 0
              ">Der Vertrag verlängert sich
              <strong>nicht</strong> automatisch.</span>
            <span *ngIf="
                (courseLevel.ageClass?.id == 1 ||
                  courseLevel.ageClass?.id == 2 ||
                  courseLevel.ageClass?.id == 3) &&
                courseLevel.description != 'Hochzeitskurs' &&
                courseLevel.description != 'Jumping (Trampolin Fitness)' &&
                courseLevel.description != 'fitdankbaby®' &&
                courseLevel.contract_auto_extend !== 0
              ">Der Vertrag verlängert sich monatlich automatisch. <br />
              Eine Kündigung ist mindestens 30 Tage vor Vertragsende
              auszusprechen.</span>
          </div>
        </div>
        <br />
        <br />
        <mat-form-field class="w-full">
          <textarea type="text" matInput placeholder="Bemerkungen" formControlName="notes"></textarea>
          <mat-icon matSuffix>notes</mat-icon>
        </mat-form-field>

        <mat-checkbox formControlName="dataProtectionAccepted">Ich habe die
          <a href="/datenschutz" target="_blank">Datenschutzerklärung</a>
          gelesen und bin
          <br />
          damit einverstanden, dass meine Daten gemäß DSGVO gespeichert werden.
        </mat-checkbox>
        <mat-checkbox formControlName="agbAccepted">Ich habe die
          <a class="text-orange underline" href="/agb" target="_blank">AGBs und die Bestimmungen zum Widerruf</a>
          gelesen und bin damit einverstanden
        </mat-checkbox>

        <br />

        <button class="rounded-full px-3 py-1.5 bg-orange hover:bg-orangeHover shadow-md text-white text-sm font-bold mt-4 self-start"
            (click)="onSubmit()" [disabled]="
            !contractDetailsForm.valid || !startDateSelected || submitting
          ">
          Kostenpflichtig bestellen
        </button>
      </form>
    </mat-step>
  </mat-vertical-stepper>
</div>
