<div class="max-w-6xl mx-auto">
  <div class="container mx-2 mt-4" [@routerTransition]="">
    <div>
      <h1 class="font-bold text-3xl">Impressum</h1>
      <h2 class="font-bold text-xl">Angaben gemäß § 5 TMG:</h2>
      <p [innerHTML]="school.imprint_infos"></p>
    </div>
    <div>
      <div class="prose mt-4">
        <h2>Haftungsausschluss:</h2>

        <p>
          1. Inhalt des Onlineangebotes Der Autor übernimmt keinerlei Gewähr für
          die Aktualität, Korrektheit, Vollständigkeit oder Qualität der
          bereitgestellten Informationen. Haftungsansprüche gegen den Autor,
          welche sich auf Schäden materieller oder ideeller Art beziehen, die
          durch die Nutzung oder Nichtnutzung der dargebotenen Informationen
          bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen
          verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens
          des Autors kein nachweislich vorsätzliches oder grob fahrlässiges
          Verschulden vorliegt. Alle Angebote sind freibleibend und
          unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der
          Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
          verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise
          oder endgültig einzustellen.
        </p>

        <p>
          2. Verweise und Links Bei direkten oder indirekten Verweisen auf
          fremde Internetseiten ("Links"), die außerhalb des
          Verantwortungsbereiches des Autors liegen, würde eine
          Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in
          dem der Autor von den Inhalten Kenntnis hat und es ihm technisch
          möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte
          zu verhindern. Der Autor erklärt hiermit ausdrücklich, dass zum
          Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu
          verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige
          Gestaltung, die Inhalte oder die Urheberschaft der
          gelinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb
          distanziert er sich hiermit ausdrücklich von allen Inhalten aller
          gelinkten /verknüpften Seiten, die nach der Linksetzung verändert
          wurden. Diese Feststellung gilt für alle innerhalb des eigenen
          Internetangebotes gesetzten Links und Verweise. Für illegale,
          fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden,
          die aus der Nutzung oder Nichtnutzung solcherart dargebotener
          Informationen entstehen, haftet allein der Anbieter der Seite, auf
          welche verwiesen wurde, nicht derjenige, der über Links auf die
          jeweilige Veröffentlichung lediglich verweist.
        </p>

        <p>
          3. Urheber- und Kennzeichenrecht Der Autor ist bestrebt, in allen
          Publikationen die Urheberrechte der verwendeten Grafiken,
          Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst
          erstellte Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen
          oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte
          zurückzugreifen. Alle innerhalb des Internetangebotes genannten und
          ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen
          uneingeschränkt den Bestimmungen des jeweils gültigen
          Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen
          Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss
          zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt
          sind! Das Copyright für veröffentlichte, vom Autor selbst erstellte
          Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung
          oder Verwendung solcher Fotografien, Grafiken, Tondokumente,
          Videosequenzen und Texte in anderen elektronischen oder gedruckten
          Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht
          gestattet und würde in jedem Falle gerichtliche Konsequenzen nach sich
          ziehen.
        </p>

        <p>
          4. Datenschutz Sofern innerhalb des Internetangebotes die Möglichkeit
          zur Eingabe persönlicher oder geschäftlicher Daten (Emailadressen,
          Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten
          seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die
          Inanspruchnahme und Bezahlung aller angebotenen Dienste ist – soweit
          technisch möglich und zumutbar – auch ohne Angabe solcher Daten bzw.
          unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet.
        </p>

        <p>
          5. Rechtswirksamkeit dieses Haftungsausschlusses Dieser
          Haftungsausschluss ist als Teil des Internetangebotes zu betrachten,
          von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder
          einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht,
          nicht mehr oder nicht vollständig entsprechen sollten, bleiben die
          übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit
          davon unberührt.
        </p>

        <p>
          6. Hinweis gemäß §36 Verbraucherstreitbeilegungsgesetz (VSBG): Der
          Tanzschulbetreiber wird nicht an einem Streitbeilegungsverfahren vor
          einer Verbraucherschlichtungsstelle im Sinne des VSBG teilnehmen und
          ist hierzu auch nicht verpflichtet.
        </p>

        Fotonachweis Istock photo: Luca di Filippo, Anna Bryukhanova
      </div>
    </div>
  </div>
</div>
