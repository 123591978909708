import { Component, OnInit } from "@angular/core";
import { AgeClass } from "../../models/ageClass";
import { News } from "../../models/news";
import { School } from "../../models/school";
import { AgeClassService } from "../../services/age-class.service";
import { NewsService } from "../../services/news.service";
import { SchoolService } from "../../services/school.service";
import { EventListComponent } from "../../event/event-list/event-list.component";
import { NgFor } from "@angular/common";
import { RouterLink } from "@angular/router";
import { PictureComponent } from "../../shared/picture/picture.component";

@Component({
    selector: "ts-routing-event-single-page",
    templateUrl: "./routing-event-single-page.component.html",
    styleUrls: ["./routing-event-single-page.component.css"],
    standalone: true,
    imports: [
        PictureComponent,
        RouterLink,
        NgFor,
        EventListComponent,
    ],
})
export class RoutingEventSinglePageComponent implements OnInit {
  loading: boolean = false;
  ageClasses: AgeClass[] = [];
  school: School = new School();
  firstNews: News = new News();

  constructor(
    private ageClassSrv: AgeClassService,
    private schoolSrv: SchoolService,
    private newsSrv: NewsService
  ) {}

  ngOnInit() {
    this.loading = true;
    this.ageClassSrv.findAll().subscribe((ageClasses) => {
      this.ageClasses = ageClasses.splice(0, 3);
      this.loading = false;
    });

    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
      this.newsSrv.bySchoolId(this.school.id).subscribe((news) => {
        this.firstNews = news[0];
      });
    });
  }
}
