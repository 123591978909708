import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConvService } from './conv.service';
import { TsHttpService } from './ts-http.service';

export class CrudService<T> {

  public dbTable: string;

  constructor(
    public httpSrv: TsHttpService,
    private root: string,
    private type: (new () => T),
    public convServ: ConvService
  ) { }

  findAll(): Observable<T[]> {
    return this.httpSrv.get<T[]>(`${this.root}`).pipe(
      map(elems => this.convServ.convert2TypedArray<T>(elems, this.type))
    );
  }

  findSubItems(id: number, suffix: string): Observable<any[]> {
    return this.httpSrv.get<any[]>(`${this.root}/${id}/${suffix}`);
  }


  findById(id: number): Observable<T> {
    return this.httpSrv.get<T>(`${this.root}/${id}`).pipe(
      map(elem => Object.assign(new this.type(), elem))
    );
  }

  findBySchool(schoolId: number): Observable<T[]> {
    return this.httpSrv.get<T[]>(`schools/${schoolId}/${this.root}`).pipe(
      map(elems => this.convServ.convert2TypedArray<T>(elems, this.type))
    );
  }

  findOthers(id: number): Observable<T[]> {
    return this.httpSrv.get<T[]>(`${this.root}/${id}/others`).pipe(
      map(elems => this.convServ.convert2TypedArray<T>(elems, this.type))
    );
  }

  addRef(id: number, refService: any, refId: number) {
    return this.httpSrv.put<T>(`${this.root}/${id}/add/${refService.root}/${refId}`, null).pipe(
      map(elem => Object.assign(new this.type(), elem))
    );
  }

  removeRef(id: number, refService: any, refId: number) {
    return this.httpSrv.put<T>(`${this.root}/${id}/remove/${refService.root}/${refId}`, null).pipe(
      map(elem => Object.assign(new this.type(), elem))
    );
  }

  update(id: number, t: T): Observable<T> {
    return this.httpSrv.put<T>(`${this.root}/${id}`, t).pipe(
      map(elem => Object.assign(new this.type(), elem))
    );
  }

  create(t: T): Observable<T> {
    return this.httpSrv.post<T>(`${this.root}`, t).pipe(
      map(elem => Object.assign(new this.type(), elem))
    );
  }

  delete(id: number): Observable<void> {
    return this.httpSrv.delete<void>(`${this.root}/${id}`);
  }
}
