import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Page } from '../models/page';
import { ConvService } from './conv.service';
import { CrudService } from './crud.service';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class PageService extends CrudService<Page> {

  static prefix = 'pages';
  dbTable = 'page';

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService,
  ) {
    super(httpSrv, PageService.prefix, Page, convSrv);
  }

  findByTitleUrl(titleUrl: string): Observable<Page> {
    return this.httpSrv.get(`${PageService.prefix}/titleUrl/${titleUrl}`).pipe(
      map(page => Object.assign(new Page(), page))
    );
  }
}
