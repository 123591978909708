<div class="mt-2 overflow-hidden">
  <span class="text-sm font-medium text-gray-700">{{column.prettyName}}<span *ngIf="column.required">*</span></span>
  <div class="flex items-center gap-2">
    <div class="flex flex-col">
      <a *ngIf="column.data" [href]="assetsRoot + column.data" target="_blank"
        class="flex flex-col gap-4 items-center justify-center bg-orangeLight text-white rounded-md h-36 w-80">
        <div class="flex gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-20 h-20">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
          </svg>
        </div>
        <a target="_blank" [href]="assetsRoot + column.data" class="text-sm flex gap-1 items-center">
          <span>Datei ansehen</span>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
            stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round"
              d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
          </svg>

        </a>
      </a>
      <div *ngIf="!column.data" class="text-sm"
        class="flex items-center justify-center bg-orangeLight text-white rounded-md h-36 w-80">
        <span class="text-sm">Keine Datei vorhanden.</span>
      </div>
      <div [style]="'width: ' + uploadProgress * 320 + 'px'" class="mt-1 h-1 bg-orange rounded-full"></div>
    </div>

    <form class="flex flex-col items-start gap-2.5">
      <button *ngIf="column.data" type="button" (click)="column.data = null"
        class="rounded-full flex items-center gap-1 px-3 py-1.5 text-orangeLight bg-white border border-orangeLight hover:text-orange shadow-md text-sm font-bold">
        <mat-icon>delete</mat-icon>
        <span>Datei löschen</span>
      </button>
      <label [for]="column.dbName"
        class="rounded-full flex items-center gap-1 px-3 py-1.5 text-orangeLight bg-white border border-orangeLight hover:text-orange shadow-md text-sm font-bold">
        <mat-icon>file_upload</mat-icon>
        <span *ngIf="!column.data">Datei hochladen</span>
        <span *ngIf="column.data">Datei austauschen</span>
      </label>
      <input class="hidden" [id]="column.dbName" placeholder="Datei hochladen" [name]="column.dbName" type="file"
        (change)="onChange($event)" />
    </form>
  </div>
</div>

<!-- {{column.prettyName}}<span *ngIf="column.required">*</span>
<div>
  <small *ngIf="!column.data"><br />Keine Datei vorhanden<br /></small>
  <br />
  <a target="_blank" *ngIf="column.data" [href]="assetsRoot + column.data">Datei ansehen</a>
  <br />
  <br />
  <button *ngIf="column.data" type="button" (click)="column.data = null" style="margin-left: 0!important;"
    class="rounded-full px-3 py-1.5 bg-orange hover:bg-orangeHover shadow-md text-white text-sm font-bold">
    <mat-icon>delete</mat-icon>
    Datei löschen
  </button>
  <form>
    <input placeholder="Datei hochladen" name="file" type="file" (change)="onChange($event)">
  </form>
</div>
 -->
