<div class="max-w-6xl mx-auto mb-4">
  <div [@routerTransition]="" class="mx-2">

    <div class="flex-1">
      <div class="mt-4">
        <h1 class="font-bold text-3xl">
          {{ school.has_hall_rental ? "Die Säle" : "Die Tanzschule" }} in
          {{ school.city }}
        </h1>
        <div class="text-sm prose mt-2">
          <p>
            Herzlich willkommen in unserer Tanzschule im Herzen von
            {{ school.city }}.<br />
            Wir haben mehrere Säle, ausgestattet mit moderner Lich- und
            Tontechnik, in denen wir das Tanzen unterrichten.<br />
            Vor dem Kurs, in den Pausen oder nach dem Kurs bietet unsere Bar
            ausreichend Platz zum Verweilen und Genießen.
          </p>
          <p>Wir freuen und auf euch!</p>
          <p *ngIf="school.has_hall_rental">
            Heißen Sie Ihre Gäste in unseren Räumlichkeiten herzlich
            willkommen. Wir sind gerne bereit, Ihnen bei der Organisation
            Ihres Festes unter die Arme zu greifen, um Ihre Feier zu einem
            unvergesslichen Abend werden zu lassen.
          </p>
          <p *ngIf="school.has_hall_rental">
            Selbstverständlich stehen wir Ihnen auch gerne zur Seite, wenn Sie
            Ihr Fest auswärts durchführen möchten und Hilfe bei der
            Organisation benötigen. Durch starke Kontakte zu renommierten
            Firmen aus den Bereichen PR, Catering, Verleih und
            Event-Organisation wird Ihre Veranstaltung noch lange Zeit ein
            interessantes Gesprächsthema bleiben. Für nähere Details möchten
            wir Sie gerne zu einem persönlichen Gespräch einladen.
            <br />
            <a *ngIf="school.has_hall_rental" [href]="'mailto:' + school.e_mail">{{ school.e_mail }}</a>
          </p>
        </div>
      </div>
    </div>
    <div class="mt-6" *ngFor="let room of rooms">
      <ts-room-elem [room]="room"></ts-room-elem>
    </div>
  </div>
</div>
