<div class="relative h-exceptMenu top-menu flex">
  <div class="bg-white h-full flex flex-col">
    <div class="mt-5 flex-1 h-0 overflow-y-auto">
      <nav class="px-2 space-y-1">
        <div class="mt-8">
          <h3 class="
              px-3
              text-xs
              font-semibold
              text-gray-500
              uppercase
              tracking-wider
            " id="projects-headline">
            Livestreams
          </h3>
          <div class="mt-1 space-y-1" aria-labelledby="projects-headline">
            <a [routerLink]="[{ outlets: { portal: ['live'] } }]" class="
                group
                flex
                items-center
                px-3
                py-2
                text-sm
                font-medium
                text-gray-600
                rounded-md
                hover:text-gray-800 hover:bg-gray-50
              ">
              <span class="truncate">Live</span>
            </a>
          </div>
        </div>
        <div class="mt-8">
          <h3 class="
              px-3
              text-xs
              font-semibold
              text-gray-500
              uppercase
              tracking-wider
            " id="projects-headline">
            Videos
          </h3>
          <div class="mt-1 space-y-1" aria-labelledby="projects-headline">
            <a *ngFor="let videoCat of videoCats" [routerLink]="[{ outlets: { portal: [videoCat.id] } }]" class="
                group
                flex
                items-center
                px-3
                py-2
                text-sm
                font-medium
                text-gray-600
                rounded-md
                hover:text-gray-800 hover:bg-gray-50
              ">
              <span class="truncate">{{ videoCat.name_pretty }}</span>
            </a>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <main class="flex-1 relative overflow-y-auto focus:outline-none bg-gray-200">
    <router-outlet name="portal"></router-outlet>
  </main>
</div>
