<div class="py-6">
  <div class="max-w-6xl mx-auto px-4 sm:px-6 md:px-8">
    <div class="bg-orange p-6 rounded-lg shadow-none flex justify-between">
      <div>
        <h1 class="mt-2 text-4xl font-semibold text-white">Livestreams</h1>
        <p class="pt-2 text-white">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic nam quae
          sint, soluta impedit ipsa adipisci iure praesentium voluptate deserunt
          doloribus, aspernatur iusto minima sapiente. Minus temporibus quis
          excepturi error?
        </p>
      </div>
    </div>
    <div class="grid grid-cols-3 mt-6 gap-6">
      <a
        *ngFor="let livestream of livestreams"
        [href]="livestream.url"
        target="_blank"
      >
        <div
          class="bg-orangeLight p-4 shadow-none rounded-lg flex flex-col justify-center items-center"
        >
          <h3 class="text-white text-xl font-semibold">
            {{ livestream.name }}
          </h3>
          <img [src]="livestream.logo" alt="" />
        </div>
      </a>
    </div>
  </div>
</div>
