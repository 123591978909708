<form [formGroup]="formGroup" class="personalDataForm">
  <div>
    <!-- Anrede -->
    <mat-form-field class="w-full">
      <mat-select placeholder="Anrede" formControlName="gender">
        <mat-option
          value="Frau"
          [disabled]="startDate && startDate.is_full_for_f === 1"
        >
          Frau
          <span *ngIf="startDate && startDate.is_full_for_f === 1"
            >(Für Mädchen leider ausgebucht!)</span
          >
        </mat-option>
        <mat-option
          value="Herr"
          [disabled]="startDate && startDate.is_full_for_m === 1"
        >
          Herr
          <span *ngIf="startDate && startDate.is_full_for_m === 1"
            >(Für Jungs leider ausgebucht!)</span
          >
        </mat-option>
      </mat-select>
      <mat-error *ngIf="formGroup?.get('gender').hasError('required')">
        Die Anrede ist ein <strong>Pflichtfeld</strong>.
      </mat-error>
    </mat-form-field>

    <br />

    <!-- first name -->
    <mat-form-field class="w-1/2">
      <input
        type="text"
        matInput
        placeholder="Vorname"
        formControlName="first_name"
      />
      <mat-icon class="hidden sm:inline" matSuffix>person</mat-icon>
      <mat-error *ngIf="formGroup?.get('first_name').hasError('required')">
        Der Vorname ist ein <strong>Pflichtfeld</strong>.
      </mat-error>
    </mat-form-field>

    <!-- last name -->
    <mat-form-field class="w-1/2">
      <input
        type="text"
        matInput
        placeholder="Nachname"
        formControlName="last_name"
      />
      <mat-icon class="hidden sm:inline" matSuffix>person</mat-icon>
      <mat-error *ngIf="formGroup?.get('last_name').hasError('required')">
        Der Nachname ist ein <strong>Pflichtfeld</strong>.
      </mat-error>
    </mat-form-field>

    <br />

    <!-- birthday -->
    <mat-form-field class="w-1/2">
      <input
        type="text"
        matInput
        placeholder="Geburtsdatum"
        formControlName="birthday"
      />
      <mat-icon class="hidden sm:inline" matSuffix>cake</mat-icon>
      <mat-error *ngIf="formGroup?.get('birthday').hasError('required')">
        Das Geburtsdatum ist ein <strong>Pflichtfeld</strong>.
      </mat-error>
    </mat-form-field>

    <br />

    <mat-divider></mat-divider>

    <!-- street -->
    <mat-form-field class="w-1/2">
      <input
        type="text"
        matInput
        placeholder="Straße"
        formControlName="street"
      />
      <mat-icon class="hidden sm:inline" matSuffix>location_city</mat-icon>
      <mat-error *ngIf="formGroup?.get('street').hasError('required')">
        Die Adresse ist ein <strong>Pflichtfeld</strong>.
      </mat-error>
    </mat-form-field>

    <!-- streetNo -->
    <mat-form-field class="w-1/2">
      <input
        type="text"
        matInput
        placeholder="Hausnummer"
        formControlName="streetNo"
      />
      <mat-icon class="hidden sm:inline" matSuffix>location_city</mat-icon>
      <mat-error *ngIf="formGroup?.get('streetNo').hasError('required')">
        Die Adresse ist ein <strong>Pflichtfeld</strong>.
      </mat-error>
      <mat-error *ngIf="formGroup?.get('streetNo').hasError('maxlength')">
        Die Hausnummer darf maximal <strong>10 Zeichen</strong> lang sein.
      </mat-error>
    </mat-form-field>

    <br />

    <!-- plz -->
    <mat-form-field class="w-1/2">
      <input
        type="text"
        matInput
        placeholder="Postleitzahl"
        formControlName="plz"
      />
      <mat-icon class="hidden sm:inline" matSuffix>location_city</mat-icon>
      <mat-error *ngIf="formGroup?.get('plz').hasError('required')">
        Die Adresse ist ein <strong>Pflichtfeld</strong>.
      </mat-error>
      <mat-error
        *ngIf="
          formGroup?.get('plz').hasError('minlength') ||
          formGroup?.get('plz').hasError('maxlength')
        "
      >
        Die Postleitzahl muss genau <strong>fünf Zeichen</strong> lang sein.
      </mat-error>
    </mat-form-field>

    <!-- city -->
    <mat-form-field class="w-1/2">
      <input type="text" matInput placeholder="Stadt" formControlName="city" />
      <mat-icon class="hidden sm:inline" matSuffix>location_city</mat-icon>
      <mat-error *ngIf="formGroup?.get('city').hasError('required')">
        Die Adresse ist ein <strong>Pflichtfeld</strong>.
      </mat-error>
    </mat-form-field>

    <br />

    <!-- email -->
    <mat-form-field class="w-1/2">
      <input
        type="text"
        matInput
        placeholder="E-Mail"
        formControlName="email"
      />
      <mat-icon class="hidden sm:inline" matSuffix>mail</mat-icon>
      <mat-error *ngIf="formGroup?.get('email').hasError('required')">
        Die E-Mail ist ein <strong>Pflichtfeld</strong>.
      </mat-error>
      <mat-error *ngIf="formGroup?.get('email').hasError('email')">
        Bitte geben Sie eine gültige E-Mail-Adresse ein.
      </mat-error>
    </mat-form-field>

    <!-- phone -->
    <mat-form-field class="w-1/2">
      <input
        type="text"
        matInput
        placeholder="Telefon"
        formControlName="telephone"
      />
      <mat-icon class="hidden sm:inline" matSuffix>phone</mat-icon>
      <mat-error *ngIf="formGroup?.get('telephone').hasError('required')">
        Die Telefonnummer ist ein <strong>Pflichtfeld</strong>.
      </mat-error>
    </mat-form-field>
  </div>
</form>
