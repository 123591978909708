<div class="max-w-6xl mx-auto mt-4">
  <div class="flex flex-col items-center gap-5" *ngIf="course.isDisabled()">
    <h1 class="font-bold text-3xl">Für {{ course.description }} anmelden</h1>
    Der ausgewählte Kurs ist leider ausgebucht.
    <button [routerLink]="['/kurse', course.courseLevel.ageClass.nameUrl]"
     class="px-2 py-1 rounded-full shadow-md bg-orange text-white text-xs sm:text-sm md:text-base md:py-1.5 md:px-3">Zurück</button>
  </div>
  <div *ngIf="!course.isDisabled()" [@routerTransition]="">
    <h1 class="font-bold text-3xl">Für {{ course.description }} anmelden</h1>
    <ts-course-data
      [courseId]="course.id"
      [startDateId]="startDateId"
    ></ts-course-data>
    <mat-expansion-panel
      class="panel"
      hideToggle
      *ngIf="course?.courseLevel?.long_text"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong>Mehr Infos zu {{ course?.courseLevel.description }} </strong>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <h2 style="margin-bottom: 0">{{ course?.courseLevel.description }}</h2>
      <p style="margin-top: 5px">
        <i
          *ngIf="!course?.courseLevel?.dance"
          class="whitespace-pre-line"
        >{{course?.courseLevel.text}}</i>
        <i
          *ngIf="course?.courseLevel?.dance && course?.courseLevel?.dance.id"
          [innerHtml]="course?.courseLevel?.dance.text"
        ></i>
      </p>
      <p [innerHtml]="course?.courseLevel.long_text"></p>
    </mat-expansion-panel>
    <div class="mx-2">
      <ts-course-checkin-stepper
        [startDateId]="startDateId"
        [courseId]="course.id"
      ></ts-course-checkin-stepper>
    </div>
  </div>
</div>
