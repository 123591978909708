<div class="max-w-6xl mx-auto">
  <div [@routerTransition]="" class="mt-4 mx-2">
    <h2 class="font-bold text-3xl">Datenschutzerklärung tanzspass.com-App</h2>

    <div style="margin-top: 1em">
      Im Rahmen der Nutzung dieser App werden personenbezogenen Daten von Ihnen
      durch uns als den für die Datenverarbeitung Verantwortlichen verarbeitet
      und gespeichert.<br />
      Im Folgenden informieren wir Sie darüber, um welche Daten es sich dabei
      handelt, auf welche Weise sie verarbeitet werden und welche Rechte Ihnen
      diesbezüglich zustehen.<br />
      Personenbezogene Daten sind gemäß Art. 4 Nr. 1 Datenschutzgrundverordnung
      (DSGVO) alle Informationen, die sich auf eine identifizierte oder
      identifizierbare natürliche Person beziehen.
    </div>
    <h3>1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen</h3>
    <p>
      Diese Datenschutzinformation gilt für die Datenverarbeitung im Rahmen der
      Nutzung unserer tanzspass.com-App durch den Verantwortlichen:
    </p>

    <p>
      {{ school.company_name }}<br />
      {{ school.street }}<br />
      {{ school.plz }} {{ school.city }}<br />
      <br />
      Telefon: {{ school.telephone }}<br />
      E-Mail: {{ school.e_mail }}<br /><br />

      Sie können sich jederzeit bei Fragen zum Datenschutzrecht oder Ihren
      Betroffenenrechten direkt an uns wenden.
    </p>

    <h3>2. Berechtigungen der tanzspass.com-App</h3>
    <p>
      tanzspass.com benötigt für Android und iOS folgende Berechtigungen:<br />
    </p>
    <ol>
      <li>
        INTERNET (Daten aus dem Internet abrufen/Zugriff auf alle Netzwerke):
        Wird benötigt um die Videodaten zu laden und Sie über das Anmeldesystem
        an Kursen an- und abzumelden.
      </li>
    </ol>
    <h3>3. Einbindung von externen Videos</h3>
    <p>
      Wir binden in unserer App YouTube-Videos über die YouTube-API ein. Damit
      die Videos angezeigt werden können, wird Ihre IP-Adresse die YouTube-API
      übermittelt. Die Einbindung erfolgt auf Grundlage des Art. 6 Abs. 1 S. 1
      lit. f DSGVO.
    </p>
    <p>
      Weitere Informationen zum Datenschutz im Zusammenhang mit YouTube finden
      Sie hier:<br />
      <a href="https://policies.google.com/privacy?hl=de"
        >https://policies.google.com/privacy?hl=de</a
      >
    </p>
    <p>
      Die Einbindung externer Videodienste muss aktiv hinzugeschaltet werden und
      ist bei der Installation erst einmal ausgeschaltet.
    </p>
    <p>
      Eine Erhebung, Verarbeitung und Weitergabe von personenbezogenen Daten
      kommt darüber hinaus allenfalls in Betracht, wenn
    </p>
    <ul>
      <li>
        Sie gem. Art. 6 Abs. 1 S. 1 lit. a DSGVO Ihre ausdrückliche Einwilligung
        dazu erteilt haben, sowie
      </li>
      <li>
        für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c
        DSGVO eine gesetzliche Verpflichtung besteht.
      </li>
    </ul>
    <h3>4. Auswertung von Nutzungsdaten</h3>
    <p>
      Im Rahmen der App werden von uns mit Ausnahme von Benutzername und
      Passwort keine Nutzungsdaten erhoben, weiterverarbeitet und gespeichert.
    </p>
    <h3>5. Weitergabe von Daten an Dritte</h3>
    <p>
      Daten, die bei der Nutzung der tanzspass.com-App protokolliert worden
      sind, werden grundsätzlich an Dritte nur übermittelt, soweit dies
      gesetzlich vorgeschrieben, durch eine Gerichtsentscheidung festgelegt oder
      die Weitergabe im Falle von Angriffen auf die Internetinfrastruktur des
      {{ school.company_name }} zur Rechts- oder Strafverfolgung erforderlich
      ist. Eine Weitergabe zu anderen nichtkommerziellen oder zu kommerziellen
      Zwecken erfolgt nicht.
    </p>
    <h3>6. Verlinkungen zu Webseiten/Apps anderer Anbieter</h3>
    <p>
      Die tanzspass.com-App enthält Verlinkungen auf Tanzzeit-Videos, die über
      YouTube bereitgestellt werden, sowie Verlinkungen auf Spotify-Songs und
      Spotify-Playlisten, die über Spotify bereitgestellt werden. Wir haben
      keinen Einfluss darauf, dass diese Anbieter die Datenschutzbestimmungen
      einhalten. Wir bitten Sie daher, sich ggf. bei diesen Anbietern über
      dessen Datenschutzpraxis zu informieren.
    </p>
    <h3>7. Betroffenenrechte</h3>
    <p>Sie haben das Recht:</p>
    <ul>
      <li>
        gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit
        gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die
        Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft
        nicht mehr fortführen dürfen;
      </li>
      <li>
        gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In
        der Regel können Sie sich hierfür an die Aufsichtsbehörde ihres üblichen
        Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes
        wenden.
      </li>
    </ul>
    <h3>8. Aktualität und Änderung dieser Datenschutzerklärung</h3>
    <p>
      Diese Datenschutzinformation ist aktuell gültig und hat den Stand März
      2020. Durch die Weiterentwicklung dieser App kann es notwendig sein diese
      Datenschutzinformation zu ändern.
    </p>
  </div>
</div>
