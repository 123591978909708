
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TsHttpService } from './ts-http.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerAuthService {

  static prefix = 'schools';

  constructor(private http: TsHttpService) { }

  public check(schoolId: number, pw: string): Observable<boolean> {
    return this.http
      .get<boolean>(`${CustomerAuthService.prefix}/${schoolId}/auth/${pw}`);
  }
}
