import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Backend } from '../../models/backend';
import { School } from '../../models/school';
import { AuthService } from '../../services/auth.service';
import { BackendService } from '../../services/backend.service';
import { SchoolService } from '../../services/school.service';
import { TimeService } from '../../services/time.service';
import { SchoolLinksToolbarComponent } from '../../school/school-links-toolbar/school-links-toolbar.component';
import { NgIf } from '@angular/common';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { CourseToolbarMenuComponent } from '../../course/course-toolbar-menu/course-toolbar-menu.component';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ts-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.css'],
    standalone: true,
    imports: [MatToolbar, MatIcon, RouterLink, CourseToolbarMenuComponent, MatMenuTrigger, MatMenu, MatMenuItem, NgIf, SchoolLinksToolbarComponent]
})
export class ToolbarComponent implements OnInit {

  @Output() toggledSidebar = new EventEmitter<boolean>();

  public backends: Backend[];
  public school: School = new School();
  public now: Number = TimeService.now.getFullYear();
  isAuthenticated = false;

  constructor(private schoolSrv: SchoolService, private authSrv: AuthService, private backendSrv: BackendService) { }

  toggleSidebar() {
    this.toggledSidebar.emit(true);
  }

  onLogout() {
    this.authSrv.logout();
  }

  ngOnInit() {
    this.schoolSrv.current().subscribe((school: School) => {
      this.school = school;
    });

    this.authSrv.school.subscribe(school => {
      this.isAuthenticated = !!school;

      if (this.isAuthenticated) {
        this.backendSrv.findAll().subscribe(backends => {
          this.backends = backends.filter(backend => backend.isOwnTable);
        });
      }
    });

  }

}
