import { ConvService } from '../services/conv.service';
import { DbObj } from './dbObj';

export class Page implements DbObj {
    id: number;
    title: string;
    titleUrl: string;
    text: string;
    icon: string;
    img: string;
    fk_school: number;

    get prettyName() {
        return this.title;
    }

    get subtitle() {
        return this.text ? ConvService.truncate(this.text, 30) : '';
    }
}
