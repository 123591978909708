<div class="max-w-6xl mx-auto mt-2">
  <h1 class="font-bold text-2xl text-center">Kundenbereich</h1>
  <ng-container *ngIf="this.isAuthed">
    <div class="mt-4 hidden xs:block" style="display: flex; flex-direction: row">
      <nav mat-tab-nav-bar style="flex-grow: 1" [tabPanel]="tabPanel">
        <a mat-tab-link [active]="" [routerLink]="['/kundenbereich', 'videos']">
          <mat-icon>&#xe02c;</mat-icon>
          Mediathek
        </a>
        <!--<a
          mat-tab-link
          *ngIf="school?.has_gallery"
          [routerLink]="['/kundenbereich', 'galerie']"
        >
          <mat-icon>&#xe410;</mat-icon>
          Fotogalerie
        </a>-->
        <a mat-tab-link [routerLink]="['/kundenbereich', 'kursprogramm']">
          <mat-icon>&#xe415;</mat-icon>
          Kursprogramm
        </a>
        <a mat-tab-link [routerLink]="['/kundenbereich', 'info']">
          <mat-icon>info</mat-icon>
          Aktuelle Info
        </a>
      </nav>
      <button class="bg-orange px-6 py-1 text-white rounded-full items-center shadow-lg hidden sm:flex"
        (click)="logout()">
        <mat-icon>logout</mat-icon>
        Abmelden
      </button>
    </div>
    <mat-tab-nav-panel #tabPanel>
      <router-outlet></router-outlet>
    </mat-tab-nav-panel>
  </ng-container>
  <div style="text-align: center" *ngIf="!isAuthed" [@routerTransition]="" (keyup)="onKey($event, tmpPw)">
    <div class="flex flex-col items-center justify-center">
      <div>Bitte geben Sie das Passwort für den Kundenbereich ein:</div>
      <div class="flex space-x-4 mt-4 items-center">

        <div>
          <input placeholder="Passwort" type="password" id="password" name="column.dbName" [(ngModel)]="tmpPw"
            class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
        </div>

        <button (click)="auth(tmpPw)"
          class="bg-orange gap-1 px-3 py-2 text-white rounded-full flex items-center shadow-lg">
          <mat-icon>login</mat-icon>
          <span>Anmelden</span>
        </button>
      </div>
    </div>
    <br />
    <small>Sollten Sie das aktuelle Passwort nicht wissen, können Sie es jederzeit
      telefonisch bei uns erfragen: {{ school.telephone }}</small>
  </div>
</div>
