import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Dance } from "../models/dance";
import { School } from "../models/school";
import { Video } from "../models/video";
import { VideoCategory } from "../models/videoCategory";
import { ConvService } from "./conv.service";
import { CrudService } from "./crud.service";
import { SchoolService } from "./school.service";
import { TsHttpService } from "./ts-http.service";

@Injectable({
  providedIn: "root",
})
export class VideoService extends CrudService<Video> {
  static prefix = "videos";
  static prefix2 = "videoCategories";
  dbTable = "video";

  constructor(
    public httpSrv: TsHttpService,
    public convSrv: ConvService,
    public schoolSrv: SchoolService
  ) {
    super(httpSrv, VideoService.prefix, Video, convSrv);
  }

  public years() {
    return this.httpSrv.get<any[]>(`${VideoService.prefix}/years`).pipe(
      map((result) => {
        return result.map((year) => year["year"]);
      })
    );
  }

  public categories(school: School) {
    return this.httpSrv
      .get<VideoCategory[]>(`schools/${school.id}/${VideoService.prefix2}`)
      .pipe(
        map((categories) =>
          this.convSrv.convert2TypedArray<VideoCategory>(
            categories,
            VideoCategory
          )
        )
      );
  }

  public findByCategory(videoCatId: number) {
    return this.httpSrv
      .get<Video[]>(`videoCategories/${videoCatId}/videos`)
      .pipe(
        map((videos) => this.convSrv.convert2TypedArray<Video>(videos, Video))
      );
  }

  public filter(
    school: School,
    pw: string,
    year: number,
    dance: Dance,
    category: VideoCategory
  ): Observable<Video[]> {
    const danceStr = "";
    const catID = category ? category.id : 0;
    const danceID = dance ? dance.id : 0;

    const params = new URLSearchParams();

    if (year > 2000) {
      params.set("year", year.toString());
    }

    if (catID > 0) {
      params.set("categoryId", catID.toString());
    }

    if (danceID > 0) {
      params.set("danceId", danceID.toString());
    }

    return this.httpSrv
      .get<Video[]>(
        `${VideoService.prefix}/filter?${params.toString()}`
      )
      .pipe(
        map((videos) => this.convSrv.convert2TypedArray<Video>(videos, Video))
      );
  }
}
