import { Injectable } from "@angular/core";
import { ConvService } from "../services/conv.service";
import { AgeClass } from "./ageClass";
import { Course } from "./course";
import { Dance } from "./dance";
import { DbObj } from "./dbObj";

@Injectable({
  providedIn: "root",
})
export class CourseLevel implements DbObj {
  public id: number;
  public sort: number;
  public type: string;
  public description: string;
  public description_url: string;
  public description_text: string;
  public text: string;
  public long_text: string;
  public price3: string;
  public price6: string;
  public price12: string;
  public priceComplete: string;
  public pastDays: number;
  public displayDays: number;
  public no_start_dates_text: string;
  public contract_auto_extend: number;
  public courses: Course[];
  public ageClass: AgeClass;
  public img: string;
  public fk_dance: number;
  public dance: Dance;
  private ageClassString: string;
  private fk_ageClass: number;

  constructor() {}

  get prettyName() {
    return `[${
      this.ageClass && this.ageClass.name.slice(0, 3).toUpperCase()
    }] ${this.description}`;
  }

  get subtitle() {
    return this.text ? ConvService.truncate(this.text, 30) : "";
  }
}
