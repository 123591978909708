import { isPlatformBrowser, isPlatformServer, Location } from "@angular/common";
import { forwardRef, Inject, Injectable, Injector, PLATFORM_ID } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Meta, Title } from "@angular/platform-browser";
import { Observable, ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { PageMode } from "../app.component";
import { School } from "../models/school";
import { ConvService } from "./conv.service";
import { CrudService } from "./crud.service";
import { TsHttpService } from "./ts-http.service";

@Injectable({
  providedIn: "root",
})
export class SchoolService extends CrudService<School> {
  static prefix = "schools";
  dbTable = "school";

  private host: string;
  private currentSchool: ReplaySubject<School> = new ReplaySubject(1);
  pageMode: PageMode = PageMode.schools;

  constructor(
    @Inject(forwardRef(() => TsHttpService))
    private http: TsHttpService,
    private location: Location,
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleSrv: Title,
    private metaSrv: Meta,

    @Inject(forwardRef(() => ConvService))
    public convSrv: ConvService,
    private snackBar: MatSnackBar,
    /* @Inject('HOSTNAME') private serverHostname: string, */
  ) {
    super(http, SchoolService.prefix, School, convSrv);
    if (isPlatformServer(this.platformId)) {
      const serverHostname = this.injector.get('HOSTNAME');

      this.host = serverHostname;
    } else {
      this.host = window.location.hostname;
    }
    if (this.host === "www.tanzspass.com") {
      this.pageMode = PageMode.online;
    }

    if (this.host === "tv.tanzspass.com") {
      this.pageMode = PageMode.tv;
    }

    if (this.pageMode === PageMode.online) {
      this.metaSrv.addTag(
        {
          property: "og:title",
          content: "Tanzspass.com | Tanzen im Hamburger Westen",
        },
        true
      );
      this.titleSrv.setTitle("Tanzspass.com | Tanzen im Hamburger Westen");
    }

    if (this.host === "localhost" && !environment.production) {

      // set dev-School
      //this.host = "www.tanzschule-riemer.de";
      this.host = "www.tanzschule-leseberg.de";
      //this.host = "www.tanzzeit-higle.de";

      // enable TV-Mode for dev-environment
      // this.pageMode = PageMode.tv;
    }

    if (this.host === "dev.tanzspass.com") {
      this.pageMode = PageMode.online;
    }

    // Redirections, hopefully not needed because node does that for us
    if (isPlatformBrowser(this.platformId)) {
      // Riemer
      if (
        this.host === "tanzschule-riemer.de" ||
        this.host === "riemer-tanzschule.de" ||
        this.host === "riemer.tanzspass.com"
      ) {
        window.location.href = "https://www.tanzschule-riemer.de";
      }

      // Higle
      if (
        this.host === "tanzzeit-higle.de" ||
        this.host === "higle.tanzspass.com"
      ) {
        window.location.href = "https://www.tanzzeit-higle.de";
      }

      // Leseberg
      if (
        this.host === "tanzschule-leseberg.de" ||
        this.host === "leseberg.tanzspass.com"
      ) {
        window.location.href = "https://www.tanzschule-leseberg.de";
      }

      // Tanzspass
      if (this.host === "tanzspass.com") {
        window.location.href = "https://www.tanzspass.com";
      }
    }
  }

  private othersByUrl(url: string): Observable<School[]> {
    return this.http
      .get<School[]>(`${SchoolService.prefix}/url/${url}/others`)
      .pipe(
        map((schools) =>
          this.convServ.convert2TypedArray<School>(schools, School)
        )
      );
  }

  private byUrl(url: string): Observable<School> {
    return this.http
      .get<School>(`${SchoolService.prefix}/url/${url}`)
      .pipe(map((result) => Object.assign(new School(), result)));
  }

  public getRss(schoolId: number) {
    return this.http.get<any>(`${SchoolService.prefix}/${schoolId}/rss`);
  }

  public current(): Observable<School> {
    if (this.currentSchool.observers.length === 0) {
      this.byUrl(this.host).subscribe(
        (school) => {
          this.currentSchool.next(school);
        },
        (err) => {
          const bar = this.snackBar.open(
            "Es gab ein Problem beim Laden der Seite",
            "Neu laden"
          );
          bar.onAction().subscribe(() => {
            if (isPlatformBrowser(this.platformId)) {
              window.location.reload();
            }
          });
        }
      );
    }
    return this.currentSchool;
  }

  public others(): Observable<School[]> {
    return this.othersByUrl(this.host);
  }
}
