<!-- Singe Pages -->
<ng-container>
  <ts-toolbar
    *ngIf="pageMode === 'schools'"
    (toggledSidebar)="openSidenav()"
  ></ts-toolbar>
  <main
    class="min-h-exceptBothMenus"
    *ngIf="pageMode === 'schools'"
    (click)="closeSidenav()"
  >
    <router-outlet></router-outlet>
  </main>
  <ts-root *ngIf="pageMode === 'online'"></ts-root>
  <ts-infosystem-index *ngIf="pageMode === 'tv'"></ts-infosystem-index>
  <ts-sidenav
    *ngIf="pageMode === 'schools'"
    [open]="sidenavOpen"
    (openedChange)="sidenavOpen = $event"
  ></ts-sidenav>
  <ts-footer *ngIf="pageMode === 'schools'"></ts-footer>
</ng-container>
