<div class="max-w-4xl mx-auto">
  <div class="text-center mt-4 mx-2">
    <h2 class="text-3xl font-extrabold tracking-tight text-gray-800 sm:text-4xl mt-6">
      Kündigung
    </h2>
  </div>
  <div class="mt-12 mx-2">
    <div class="flex flex-col">
      <h2 class="text-lg text-gray-700 font-bold">
        Online-Kündigungsvoraussetzungen
      </h2>
      <div class="text-sm text-gray-500 space-y-1">
        <p>
          Nutze für die Online-Kündigung nach Möglichkeit die E-Mailadresse, mit der du dich auch angemeldet hast.
        </p>
        <p>
          Verträge von Tanzpartnerinnen oder Tanzpartnern können nur mitgekündigt werden, wenn die Anmeldung als Tanzpaar erfolgt ist. Ansonsten müssen die Verträge gesondert voneinander gekündigt werden.
        </p>
        <p>
          Die Kündigung wird in den nächsten Tagen auf der Basis des heutigen Datums bearbeitet und nochmals schriftlich von uns bestätigt.<br>
          Die automatisierte Bestätigung bezieht sich lediglich auf den Eingang der Kündigung und ist keine Kündigungsbestätigung. Wenn du weder eine automatisierte, noch eine schriftliche Bestätigung von uns erhalten hast, überprüfe bitte auch deinen Spamordner oder melde dich telefonisch bei uns in der Tanzschule.
        </p>
        <p>
          Bei allen Kündigungen gelten unsere vertraglichen Fristen, siehe hierfür unter unseren 
          <a class="underline" target="_blank" href="/agb">AGBs</a> nach.
        </p>
      </div>
    </div>
    <form (submit)="onSend()" method="POST" ngNativeValidate
      class="grid mt-6 grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
      <div class="sm:col-span-2">
        <label for="who-cancels" class="block text-sm font-medium text-gray-700">Für wen möchtest du kündigen?</label>
        <div class="mt-1 inline-block relative w-full">
          <select name="who-cancels" id="who-cancels" required [(ngModel)]="cancellation.whoCancels"
            class="py-2 appearance-none px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md">
            <option value="me" selected>für mich</option>
            <option value="partner">für uns</option>
            <option value="child">für mein Kind</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="sm:col-span-2 text-xs font-bold text-gray-500">
        Deine Daten
      </div>
      <div class="-mt-4">
        <label for="first-name" class="block text-sm font-medium text-gray-700">Vorname</label>
        <div class="mt-1">
          <input type="text" name="first-name" id="first-name" autocomplete="given-name" required
            [(ngModel)]="cancellation.firstName"
            class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
        </div>
      </div>
      <div class="sm:-mt-4">
        <label for="last-name" class="block text-sm font-medium text-gray-700">Nachname</label>
        <div class="mt-1">
          <input type="text" name="last-name" id="last-name" autocomplete="family-name" required="required"
            [(ngModel)]="cancellation.lastName"
            class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
        </div>
      </div>

      <div>
        <label for="email" class="block text-sm font-medium text-gray-700">E-Mail</label>
        <div class="mt-1">
          <input id="email" name="email" type="email" autocomplete="email" required [(ngModel)]="cancellation.email"
            class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
        </div>
      </div>
      <div *ngIf="cancellation.whoCancels === 'partner'" class="sm:col-span-2 text-xs font-bold text-gray-500 mt-2">
        Daten deines Partners
      </div>
      <div class="-mt-4" *ngIf="cancellation.whoCancels === 'partner'">
        <label for="first-name-partner" class="block text-sm font-medium text-gray-700">Vorname</label>
        <div class="mt-1">
          <input type="text" name="first-name-partner" id="first-name-partner" required
            [(ngModel)]="cancellation.firstNamePartner"
            class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
        </div>
      </div>
      <div class="sm:-mt-4" *ngIf="cancellation.whoCancels === 'partner'">
        <label for="last-name-partner" class="block text-sm font-medium text-gray-700">Nachname</label>
        <div class="mt-1">
          <input type="text" name="last-name-partner" id="last-name-partner" required="required"
            [(ngModel)]="cancellation.lastNamePartner"
            class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
        </div>
      </div>
      <div *ngIf="cancellation.whoCancels === 'child'" class="sm:col-span-2 text-xs font-bold text-gray-500 mt-2">
        Daten deines Kindes
      </div>
      <div class="-mt-4" *ngIf="cancellation.whoCancels === 'child'">
        <label for="first-name-child" class="block text-sm font-medium text-gray-700">Vorname</label>
        <div class="mt-1">
          <input type="text" name="first-name-child" id="first-name-child" required
            [(ngModel)]="cancellation.firstNameChild"
            class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
        </div>
      </div>
      <div class="sm:-mt-4" *ngIf="cancellation.whoCancels === 'child'">
        <label for="last-name-child" class="block text-sm font-medium text-gray-700">Nachname</label>
        <div class="mt-1">
          <input type="text" name="last-name-child" id="last-name-child" required="required"
            [(ngModel)]="cancellation.lastNameChild"
            class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md" />
        </div>
      </div>
      <div class="sm:col-span-2 text-xs font-bold text-gray-500 mt-2">
        Kündigungsmodalitäten
        <span class="block text-sm font-medium text-gray-700 mt-1.5">Kündigung zum nächstmöglichen Termin</span>
      </div>
      <div class="sm:col-span-2">
        <label for="message" class="block text-sm font-medium text-gray-700">Nachricht</label>
        <div class="mt-1">
          <textarea id="message" name="message" rows="4" [(ngModel)]="cancellation.message"
            class="py-2 px-4 block w-full shadow-sm focus:ring-orange focus:border-orange border border-gray-300 rounded-md"></textarea>
        </div>
        <p class="mt-4 text-sm text-gray-500">
          Schade, dass du uns verlassen willst. Teile uns doch gerne mit, warum
          du dich so entschieden hast und vielleicht können wir etwas
          unternehmen, dass du deine Kündigung später wieder zurückziehst.
        </p>
      </div>
      <div class="sm:col-span-2 mb-2">
        <button type="submit"
          class="inline-flex items-center justify-center px-4 py-1.5 shadow-md font-bold border border-transparent rounded-full text-base text-white bg-orange hover:bg-orangeHover duration-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange">
          Jetzt kündigen
        </button>
      </div>
    </form>
  </div>
</div>
