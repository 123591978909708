import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { CurrencyPipe, isPlatformBrowser, NgFor, NgIf } from "@angular/common";
import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnChanges,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from "@angular/core";
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormField, MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { MatList, MatListItem } from "@angular/material/list";
import { MatRadioButton, MatRadioGroup } from "@angular/material/radio";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatStep, MatStepLabel, MatStepper, MatStepperNext } from "@angular/material/stepper";
import { Router } from "@angular/router";
import * as IBAN from "iban";
import { Course } from "../../models/course";
import { CourseLevel } from "../../models/courseLevel";
import { CourseStartDate } from "../../models/courseStartDate";
import { PaymentMethod } from "../../models/paymentMethod";
import { Person } from "../../models/person";
import { School } from "../../models/school";
import { CourseLevelService } from "../../services/course-level.service";
import { CourseStartDatesService } from "../../services/course-start-dates.service";
import { CourseService } from "../../services/course.service";
import { PaymentMethodService } from "../../services/payment-method.service";
import { SchoolService } from "../../services/school.service";
import { CourseDataFormComponent } from "../course-data-form/course-data-form.component";


export function ibanValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    return IBAN.isValid(control.value) ? null : { iban: { value: control.value } };
  };
}

@Component({
    selector: "ts-course-checkin-stepper",
    templateUrl: "./course-checkin-stepper.component.html",
    styleUrls: ["./course-checkin-stepper.component.css"],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true },
        },
    ],
    standalone: true,
    imports: [
        MatStepper,
        MatStep,
        MatStepLabel,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        MatRadioGroup,
        MatList,
        NgFor,
        MatListItem,
        MatRadioButton,
        MatStepperNext,
        CourseDataFormComponent,
        MatFormField,
        MatInput,
        MatIcon,
        MatSuffix,
        CurrencyPipe,
    ],
})
export class CourseCheckinStepperComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() courseId: number;
  @Input() startDateId: number;
  @ViewChild("stepper", { static: true }) stepper: MatStepper;

  public course: Course = new Course();
  public courseLevel: CourseLevel = new CourseLevel();
  public methods: PaymentMethod[] = new Array();
  public school: School = new School();

  public contractDurations: any[] = [];

  // Step 1
  public startDate: CourseStartDate = new CourseStartDate();
  public startDatesForm: UntypedFormGroup = new UntypedFormGroup({});
  public startDateSelected = false;

  // Step 2
  public person: Person = new Person();
  public partner: Person = new Person();
  public parent: Person = new Person();
  public personForm: UntypedFormGroup = new UntypedFormGroup({});
  public partnerDataForm: UntypedFormGroup;
  public parentDataForm: UntypedFormGroup;

  // Step 3
  public paymentMethodForm: UntypedFormGroup;
  public paymentMethod: PaymentMethod;
  public contractDuration: number;
  public voucher_code = "";

  // Step 4
  public notes = "";
  public contractDetailsForm: UntypedFormGroup;
  public agbAccepted = false;
  public dataProtectionAccepted = false;
  public submitting = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private courseSrv: CourseService,
    private courseLevelSrv: CourseLevelService,
    private paymentSrv: PaymentMethodService,
    private startDateSrv: CourseStartDatesService,
    public snackBar: MatSnackBar,
    public router: Router,
    public schoolSrv: SchoolService,
    /* private recaptchaV3Service: ReCaptchaV3Service, */
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  public onSubmit() {
    this.submitting = true;
    /* this.recaptchaV3Service.execute("register").subscribe((capchaToken) => { */
    this.courseSrv
      .register(
        this.person,
        this.partner,
        this.parent,
        this.notes,
        this.course.id,
        this.startDate.id,
        this.paymentMethod.id,
        this.contractDuration,
        this.voucher_code,
        /* capchaToken */ ""
      )
      .subscribe(
        (data) => {
          this.submitting = false;
          if (data) {
            this.router.navigate(["/gebucht", this.course.id]);
          } else {
            this.snackBar.open(
              `Die Anmeldung konnte leider nicht verschickt werden.`,
              "",
              {
                duration: 10000,
              }
            );
          }
        },
        () => {
          this.submitting = false;
          this.snackBar.open(
            `Die Anmeldung konnte nicht verschickt werden.
        Sollte dieser Fehler bestehen bleiben, melden Sie sich bitte telefonisch unter ${this.school.telephone}. Danke!`,
            "",
            {
              duration: 10000,
            }
          );
        }
      );
    /* }); */
  }

  public courseNeedsParent() {
    return (
      this.courseLevel.ageClass &&
      (this.courseLevel.ageClass.id === 1 || this.courseLevel.ageClass.id === 2)
    );
  }

  public courseHasPartner() {
    return (
      this.courseLevel.ageClass &&
      ((this.courseLevel.ageClass.id === 3 &&
        this.courseLevel.type === "paar") ||
        this.courseLevel.ageClass.id === 4 ||
        this.courseLevel.ageClass.id === 5)
    );
  }

  public setStartDateValidation() {
    // Kurs hat mehr als ein Startdatum
    if (
      this.course.course_start_dates &&
      this.course.course_start_dates.length > 1
    ) {
      this.startDatesForm.controls["startDate"].setValidators([
        Validators.required,
      ]);
      this.startDatesForm.get("startDate").valueChanges.subscribe(() => {
        this.startDate = this.startDatesForm.get("startDate").value;
      });
      this.startDatesForm.controls["startDate"].updateValueAndValidity();
    } else {
      // this.startDatesForm.controls["startDate"].clearValidators();
      //this.startDatesForm.controls["startDate"].updateValueAndValidity();
    }
  }

  public ngOnInit() {
    this.schoolSrv.current().subscribe((school) => {
      this.school = school;
      if (
        this.school.short_name === "higle" ||
        this.school.short_name === "leseberg"
      ) {
        this.contractDurations = [3];
      } else {
        this.contractDurations = [3, 6];
      }
    });

    // Step 1
    this.startDatesForm = this.formBuilder.group({
      startDate: new UntypedFormControl(null),
    });
    this.setStartDateValidation.bind(this);

    // Step 2 in course-data-forms

    // Step 3
    this.paymentMethodForm = this.formBuilder.group({
      contractDuration: new UntypedFormControl(null, null),
      paymentMethod: new UntypedFormControl(null, Validators.required),
      iban: new UntypedFormControl(null),
      bankaccount_owner: new UntypedFormControl(null),
      iban_partner: new UntypedFormControl(null),
      bankaccount_owner_partner: new UntypedFormControl(null),
      voucher_code: new UntypedFormControl(null),
    });
    this.paymentMethodForm
      .get("contractDuration")
      .valueChanges.subscribe(() => {
        this.contractDuration =
          this.paymentMethodForm.get("contractDuration").value;
      });
    this.paymentMethodForm.get("paymentMethod").valueChanges.subscribe(() => {
      this.paymentMethod = this.paymentMethodForm.get("paymentMethod").value;
      if (this.paymentMethod && this.paymentMethod.name === "Lastschrift") {
        this.paymentMethodForm.controls["iban"].setValidators([
          Validators.required, ibanValidator(),
        ]);
        this.paymentMethodForm.controls["iban"].updateValueAndValidity();
      } else {
        this.paymentMethodForm.controls["iban"].clearValidators();
        this.paymentMethodForm.controls["iban"].updateValueAndValidity();
      }
    });
    this.paymentMethodForm.get("voucher_code").valueChanges.subscribe(() => {
      this.voucher_code = this.paymentMethodForm.get("voucher_code").value;
    });
    this.paymentMethodForm.get("iban").valueChanges.subscribe(() => {
      this.person.iban = this.paymentMethodForm.get("iban").value;
    });
    this.paymentMethodForm
      .get("bankaccount_owner")
      .valueChanges.subscribe(() => {
        this.person.bankaccount_owner =
          this.paymentMethodForm.get("bankaccount_owner").value;
      });

    this.paymentMethodForm.get("iban_partner").valueChanges.subscribe(() => {
      this.partner.iban = this.paymentMethodForm.get("iban_partner").value;
    });
    this.paymentMethodForm
      .get("bankaccount_owner_partner")
      .valueChanges.subscribe(() => {
        this.partner.bankaccount_owner = this.paymentMethodForm.get(
          "bankaccount_owner_partner"
        ).value;
      });

    // Step 4
    this.contractDetailsForm = this.formBuilder.group({
      dataProtectionAccepted: new UntypedFormControl(
        null,
        Validators.requiredTrue
      ),
      agbAccepted: new UntypedFormControl(null, Validators.requiredTrue),
      notes: new UntypedFormControl(null),
    });
    this.contractDetailsForm.get("notes").valueChanges.subscribe(() => {
      this.notes = this.contractDetailsForm.get("notes").value;
    });
  }

  ngOnChanges() {
    if (this.courseId !== undefined) {
      this.courseSrv.findById(this.courseId).subscribe((course) => {
        this.course = course;
        this.course.course_start_dates = this.course.course_start_dates.filter(
          (startDate) => {
            return !(startDate.is_full_for_f && startDate.is_full_for_m);
          }
        );
        if (this.course.course_start_dates.length === 1) {
          this.startDate = this.course.course_start_dates[0];
          // this.stepper.selectedIndex = 1;
          this.course.course_start_dates[0].checked = true;
        }
        this.courseLevel = course.courseLevel;
        if (!this.courseLevel.priceComplete) {
          this.paymentMethodForm
            .get("contractDuration")
            .setValidators(Validators.required);
        }

        this.setStartDateValidation();
      });
    }
    this.paymentSrv.findAll().subscribe((methods) => {
      this.methods = methods.filter(method => method.name !== 'Bar');
    });
  }

  ngAfterViewInit() {
    let x = 25;
    if (isPlatformBrowser) {
      const interval = setInterval(() => {
        if (
          this.course.course_start_dates &&
          this.course.course_start_dates.length <= 1
        ) {
          this.stepper.selectedIndex = 1;
          this.startDateSelected = true;
          clearInterval(interval);
        }

        if (this.startDateId) {
          this.startDateSrv
            .findById(this.startDateId)
            .subscribe((startDate) => {
              this.startDate = startDate;
              this.startDateSelected = true;
              this.stepper.selectedIndex = 1;
              this.startDatesForm.get("startDate").setValue(this.startDate);
              clearInterval(interval);
            });
        }
        x--;
        if (x <= 0 || this.stepper.selectedIndex === 1) {
          clearInterval(interval);
        }
      }, 500);
    }
  }
}
