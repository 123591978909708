<div class="max-w-6xl mx-auto">
  <div
    class="
      relative
      shadow-lg
      rounded-2xl
      overflow-hidden
      h-48
      mt-8
      p-8
      mx-2
      sm:mx-0
    "
  >
    <div class="absolute inset-0">
      <ts-picture
        class="object-cover w-full h-full"
        [img]="drinkCategory.img"
        width="100%"
        height="100%"
      >
      </ts-picture>

      <div class="absolute inset-0 bg-gray-400 mix-blend-multiply"></div>
    </div>
    <div class="relative h-full flex flex-row justify-center items-center">
      <h1
        class="
          text-center text-4xl
          font-extrabold
          tracking-tight
          sm:text-5xl
          lg:text-6xl
          text-white
        "
      >
        {{ drinkCategory.name }}
      </h1>
    </div>
  </div>
  <div class="mt-2">
    <div class="flex mt-4 mx-2" *ngFor="let drink of drinkCategory.drinks">
      <div class="text-lg flex-1 whitespace-pre-line">{{drink.name}}</div>
      <div class="flex-1 text-gray-600">{{ drink.size }}</div>
      <div class="flex-1 text-gray-600">{{ drink.price }}</div>
    </div>
  </div>
</div>
