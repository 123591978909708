import { DbObj } from './dbObj';

export class Person implements DbObj {

    public id: number;
    public first_name: string | null;
    public last_name: string | null;
    public gender: string;
    public birthday: string | null;
    public street: string | null;
    public streetNo: string | null
    public plz: string | null;
    public city: string | null;
    public email: string | null;
    public telephone: string | null;
    public iban: string | null;
    public bankaccount_owner: string | null;
    public created: Date;
    public password: string;
    public voucher_code: string;
    public valid: boolean;

    constructor() { }

    get prettyName() {
        return `${this.first_name} ${this.last_name}`;
    }

    get subtitle() {
        return this.email;
    }
}
