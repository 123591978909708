import { NgClass, NgFor, NgIf } from "@angular/common";
import {
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input, OnInit,
  Output
} from "@angular/core";
import { FormsModule, NgForm } from "@angular/forms";
import { MatIcon } from "@angular/material/icon";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { Backend } from "../../models/backend";
import { BackendEditItem } from "../../models/backendEditItem";
import { Column } from "../../models/column";
import { DbObj } from "../../models/dbObj";
import { AuthService } from "../../services/auth.service";
import { BackendEditService } from "../../services/backend-edit.service";
import { BackendService } from "../../services/backend.service";
import { DataFieldLoaderService } from "../../services/data-field-loader.service";
import { ServiceLoaderService } from "../../services/service-loader.service";
import { GenericFormElementComponent } from "./generic-form-element/generic-form-element.component";

@Component({
    selector: "ts-generic-form",
    templateUrl: "./generic-form.component.html",
    styleUrls: ["./generic-form.component.css"],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        RouterLink,
        MatIcon,
        NgClass,
        NgFor,
        forwardRef(() => GenericFormElementComponent),
    ],
    host: {ngSkipHydration: 'true'},
})
export class GenericFormComponent implements OnInit {
  // When no editItem is provided, this component uses the global one from the editSrv
  @Input() editItem: BackendEditItem;
  @Input() isDialog: boolean = false;

  item: any;
  backend: Backend = new Backend();
  service: any;
  injector;
  updating = false;
  backendList: NgForm = new NgForm(null, null);
  isDuplicateMode = false;
  @Output() done: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private editSrv: BackendEditService,
    private loaderSrv: DataFieldLoaderService,
    private snackBar: MatSnackBar,
    private authSrv: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private serviceLoaderSrv: ServiceLoaderService,
    private backendSrv: BackendService,
  ) {
    this.injector = Injector.create({
      providers: [{ provide: Column, deps: [] }],
      parent: this.injector,
    });
  }

  isValidForm() {
    return (
      this.backend &&
      this.backend?.columns &&
      this.backend?.columns?.filter(
        (column) => {
          return column.isVisibleInTable &&
            column.required &&
            column.dataType !== "boolean" &&
            (!column.data || column.data === '')
        }
      ).length === 0
    );
  }

  /* update() {
    this.updating = true;
    const obs = this.editItem ? of(this.editItem) : this.editSrv.item;
    obs.pipe().subscribe((data) => {
      if (data) {
        this.backend = data.backend;
        this.service = data.service;
        this.item = data.item;
        data.backend.columns.forEach((column) => {
          // Edit
          column.data = data.item[column.dbName];
          column.component = this.loaderSrv.load(column.dataType);
        });
        this.columns = data.backend.columns;
      } else {
        this.backend = null;
        this.service = null;
        this.columns = null;
        this.item = null;
      }
      this.updating = false;
    });
  } */


  onDeleteClick() {
    this.service.delete(this.item.id).subscribe({
      next: () => {
        this.editSrv.itemHasBeenEdited.next();
        this.done.emit();
        this.snackBar.open("Erfolgreich gelöscht!", null, {
          duration: 4000,
        });
        if (!this.isDialog) {
          this.router.navigate(['/backend', {
            outlets: {
              backendOutlet: [this.backend.dbName],
              backendItemOutlet: [],
            }
          }]);
        }
      }, error: () => {
        this.snackBar.open("Fehler beim Löschen!", null, {
          duration: 4000,
        });
      }
    },);
  }

  onSave(columns: Column[]) {
    this.item = {};
    columns.forEach((column) => {
      this.item[column.dbName] = column.data;
    });
    if (this.isDuplicateMode) {
      this.item.id = undefined;
    }

    // Create new or update existing element
    if (this.item.id) {
      this.service.update(this.item.id, this.item).subscribe(() => {
        this.snackBar.open("Erfolgreich gespeichert!", null, {
          duration: 4000,
        });
        this.editSrv.itemHasBeenEdited.next();
        this.done.emit();
        this.updateItem(this.item.id);
      });
    } else {
      // set ref to school if necessary, then POST
      this.authSrv.school.subscribe((school) => {
        if (
          this.backend &&
          this.backend.isDependentOnSchool &&
          !this.item.fk_school
        ) {
          this.item.fk_school = school.id;
        }
        this.service.create(this.item).subscribe((data) => {
          this.snackBar.open("Erfolgreich erstellt!", null, {
            duration: 4000,
          });
          this.editSrv.itemHasBeenEdited.next();
          this.done.emit();
          if (!this.isDialog) {
            this.router.navigate(['/backend', {
              outlets: {
                backendOutlet: [this.backend.dbName, data.id],
                backendItemOutlet: [this.backend.dbName, data.id],
              }
            }]);
          }
        });
      });
    }
  }

  updateItem(itemId: number) {
    this.service.findById(itemId).subscribe(item => {
      if (item.id) {
        this.item = item;
        this.backend?.columns?.forEach((column) => {
          column.data = item[column.dbName];
          column.component = this.loaderSrv.load(column.dataType);
        });
      }
    });
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      if (this.isDialog) {
        this.isDuplicateMode = false;
        this.service = this.serviceLoaderSrv.load(this.editItem.backend.dbName);
      } else {
        this.isDuplicateMode = this.route.snapshot.url[1]?.toString() === 'from';
        this.service = this.serviceLoaderSrv.load(params.serviceName);
      }
      this.backendSrv.findByName(this.service.dbTable).subscribe((backend) => {
        this.backend = backend;

        if (params.itemId) {
          this.updateItem(params.itemId);
        } else if (this.route.snapshot.url?.length > 0
          && this.route.snapshot.url[this.route.snapshot.url.length - 1].toString() === 'new' || this.isDialog) {
          this.item = new DbObj();
        } else {
          this.item = undefined;
        }

        if (this.editItem && this.isDialog) {
          this.item = Object.assign(this.item, this.editItem.item);
          this.backend.columns.forEach((column) => {
            // Edit
            column.data = this.item[column.dbName];
            column.component = this.loaderSrv.load(column.dataType);
          });
        }

      });
    });
  }
}
