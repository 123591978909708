import { Component, EventEmitter, Input, Output } from "@angular/core";
import * as dayjs from "dayjs";
import { Column } from "../../../models/column";
import { FormsModule } from "@angular/forms";
import { NgIf, DatePipe } from "@angular/common";

@Component({
    selector: "ts-time-field",
    templateUrl: "./time-field.component.html",
    styleUrls: ["./time-field.component.css"],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        DatePipe,
    ],
})
export class TimeFieldComponent {
  private _column: Column;

  get column(): Column {
    return this._column;
  }

  @Input() set column(val: Column) {
    this._column = val;
    if (!isNaN(new Date(this._column.data).getTime())) {
      this.time = new Date(this._column.data).toISOString().slice(11, 16);
    }
  }

  @Output() columnChange = new EventEmitter();
  time: string;

  setTime($event: any) {
    this.column.data = dayjs($event + " 1970/01/01", "HH:mm YYYY/MM/DD");
  }

  onChange() {
    const date = new Date();
    date.setHours(parseInt(this.time.slice(0, 2), 10));
    date.setMinutes(parseInt(this.time.slice(3), 10));
    date.setSeconds(0);
    date.setMilliseconds(0);
    this._column.data = date;
    this.columnChange.emit(this.column);
  }
}
