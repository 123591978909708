<div class="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-12 mt-4">
  <a [href]="partner.link" target="_blank" *ngFor="let partner of partners" class="flex flex-col justify-between">
    <div class="block w-32 object-cover">
      <ts-picture [img]="partner?.imgUrl"> </ts-picture>
    </div>
    <div class="flex flex-col">
      <h2 class="font-bold text-lg">{{ partner.name }}</h2>
      <p class="text-gray-600 text-sm">{{ partner.text }}</p>
    </div>
  </a>
</div>
