import { DbObj } from "./dbObj";
import { DrinkAdditive } from "./drinkAdditive";
import { DrinkCategory } from "./drinkCategory";

export class Drink implements DbObj {
  get prettyName() {
    return this.name.includes("<")
      ? this.name.split("<")[0] + "..."
      : this.name;
  }

  get subtitle() {
    return this.size;
  }

  id: number;
  name: string;
  price: string;
  size: string;
  fk_drink_category: number;
  drinkCategory: DrinkCategory;
  drinkAdditives: DrinkAdditive[] = [];
}
