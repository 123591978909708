import { DbObj } from "./dbObj";
import { Drink } from "./drink";

export class DrinkAdditive implements DbObj {
  id: number;
  name: string;
  fkDrink: number;
  drink: Drink;

  get prettyName() {
    return this.name.includes("<")
      ? this.name.split("<")[0] + "..."
      : this.name;
  }

  get subtitle() {
    return '';
  }

}
