import { Component, OnInit } from "@angular/core";
import { NgFor, NgClass, NgIf } from "@angular/common";

@Component({
    selector: "ts-faq",
    templateUrl: "./faq.component.html",
    styleUrls: ["./faq.component.css"],
    standalone: true,
    imports: [
        NgFor,
        NgClass,
        NgIf,
    ],
})
export class FaqComponent implements OnInit {
  faqs: { question: string; answer: string }[] = [];
  expandedFaq: number = -1;

  constructor() {
    this.faqs = [
      {
        question: "Wie läuft der Anmeldeprozess ab?",
        answer:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque doloribus dicta illo sequi voluptates dolore? Repudiandae animi dignissimos tempore unde veniam, nulla eaque accusantium iure. Officia et odio corporis aspernatur!",
      },
      {
        question: "Wie häufig kann ich tanzen?",
        answer:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque doloribus dicta illo sequi voluptates dolore? Repudiandae animi dignissimos tempore unde veniam, nulla eaque accusantium iure. Officia et odio corporis aspernatur!",
      },
      {
        question: "Welche Videos erwarten mich?",
        answer:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque doloribus dicta illo sequi voluptates dolore? Repudiandae animi dignissimos tempore unde veniam, nulla eaque accusantium iure. Officia et odio corporis aspernatur!",
      },
      {
        question: "Wie lange läuft mein Vertrag?",
        answer:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque doloribus dicta illo sequi voluptates dolore? Repudiandae animi dignissimos tempore unde veniam, nulla eaque accusantium iure. Officia et odio corporis aspernatur!",
      },
      {
        question: "Welche Fragen fallen mir noch ein?",
        answer:
          "Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque doloribus dicta illo sequi voluptates dolore? Repudiandae animi dignissimos tempore unde veniam, nulla eaque accusantium iure. Officia et odio corporis aspernatur!",
      },
    ];
  }
  expandFaq(index: number) {
    this.expandedFaq = index === this.expandedFaq ? -1 : index;
  }

  ngOnInit(): void {}
}
