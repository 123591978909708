<div class="max-w-6xl mx-auto">
  <div [@routerTransition]="" class="mt-4 mx-2">
    <h1 class="font-bold text-3xl">Datenschutzerklärung</h1>
    <p>
      <strong>Die Kontaktdaten des Unternehmens: </strong>
    </p>
    <p class="mt-2">
      {{ school.company_name }} <br />
      {{ school.street }} <br />
      {{ school.plz }} {{ school.city }}
      <br />
      <br />
      {{ school.telephone }} <br />
      {{ school.e_mail }}
      <br />
      <br />
    </p>
    <p>
      <strong>Datenerfassung auf unserer Website</strong>
    </p>
    <p>
      <strong>Wer ist verantwortlich f&uuml;r die Datenerfassung auf dieser
        Website?</strong>
      <br />Die Datenverarbeitung auf dieser Website erfolgt durch den
      Websitebetreiber. Dessen Kontaktdaten k&ouml;nnen Sie dem Impressum dieser
      Website entnehmen.
      <br />
      <br />
      <strong>Wie erfassen wir Ihre Daten?</strong>
      <br />Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
      mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein
      Kontaktformular eingeben. <br />Andere Daten werden automatisch beim
      Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem
      technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
      Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie
      unsere Website betreten.
      <br />
      <br />
      <strong>Wof&uuml;r nutzen wir Ihre Daten?</strong>
      <br />Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
      der Website zu gew&auml;hrleisten.
      <br />
      <br />
      <strong>Welche Rechte haben Sie bez&uuml;glich Ihrer Daten?</strong>
      <br />Sie haben jederzeit das Recht unentgeltlich Auskunft &uuml;ber
      Herkunft, Empf&auml;nger und Zweck Ihrer gespeicherten personenbezogenen
      Daten zu erhalten. Sie haben au&szlig;erdem ein Recht, die Berichtigung,
      Sperrung oder L&ouml;schung dieser Daten zu verlangen. Hierzu sowie zu
      weiteren Fragen zum Thema Datenschutz k&ouml;nnen Sie sich jederzeit unter
      der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht
      Ihnen ein Beschwerderecht bei der zust&auml;ndigen Aufsichtsbeh&ouml;rde
      zu.
    </p>
    <p>
      <strong>Datenschutz</strong>
    </p>
    <p>
      Die Betreiber dieser Seiten nehmen den Schutz Ihrer pers&ouml;nlichen
      Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich
      und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
      Datenschutzerkl&auml;rung.
      <br />
      <br />Wenn Sie diese Website benutzen, werden verschiedene
      personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit
      denen Sie pers&ouml;nlich identifiziert werden k&ouml;nnen. Die
      vorliegende Datenschutzerkl&auml;rung erl&auml;utert, welche Daten wir
      erheben und wof&uuml;r wir sie nutzen. Sie erl&auml;utert auch, wie und zu
      welchem Zweck das geschieht.
      <br />
      <br />Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet
      (z.B. bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen
      kann. Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte
      ist nicht m&ouml;glich.
    </p>
    <p>
      <strong>SSL- bzw. TLS-Verschl&uuml;sselung</strong>
    </p>
    <p>
      Diese Seite nutzt aus Sicherheitsgr&uuml;nden und zum Schutz der
      &Uuml;bertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
      Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
      TLS-Verschl&uuml;sselung. Eine verschl&uuml;sselte Verbindung erkennen Sie
      daran, dass die Adresszeile des Browsers von &ldquo;http://&rdquo; auf
      &ldquo;https://&rdquo; wechselt und an dem Schloss-Symbol in Ihrer
      Browserzeile.
      <br />
      <br />Wenn die SSL- bzw. TLS-Verschl&uuml;sselung aktiviert ist,
      k&ouml;nnen die Daten, die Sie an uns &uuml;bermitteln, nicht von Dritten
      mitgelesen werden.
    </p>
    <p>
      <strong>Auskunft, Sperrung, L&ouml;schung</strong>
    </p>
    <p>
      Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das
      Recht auf unentgeltliche Auskunft &uuml;ber Ihre gespeicherten
      personenbezogenen Daten, deren Herkunft und Empf&auml;nger und den Zweck
      der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
      L&ouml;schung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
      personenbezogene Daten k&ouml;nnen Sie sich jederzeit unter der im
      Impressum angegebenen Adresse an uns wenden.
    </p>
    <p>
      <strong>Widerspruch gegen Werbe-Mails</strong>
    </p>
    <p>
      Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten
      Kontaktdaten zur &Uuml;bersendung von nicht ausdr&uuml;cklich
      angeforderter Werbung und Informationsmaterialien wird hiermit
      widersprochen. Die Betreiber der Seiten behalten sich ausdr&uuml;cklich
      rechtliche Schritte im Falle der unverlangten Zusendung von
      Werbeinformationen, etwa durch Spam-E-Mails, vor.
    </p>
    <p>
      <strong>Cookies</strong>
    </p>
    <p>
      Die Internetseiten verwenden teilweise so genannte Cookies. Cookies
      richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren.
      Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und
      sicherer zu machen. Cookies sind kleine Textdateien, die auf Ihrem Rechner
      abgelegt werden und die Ihr Browser speichert.
      <br />
      <br />Die meisten der von uns verwendeten Cookies sind so genannte
      &ldquo;Session-Cookies&rdquo;. Sie werden nach Ende Ihres Besuchs
      automatisch gel&ouml;scht. Andere Cookies bleiben auf Ihrem Endger&auml;t
      gespeichert bis Sie diese l&ouml;schen. Diese Cookies erm&ouml;glichen es
      uns, Ihren Browser beim n&auml;chsten Besuch wiederzuerkennen.
      <br />
      <br />Sie k&ouml;nnen Ihren Browser so einstellen, dass Sie &uuml;ber das
      Setzen von Cookies informiert werden und Cookies nur im Einzelfall
      erlauben, die Annahme von Cookies f&uuml;r bestimmte F&auml;lle oder
      generell ausschlie&szlig;en sowie das automatische L&ouml;schen der
      Cookies beim Schlie&szlig;en des Browser aktivieren. Bei der Deaktivierung
      von Cookies kann die Funktionalit&auml;t dieser Website eingeschr&auml;nkt
      sein.
      <br />
      <br />Cookies, die zur Durchf&uuml;hrung des elektronischen
      Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen
      erw&uuml;nschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind,
      werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der
      Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von
      Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner
      Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres
      Surfverhaltens) gespeichert werden, werden diese in dieser
      Datenschutzerkl&auml;rung gesondert behandelt.
    </p>
    <p>
      <strong>Kontaktformular</strong>
    </p>
    <p>
      Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre
      Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen
      Kontaktdaten zwecks Bearbeitung der Anfrage und f&uuml;r den Fall von
      Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre
      Einwilligung weiter.
      <br />
      <br />Die Verarbeitung der in das Kontaktformular eingegebenen Daten
      erfolgt somit ausschlie&szlig;lich auf Grundlage Ihrer Einwilligung (Art.
      6 Abs. 1 lit. a DSGVO). Sie k&ouml;nnen diese Einwilligung jederzeit
      widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die
      Rechtm&auml;&szlig;igkeit der bis zum Widerruf erfolgten
      Datenverarbeitungsvorg&auml;nge bleibt vom Widerruf unber&uuml;hrt.
      <br />
      <br />Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
      uns, bis Sie uns zur L&ouml;schung auffordern, Ihre Einwilligung zur
      Speicherung widerrufen oder der Zweck f&uuml;r die Datenspeicherung
      entf&auml;llt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
      Zwingende gesetzliche Bestimmungen &ndash; insbesondere
      Aufbewahrungsfristen &ndash; bleiben unber&uuml;hrt.
    </p>
    <p>
      <strong>Verarbeiten von Daten (Kunden- und Vertragsdaten)</strong>
    </p>
    <p>
      Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie
      f&uuml;r die Begr&uuml;ndung, inhaltliche Ausgestaltung oder &Auml;nderung
      des Rechtsverh&auml;ltnisses erforderlich sind (Bestandsdaten). Dies
      erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung
      von Daten zur Erf&uuml;llung eines Vertrags oder vorvertraglicher
      Ma&szlig;nahmen gestattet. Personenbezogene Daten &uuml;ber die
      Inanspruchnahme unserer Internetseiten (Nutzungsdaten) erheben,
      verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem
      Nutzer die Inanspruchnahme des Dienstes zu erm&ouml;glichen oder
      abzurechnen.
      <br />
      <br />Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder
      Beendigung der Gesch&auml;ftsbeziehung gel&ouml;scht. Gesetzliche
      Aufbewahrungsfristen bleiben unber&uuml;hrt.
    </p>
    <p>
      <strong>Daten&uuml;bermittlung bei Vertragsschluss f&uuml;r Ware</strong>
    </p>
    <p>
      Wir &uuml;bermitteln personenbezogene Daten an Dritte nur dann, wenn dies
      im Rahmen der Vertragsabwicklung notwendig ist, etwa an die mit der
      Lieferung der Ware betrauten Unternehmen oder das mit der
      Zahlungsabwicklung beauftragte Kreditinstitut. Eine weitergehende
      &Uuml;bermittlung der Daten erfolgt nicht bzw. nur dann, wenn Sie der
      &Uuml;bermittlung ausdr&uuml;cklich zugestimmt haben. Eine Weitergabe
      Ihrer Daten an Dritte ohne ausdr&uuml;ckliche Einwilligung, etwa zu
      Zwecken der Werbung, erfolgt nicht.
      <br />
      <br />Grundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1 lit. b
      DSGVO, der die Verarbeitung von Daten zur Erf&uuml;llung eines Vertrags
      oder vorvertraglicher Ma&szlig;nahmen gestattet.
    </p>
    <p>
      <strong>Daten&uuml;bermittlung bei Vertragsschluss f&uuml;r
        Dienstleistungen</strong>
    </p>
    <p>
      Wir &uuml;bermitteln personenbezogene Daten an Dritte nur dann, wenn dies
      im Rahmen der Vertragsabwicklung notwendig ist, etwa an das mit der
      Zahlungsabwicklung beauftragte Kreditinstitut.
      <br />
      <br />Eine weitergehende &Uuml;bermittlung der Daten erfolgt nicht bzw.
      nur dann, wenn Sie der &Uuml;bermittlung ausdr&uuml;cklich zugestimmt
      haben. Eine Weitergabe Ihrer Daten an Dritte ohne ausdr&uuml;ckliche
      Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht.
      <br />
      <br />Grundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1 lit. b
      DSGVO, der die Verarbeitung von Daten zur Erf&uuml;llung eines Vertrags
      oder vorvertraglicher Ma&szlig;nahmen gestattet.
    </p>
    <p>
      <strong>Google Maps</strong>
    </p>
    <p>
      Diese Seite nutzt &uuml;ber eine API den Kartendienst Google Maps.
      Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
      94043, USA.
      <br />
      <br />Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP
      Adresse zu speichern. Diese Informationen werden in der Regel an einen
      Server von Google in den USA &uuml;bertragen und dort gespeichert. Der
      Anbieter dieser Seite hat keinen Einfluss auf diese Daten&uuml;bertragung.
      <br />
      <br />Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
      Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit
      der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes
      Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      <br />
      <br />Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
      Datenschutzerkl&auml;rung von Google:
      <a href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/.</a>
    </p>
    <p>
      <strong>YouTube Plugin</strong>
    </p>
    <p>
      Unsere Website nutzt Plugins der von Google betriebenen Seite YouTube.
      Betreiber der Seiten ist die YouTube, LLC, 901 Cherry Ave., San Bruno, CA
      94066, USA.
      <br />
      <br />Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten Seiten
      besuchen, wird eine Verbindung zu den Servern von YouTube hergestellt.
      Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie
      besucht haben.
      <br />
      <br />Wenn Sie in Ihrem YouTube-Account eingeloggt sind, erm&ouml;glichen
      Sie YouTube, Ihr Surfverhalten direkt Ihrem pers&ouml;nlichen Profil
      zuzuordnen. Dies k&ouml;nnen Sie verhindern, indem Sie sich aus Ihrem
      YouTube-Account ausloggen.
      <br />
      <br />Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
      Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes
      Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      <br />
      <br />Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der
      Datenschutzerkl&auml;rung von YouTube unter:
      <a href="https://www.google.de/intl/de/policies/privacy.">https://www.google.de/intl/de/policies/privacy.</a>
    </p>
  </div>
</div>
