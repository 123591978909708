import { NgIf } from '@angular/common';
import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { News } from '../../models/news';
import { routerTransition } from '../../router.animations';
import { NewsService } from '../../services/news.service';
import { PictureComponent } from '../../shared/picture/picture.component';

@Component({
    selector: 'ts-routing-news',
    templateUrl: './routing-news.component.html',
    styleUrls: ['./routing-news.component.css'],
    animations: [routerTransition()],
    standalone: true,
    imports: [
        NgIf,
        PictureComponent,
        RouterLink,
    ],
    host: { ngSkipHydration: 'true' },
})
export class RoutingNewsComponent implements OnInit, OnChanges {

  public news: News = new News();

  constructor(
    private router: ActivatedRoute,
    private newsSrv: NewsService
  ) { }

  public updateNews() {
    this.router.params.subscribe(params => {
      this.newsSrv.byUrlTitle(params.urlTitle).subscribe(news => {
        this.news = news;
      });
    });
  }

  ngOnInit() {
    this.updateNews();
  }

  ngOnChanges() {
    this.updateNews();
  }
}
