<div class="h-screen absolute inset-0 z-30 flex items-center justify-center gap-8 bg-orange">
  <a [routerLink]="['/']">
    <img class="h-32" [src]="school?.getLogoPath()" [alt]="school?.name" />
  </a>
  <div class="max-w-md flex flex-col items-center mt-6 bg-white shadow-lg rounded-larger p-8">
    <h2 class="font-bold text-3xl text-orange" style="text-align: center">Backend</h2>
    <ts-loader class="center" *ngIf="isLoading"></ts-loader>
    <form *ngIf="!isLoading" class="mt-4 flex flex-col" (ngSubmit)="onSubmit(authForm)" #authForm="ngForm">
      <div class="mt-2">
        <div class="mt-1">
          <input placeholder="Benutzername" autocomplete="username" id="username" name="username" type="text" ngModel
            class="py-2 px-4 block w-full focus:border-b focus:border-orange border-b border-gray-300 " />
        </div>
      </div>

      <div class="mt-2">
        <div class="mt-1">
          <input placeholder="Passwort" required type="password" id="password" name="password" autocomplete="password"
            ngModel class="py-2 px-4 block w-full focus:ring-orange focus:border-orange border-b border-gray-300 " />
        </div>
      </div>

      <span *ngIf="errorMsg">{{ errorMsg }}</span>
      <div class="flex gap-2 mt-6 w-full justify-end">
        <a [routerLink]="['/']" style="text-align: center" type="submit"
          class="px-3 py-2 text-orange text-sm font-bold rounded-full hover:bg-orangeLightX">
          Zurück
        </a>
        <button [disabled]="!authForm.valid" style="text-align: center" type="submit"
          class="rounded-full px-3 py-2 bg-orange hover:bg-orangeHover shadow-md text-white text-sm font-bold">
          Anmelden
        </button>
      </div>
    </form>
  </div>
</div>
