import { NgFor, NgIf } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CourseLevel } from '../../models/courseLevel';
import { School } from '../../models/school';
import { CourseLevelService } from '../../services/course-level.service';
import { CourseService } from '../../services/course.service';
import { SchoolService } from '../../services/school.service';
import { CourseTableComponent } from '../course-table/course-table.component';

@Component({
    selector: 'ts-course-list',
    templateUrl: './course-list.component.html',
    styleUrls: ['./course-list.component.css'],
    standalone: true,
    imports: [NgFor, NgIf, RouterLink, CourseTableComponent],
    host: {ngSkipHydration: 'true'},
})
export class CourseListComponent implements OnChanges {

  @Input() ageClassId: number;

  public courseLevelsPair: CourseLevel[] = new Array();
  public courseLevelsSolo: CourseLevel[] = new Array();
  public courseLevels = [
    this.courseLevelsPair,
    this.courseLevelsSolo
  ];
  private school: School = new School();

  constructor(private courseSrv: CourseService, private CourseLevelSrv: CourseLevelService, private schoolSrv: SchoolService) { }

  ngOnChanges() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
      this.CourseLevelSrv.byAgeClassId(this.ageClassId, this.school.id, 'paar').subscribe(courseLevels => {
        // only consider levels with more than 0 courses
        this.courseLevelsPair = courseLevels.filter(courseLevel => courseLevel.courses.length > 0);
        this.courseLevels[0] = this.courseLevelsPair;
      });

      this.CourseLevelSrv.byAgeClassId(this.ageClassId, this.school.id, 'solo').subscribe(courseLevels => {
        // only consider levels with more than 0 courses
        this.courseLevelsSolo = courseLevels.filter(courseLevel => courseLevel.courses.length > 0);
        this.courseLevels[1] = this.courseLevelsSolo;
      });
    });
  }

}
