import { Component, OnInit } from '@angular/core';
import { SchoolService } from '../../services/school.service';
import { School } from '../../models/school';
import { routerTransition } from '../../router.animations';
import { PartnerListComponent } from '../../partner/partner-list/partner-list.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'ts-routing-partners',
    templateUrl: './routing-partners.component.html',
    styleUrls: ['./routing-partners.component.css'],
    animations: [routerTransition()],
    standalone: true,
    imports: [NgIf, PartnerListComponent],
})
export class RoutingPartnersComponent implements OnInit {

  public school: School = new School();

  constructor(private schoolSrv: SchoolService) { }

  ngOnInit() {
    this.schoolSrv.current().subscribe(school => {
      this.school = school;
    });
  }

}
