<div class="max-w-6xl mx-auto">
  <div class="waves relative shadow-xl sm:rounded-2xl sm:overflow-hidden h-48 sm:h-72 sm:mt-8 p-8 mx-0 sm:mx-2">
    <div class="absolute inset-0">
      <ts-picture class="object-cover w-full h-full" [img]="firstNews.image_name" width="100%" height="100%">
      </ts-picture>

      <div class="absolute inset-0 bg-gray-600 mix-blend-multiply"></div>
    </div>

    <div class="relative h-full flex flex-row justify-center items-center">
      <h1 class="text-center text-2xl font-extrabold tracking-tight sm:text-3xl lg:text-5xl text-white">
        nächste Events in unserer Tanzschule
      </h1>
    </div>
  </div>
  <div class="max-w-prose prose text-gray-700 mx-2 text-sm mt-4">
    Für das besondere Erlebnis veranstalten wir regelmäßig Events für die
    verschiedenen Altersklassen! Feiert mit uns in
    <a routerLink="/saele">unseren Sälen</a>.<br />Ein besonderes Highlight für
    die Jugendlichen sind die Bälle in schönen externen Locations. Unten findet
    ihr die nächsten geplanten Events, wir freuen uns über eure Anmeldung!
  </div>
  <div class="mx-2 mt-4" *ngFor="let ageClass of ageClasses">
    <ts-event-list [ageClassId]="ageClass.id"></ts-event-list>
  </div>
</div>
