<div class="relative bg-yellow-800 h-92 top-menu">
  <div class="absolute inset-0">
    <img class="w-full h-full object-cover" src="/assets/img/tmp/paar_springt_hoch.jpg" alt="" />
    <div class="absolute inset-0 bg-yellow-800 mix-blend-multiply" aria-hidden="true"></div>
  </div>
  <div class="relative max-w-6xl mx-auto py-24 px-4 sm:py-32 sm:px-6 lg:px-8">
    <h1 class="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
      Häufig gestellte Fragen
    </h1>
    <p class="mt-6 text-xl text-indigo-100 max-w-3xl">
      Mattis amet hendrerit dolor, quisque lorem pharetra. Pellentesque lacus
      nisi urna, arcu sociis eu. Orci vel lectus nisl eget eget ut consectetur.
      Sit justo viverra non adipisicing elit distinctio.
    </p>
  </div>
</div>
<div class="pt-24 w-full">
  <div class="prose max-w-prose mx-auto">
    <dl class="mt-6 space-y-6 divide-y divide-gray-200 pb-20">
      <div class="pt-6" *ngFor="let faq of faqs; let i = index">
        <dt class="text-xl">
          <!-- Expand/collapse question button -->
          <button type="button" class="text-left w-full flex justify-between items-start text-gray-400"
            (click)="expandFaq(i)" aria-controls="faq-0" aria-expanded="false">
            <span class="font-medium text-gray-800">
              {{ faq.question }}
            </span>
            <span class="ml-6 h-7 flex items-center">
              <!--
                Expand/collapse icon, toggle classes based on question open state.

                Heroicon name: outline/chevron-down

                Open: "-rotate-180", Closed: "rotate-0"
              -->
              <svg class="rotate-0 h-6 w-6 transform duration-200" [ngClass]="{ 'rotate-180': i === expandedFaq }"
                xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </button>
        </dt>
        <dd class="mt-2 pr-12" *ngIf="i === expandedFaq">
          <p class="text-base text-gray-500">
            {{ faq.answer }}
          </p>
        </dd>
      </div>
    </dl>
  </div>
</div>
