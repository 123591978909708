<div class="max-w-6xl mx-auto">
  <div
    class="
      relative
      shadow-xl
      sm:rounded-2xl sm:overflow-hidden
      h-96
      sm:mt-8
      p-8
    "
  >
    <div class="absolute inset-0">
      <ts-picture
        class="object-cover w-full h-full"
        [img]="firstNews.image_name"
        width="100%"
        height="100%"
      >
      </ts-picture>

      <div class="absolute inset-0 bg-gray-400 mix-blend-multiply"></div>
    </div>
    <div class="relative h-full flex flex-row justify-center items-center">
      <h1
        class="
          text-center text-4xl
          font-extrabold
          tracking-tight
          sm:text-5xl
          lg:text-6xl
          text-white
        "
      >
        Getränkekarte
      </h1>
    </div>
  </div>
  <div *ngFor="let drinkCategory of drinkCategories">
    <ts-drink-category [drinkCategory]="drinkCategory"></ts-drink-category>
  </div>

  <div class="mt-2 mx-2" [innerHtml]="school.drinksText"></div>
</div>
